import React, { useEffect, useState } from "react";
import Stylesheet from "./style.module.scss";
import { useMutation } from "@tanstack/react-query";
import getCommentData from "../../service/comment-service";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
} from "react-hook-form";
import { InputForm } from "../../../../../../shared/components/forms";
import ProcessTableBody from "../../components/process-list/process-table-body";
import ProcessTableHeader from "../../components/process-list/process-table-header";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import Popup from "../../../../../../shared/components/sy-model/popup_modal";
import Dropdown from "../../../../../../shared/components/forms/dropdown/dropdown";
import commentService from "../../service/comment-service";
import { pageSizeOptions } from "../../../../../../shared/constants";
import Sy_CustomFooter from "../../../../../../shared/components/sy-model/sy-customfooter";
import { useTranslation } from "react-i18next";
import {useDispatch} from 'react-redux';
import {displayOverlayLoading} from '../../../../feature/common-slice'
import SyPagination from "../../../../../../shared/components/sy-pagination";
import SySearch from "../../../../../../shared/components/sy-search/sy-search";
import {processHeaders} from "./process-list-config"

type Props = {};

type ListItem = {
  pro_budget: any;
  pro_status: any;
  pro_end_date: string | number | Date;
  pro_start_date: string | number | Date;
  pro_id: number;
  pro_name: string;
};

const ProcessList = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm();
  const [labels, setLabels] = useState<any>([]);
  const [processList, setProcessList] = useState<ListItem[]>([]);
  const [searchText, setSearchText] = useState("");
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [PageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [errors, setErrors] = useState<any>([]);
  const [deleteId, setDeleteId] = useState<any>();
  const [sorting, setSorting] = useState<{
    active: string;
    direction: "asc" | "desc" | "";
  }>({
    active: "",
    direction: "",
  });

  useEffect(() => {
    fetchList();
  }, []);

  const payload = {
    filter: { label_ids: [] },
    paging: {
      pageIndex: 0,
      pageSize: 100,
      globalFilter: null,
      pageSizeOptions: pageSizeOptions,
    },
    search: "",
    show_inactive: false,
  };

  const status = [
    {
      id: 0,
      value: "--select--",
    },
    {
      id: 1,
      value: "active",
    },
    {
      id: 2,
      value: "inactive",
    },
  ];

  const {
    data: ProcessListData,
    mutate: getListItem,
    isLoading: listpageloading,
  } = useMutation({
    mutationFn: getCommentData.getListPage,
    onSuccess(data, variables, context) {
    },
  });

  useEffect(()=>{
    dispatch(displayOverlayLoading(listpageloading?1:0));
  },[listpageloading])

  const { mutate: deleteList, isLoading: deleteLoading } = useMutation({
    mutationFn: getCommentData.deleteListItem,
    onSuccess: () => {
      fetchList();
    },
    onError: (data: any) => {
      setErrors(data.response.data.message);
    },
  });

  const { mutate: getLabel, isLoading } = useMutation({
    mutationFn: commentService.getProcessLabel,
    onSuccess(data) {
      setLabels(data.data);
    },
  });

  useEffect(() => {
    if (
      ProcessListData &&
      ProcessListData?.data &&
      ProcessListData?.data.data
    ) {
      setProcessList(ProcessListData?.data.data);
    }
  }, [ProcessListData]);

  const fetchList = (
    searchValue: string = searchText,
    newShowInactive: boolean = showInactive,
    sort: any = { active: "", direction: "" },
    pageIndexValue: any = pageIndex,
    pageSize: any = PageSize
  ) => {
    setSearchText(searchValue);
    const payloadWithSorting = {
      ...payload,
      paging: {
        pageIndex: pageIndexValue,
        pageSize: PageSize,
        globalFilter: null,
        pageSizeOptions: pageSize,
      },
      search: searchValue,
      show_inactive: newShowInactive,
      sorting: sort,
    };
    getListItem(payloadWithSorting);
  };

  const handleSort = (key: string) => {
    const sortedTable: any = {
      active: key,
      direction:
        sorting.active === key && sorting.direction === "asc" ? "desc" : "asc",
    };
    setSorting(sortedTable);
    fetchList(searchText, showInactive, sortedTable);
  };

  const handleDelete = (projectId: number) => {
    setDeleteId(projectId)
    deleteList(projectId);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setShowInactive(isChecked);
    fetchList(searchText, isChecked);
  };

  const searchEventEmit = (searchValue: any) => {
    fetchList(searchValue, showInactive);
  };

  const handleKanbanBoardClick = () => {
    navigate("/mopus/board");
  };

  const handleCreateProcessClick = () => {
    navigate("/mopus/project/add");
  };

  const openPopUp = () => {
    getLabel(searchvalue);
    setPopupOpen(true);
  };

  const searchvalue = {};

  const debounced = useDebouncedCallback((data) => {
    getLabel(searchvalue);
  }, 800);

  const handleInput = (inputvalue: any) => {
    debounced(inputvalue);
  };

  const handlestatusfilter = () => {
    return;
  };
  const handleStatusLabel = (data: any) => data.value;

  const handleformSubmit = (data: any) => {
    setPopupOpen(false);
    if (data) submitForm(data);
  };

  const submitForm: SubmitHandler<FieldValues> = (data: any) => {
    // if (data.status.id !== 0) {
    const filterPayloads = {
      paging: {
        pageIndex: 0,
        pageSize: 100,
        globalFilter: null,
        pageSizeOptions: pageSizeOptions,
      },
      filter: {
        region_id: "",
        status: data.status ? data.status.id : null,
        label_ids:
          data.label?.map((data: any) => {
            return data.l_id;
          }) || [],
        labels:
          data.label?.map((data: any) => {
            return data;
          }) || [],
      },
      search: searchText,
      show_inactive: showInactive,
    };

    getListItem(filterPayloads);
    // }
  };

  const filter_content = () => {
    return (
      <>
        <div className="mb-3">
          <Controller
            name="status"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Dropdown
                  options={status}
                  placeholder={"status"}
                  enableSearchBox={false}
                  loading={false}
                  handleFilter={handlestatusfilter}
                  handleChange={onChange}
                  multiple={false}
                  getoptlabel={handleStatusLabel}
                  value={status[0]}
                />
              );
            }}
          />
        </div>
        <div className="mb-3">
          <Controller
            name="label"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  options={labels}
                  placeholder={"type_here_to_search"}
                  enableSearchBox={true}
                  loading={isLoading}
                  handleFilter={handleInput}
                  handleChange={onChange}
                  multiple={true}
                  getoptlabel={function (val: any): string {
                    return val.l_name;
                  }}
                />
              );
            }}
          />
        </div>
        <Sy_CustomFooter
          handleSubmit={handleSubmit}
          handleformSubmit={handleformSubmit}
        />
      </>
    );
  };

  const handlePaginateClick = ({
    pageIndex,
    rowPerPage,
  }: {
    pageIndex: any;
    rowPerPage: any;
  }) => {
    setPageIndex(pageIndex);
    setPageSize(rowPerPage);
    fetchList(searchText, showInactive, sorting, pageIndex, PageSize);
  };

  return (
    <>
      <div className={`${Stylesheet.container_fluid} container-fluid`}>
        <div className="d-flex mb-3">
          <h5 className="mt-3 ms-2">{t("process_and_task_management")}</h5>
        </div>
        <div className={`${Stylesheet.container} border border-rounded p-2`}>
          <div className="mt-1 d-flex flex-wrap align-items-center justify-content-between mb-3 p-3">
            <div className="d-flex">
              <button
                className={`${Stylesheet.button} me-2 mb-3 text-light`}
                onClick={handleCreateProcessClick}
              >
                {t("create_process_area")}
              </button>
              <button
                className={`${Stylesheet.button} me-2 mb-3 text-light`}
                onClick={handleKanbanBoardClick}
              >
                {t("kanban_board")}
              </button>
            </div>

            <SySearch
              fetchList={fetchList}
              searchEventEmit={searchEventEmit}
              showFilterIcon={true}
              onFilterClick={openPopUp}
              searchValue={searchText}
            />
            {isPopupOpen && (
              <Popup
                model_type={"responsive"}
                open={isPopupOpen}
                close={setPopupOpen}
                showHeader={true}
                children={filter_content()}
                title={"filters"}
                style={{ width: "400px" }}
              />
            )}

            <div className="mt-2 mb-3 ms-2" onChange={handleCheckboxChange}>
              <InputForm type={"checkbox"} {...register("showInactive")} />
            </div>
          </div>
          <div className="ellipsis">
            <table className="table">
              <thead>
                <tr>
                  {processHeaders.map((header) => (
                    <ProcessTableHeader
                      key={header.key}
                      header={header}
                      handleSort={handleSort}
                      sorting={sorting}
                    />
                  ))}
                  <th className={`${Stylesheet.theader}`} scope="col">
                    action
                  </th>
                </tr>
              </thead>
              {ProcessListData?.data.total === null ? (
                <tbody>
                  <tr>
                    <td colSpan={processHeaders.length + 1}>
                      <p className="d-flex justify-content-center opacity-50">
                        no_data_found
                      </p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {processList.map((item: any) => (
                    <ProcessTableBody
                      key={item.pro_id}
                      item={item}
                      handleDelete={handleDelete}
                      Error={errors}
                      loading={deleteLoading}
                      deleteId={deleteId}
                    />
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <div className="d-flex justify-content-end me-5">
            <div className="opacity-50">
              total:{ProcessListData?.data.total}
            </div>
          </div>
          {ProcessListData?.data.total > 100 ? (
            <div
              className={`${Stylesheet.pagination} d-flex justify-content-center border rounded`}
            >
              <SyPagination
                total={
                  ProcessListData?.data.total ? ProcessListData?.data.total : 0
                }
                handlePaginateClick={handlePaginateClick}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ProcessList;
