import React from "react";
import SortableItem from "./sortable-item";
import { Droppable } from "react-beautiful-dnd";
import Stylesheet from "./style.module.scss";
import SyIcons from "../sy-icons";

type UserComponentProps = {
  item: any;
  openItemId: string | null;
  handleAccordionItemClick: (itemId: string) => void;
  status: boolean;
  onClickNavBtn:any;
};

const UserComponent = ({
  item,
  openItemId,
  handleAccordionItemClick,
  status,
  onClickNavBtn,
}: UserComponentProps) => {
  
  return (
    <Droppable droppableId={item.ts_order} key={item.id}>
      {(provided, snapshot) => (
        <div
          className={`${Stylesheet.vertical_card} rounded mt-2 me-2`}
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{  
            background: snapshot.isDraggingOver ? "white" : "white",
            
          }}
        >
          <div className="d-flex justify-content-between mt-2">
            <div className={`${Stylesheet.heading} d-flex`}>
              <h6 className="mt-2 ms-2 main-font semi-bold ellipsis">{item.name}</h6>
            </div>
            {/* <div className="d-flex align-items-center me-2">
              <SyIcons name={"add"} iconStyle="round" className={"pointer"}/>
              <SyIcons name={"more_vert"} className={"pointer"}/>
            </div> */}
          </div>

          {/* {item.data.length === 0 ? (
            <div className="card mt-4 ms-4 me-4 mb-5">
              <div className="container rounded">
                <p className=" text-center mt-3 opacity-50">no_data_found</p>
              </div>
            </div>
          ) : ( */}
            <div className="sortableItem">
              {item.data?.map((subItem: any, index: number) => (
                <SortableItem
                  key={subItem.task_id}
                  subItem={subItem}
                  isAccordionOpen={openItemId === subItem.task_id}
                  status={status}
                  handleAccordionItemClick={handleAccordionItemClick}
                  index={index}
                  onClickNavBtn={onClickNavBtn}
                />
              ))}
            </div>
          {/* )} */}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default UserComponent;
