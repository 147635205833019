import React, { useContext, useEffect, useState } from "react";
import Sy_Search from "../../../../../../../shared/components/sy-search";
import Popup from "../../../../../../../shared/components/sy-model";
import {
  SyIcons,
  SyLoadingIcon,
  SyPersist,
} from "../../../../../../../shared/components";
import SyFileDnD from "../../../../../../../shared/components/sy-file-dnd";
import styleSheet from "../style.module.scss";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../../service/comment-service";
import { getDateLocaleString } from "../../../../../../../shared/utils/dayjs";
import {
  syDeletePersistData,
  syGetPersistData,
  syOpenFile,
  sySetPersistData,
} from "../../../../../../../shared/utils";
import { AuthContext } from "../../../../../context";
import {
  FILE_ICON_NAME,
  getfiletype,
} from "../../../../../../../shared/utils/sy-file-utils";
import { SyToast } from "../../../../../../../shared/utils/sy-toast";
import SyconfirmPopup from "../../../../../../../shared/components/sy-confirmPopup";
import { MAIN_API_URL } from "../../../../../../../shared/constants";
const Doclist = ({
  disable,
  task_id,
  itemId,
  filter_content,
  taskData,
  updateDashboard,
}: any) => {
  const [fileType, setFileType] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [doclist, setdoclist] = useState<Array<object>>([]);
  const [docAdded, setDocAdded] = useState(false);
  const [isDocPopupOpen, setisDocPopupOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [submitdocLoading, setSubmitdocLoading] = useState<any>([]);
  const [updateDash, setUpdateDash] = useState<boolean>(false);
  const [deletabledoc, setdeletabledoc] = useState<any | null>(null);
  const [docConfirmPopup, setDocConfirmPopup] = useState(false);

  const authToken = useContext(AuthContext);
  const { t } = useTranslation();

  const documentpayload = { search: "", filter: { fileType: null } };
  const {
    data: documentResponse,
    mutate: getDocsMutate,
    isLoading: docLoading,
  } = useMutation({
    mutationFn: commentService.getFileDoc,
    onSuccess(data) {
      setdoclist(data.data.docs);
      updateDashboard(docAdded);
      setFileType(null);
      setDocAdded(false);
    },
  });
  const taskId = task_id;
  const editTaskId = task_id;
  const taskid = task_id;
  const mode = "subTaskList";

  const searchPersistVal = syGetPersistData(`${mode}_${task_id}_Search_Doc`);

  const searchEventEmit = (searchVal: any) => {
    setSearchValue(searchVal ? searchVal : "");
    let payload = { ...documentpayload };
    payload.search = searchVal;
    payload.filter.fileType = fileType;

    sySetPersistData(`${mode}_${task_id}_Search_Doc`, searchVal);
    getDocsMutate({ itemId, taskId, payload });
  };

  const openDocPopUp = () => {
    setisDocPopupOpen(true);
  };
  const openPopUp = () => {
    setIsOpen(true);
  };

  const handleAddDocPopupClose = () => {
    let payload = { ...documentpayload };
    docAdded && getDocsMutate({ itemId, taskId, payload });
  };

  const iconName = (data: string) => FILE_ICON_NAME[getfiletype(data)];

  const handleDocDeleteIconClick = (val: any) => {
    setdeletabledoc(val);
    setDocConfirmPopup(true);
  };

  useEffect(() => {
    if (updateDashboard) {
      updateDashboard(updateDash);
    }
  }, [updateDash]);

  const {
    mutate: deletFile,
    isLoading: deletedocLoading,
    isError,
  } = useMutation({
    mutationFn: commentService.deleteFile,
    onSuccess(data, variables) {
      const message = data.data.message;
      SyToast(t(message), "top-right", "short", "success");
      const indexval = [...submitdocLoading];
      indexval[variables.index] = false;
      setSubmitdocLoading(indexval);

      const index = deletabledoc?.index;
      doclist.splice(index, 1);
      setUpdateDash(true);
    },
    onError(error: any) {
      const message = error.message;
      SyToast(t(message), "top-right", "short", "error");
    },
  });
  const handleDocConfirmClick = () => {
    const submitdocLoadingVal = [...submitdocLoading];
    submitdocLoadingVal[deletabledoc.index] = true;
    setSubmitdocLoading(submitdocLoadingVal);
    deletFile(deletabledoc);
  };

  const onChangePersist = (data: any) => {
    if (data.action === "remove") {
      searchEventEmit("");
      syDeletePersistData(`${mode}_${task_id}_Search_Doc`);
    }
  };
  useEffect(() => {
    setSearchValue(searchPersistVal);
    let payload = documentpayload;
    getDocsMutate({ itemId, taskId, payload });
  }, []);

  const documentlist = doclist?.map((data: any, index: any) => {
    const handleDocIconClick = () => {
      syOpenFile({
        fileName: data.md_name,
        authToken: authToken.authToken,
        path: "mopus_docs",
      });
    };

    return (
      <div key={index} className={`d-flex ${styleSheet.list_style} p-0`}>
        <div className="col-1 " id={`${index}`} onClick={handleDocIconClick}>
          <span className=" pointer select-none">
            <SyIcons
              iconType="icons"
              iconStyle="outlined"
              className={`${styleSheet.icon_style} pb-0`}
              name={iconName(data.md_name)}
            />
          </span>
        </div>

        <div className="col-7  sy-primary pointer" onClick={handleDocIconClick}>
          <p className="sub-font ellipsis">{data.md_name}</p>
        </div>
        <div className="col-3 ">
          <p className="sub-font">
            {getDateLocaleString(data.created_at, "date")}
          </p>
        </div>
        {!disable || (taskData?.data.access_member &&
            taskData?.data.enable_file == "1") ||
          taskData?.data.limited_Access ? (
        <div className={`col-1 d-flex justify-content-end `}>
          {submitdocLoading[index] ? (
            <div className="px-1 ">
              <SyLoadingIcon color={"loading "} size="xm" />
            </div>
          ) : (
            <SyIcons
              name={"close"}
              toolTipTitle="delete"
              className={`material-icons-round pointer text-black-50  ${styleSheet.icon_style} pb-0`}
              value={{ data: data.md_id, index: index }}
              onClick={handleDocDeleteIconClick}
            />
          )}
        </div>):''}
      </div>
    );
  });

  const api = `${MAIN_API_URL}public/modules/mopus/main/fileUpload/${itemId}/${
    editTaskId ? editTaskId : taskid
  }`;

  const { mutate: deleteUploads, isLoading: deleteLoading } = useMutation({
    mutationFn: commentService.deleteFile,
    onSuccess(data, variables, context) {},
    onError(error: any, variables, context) {
      const message = error.message;
      SyToast(t(message), "bottom-right", "short", "error");
    },
  });


  return (
    <div>
      <div className="border border-2 rounded p-2 ">
        <div
          className={` text-black-50 d-flex justify-content-between mb-2 align-items-center`}
        >
          <p className="sub-font col-6"> {t("doc_list")}</p>
          {/* <div className="col">
            <SyPersist searchValue={searchValue} onChange={onChangePersist} />
          </div> */}
          <SyconfirmPopup
            open={docConfirmPopup}
            close={setDocConfirmPopup}
            onConfirmClick={handleDocConfirmClick}
          />
          <Sy_Search
            searchEventEmit={searchEventEmit}
            showFilterIcon={false}
            subtasklist={true}
            onFilterClick={openDocPopUp}
            searchValue={searchValue}
            // disabled={disable}
          />
          {isDocPopupOpen && (
            <Popup
              model_type={"responsive"}
              open={isDocPopupOpen}
              close={setisDocPopupOpen}
              showHeader={true}
              children={filter_content()}
              title={"filters"}
              style={{ width: "400px" }}
            />
          )}
          {!disable || (taskData?.data.access_member &&
            taskData?.data.enable_file == "1") ||
          taskData?.data.limited_Access ? (
            <SyIcons
              name="add"
              toolTipTitle="add"
              iconStyle="outlined"
              iconType="icons"
              className={`text-black-50 pointer mr-5 `}
              onClick={openPopUp}
            />
          ) : (
            ""
          )}
          {isOpen && (
            <Popup
              model_type={"responsive"}
              open={isOpen}
              close={setIsOpen}
              showHeader={isOpen}
              showCloseIcon={true}
              onClose={handleAddDocPopupClose}
              maxWidth="md"
              footerType="onlycancel"
              children={
                isOpen ? (
                  <SyFileDnD
                    url={api}
                    uploadStatus={setDocAdded}
                    validationSize={50}
                    deleteUploads={deleteUploads}
                    scroll={true}
                    autoSave={true}
                  />
                ) : null
              }
              title={t("add_doc")}
            />
          )}
        </div>
        <div className={`sy_horizontal_scroll ${styleSheet.list_div_style}`}>
          {doclist?.length === 0 && !docLoading ? (
            <div className="d-flex justify-content-center mt-3">
              <p className="sub-font text-black-50"> {t("no_data_found")}</p>
            </div>
          ) : docLoading ? (
            <div className={`d-flex justify-content-center mt-3`}>
              <p className="sub-font text-black-50"> {t("loading")}...</p>
            </div>
          ) : (
            documentlist
          )}
        </div>
      </div>
    </div>
  );
};

export default Doclist;
