import React, { memo } from 'react'
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";


interface props {
    title?: string;
    children: any;
    followCursor?:boolean;
}

const SyTooltip=(props: props)=> {
    const { t } = useTranslation();
    const { title='', children,followCursor = false } = props;
    return (
        <Tooltip title={t(title)} arrow followCursor={followCursor}>
            {children}
        </Tooltip>)
}

export default memo(SyTooltip)
