import React, { forwardRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import SyLoadingIcon from "../sy-loading-icon";
import sodisys_loading_icon from "../../../assets/images/sodisys-loading-icon-unscreen.gif";

type Props = {
  loading: any;
  color?: any;
  size?: any;
};

const Syoverlayloader = ({ loading, color, size }: Props) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 200, backgroundColor: loading === 2 ? "white" : null}}
      open={!!loading}
    >
      {loading === 1 && <SyLoadingIcon color={color} size={size} />}
      {loading === 2 && <img src={sodisys_loading_icon} style={{ width: "50px" }} />}
    </Backdrop>
  );
};

export default Syoverlayloader;
