import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const InitTransilation = () => {
  const english = {
    english: 'English'
  }
 const transilation = i18n
    .use(initReactI18next)
    .init({
      resources: {
        'en': { translation: english },
      },
      compatibilityJSON: "v3",
      lng: 'en',
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
      cleanCode: true,

    });
    return transilation;
}

export default InitTransilation;