import tinycolor from "tinycolor2";
import { storeData } from "./local-storage";

const subColorGenerator = (color: string) => {
  const lighterColor = tinycolor(color).lighten(30).toHexString();
  document.documentElement.style.setProperty(
    "--theme-primary-trans-25",
    lighterColor
  );
};

export const changeThemee = (primary: any = "#1A9DD9") => {
  const c = tinycolor(primary);
  document.documentElement.style.setProperty("--theme-primary", primary);
  document.documentElement.style.setProperty(
    "--theme-primary-contrast",
    c.isLight() ? "rgba(0, 0, 0, 0.87)" : "white"
  );
  subColorGenerator(primary);
  const primaryColorPalette = computeColors(primary);
  let secondary = "";
  for (const color of primaryColorPalette) {
    if (color.name == "100" && !secondary) {
      secondary = color.hex;
      storeData("secondary_color", secondary);
      document.documentElement.style.setProperty(
        "--theme-secondary",
        secondary
      );
    }

    const key1 = `--theme-primary-${color.name}`;
    const value1 = color.hex;
    const key2 = `--theme-primary-contrast-${color.name}`;
    const value2 = color.darkContrast ? "rgba(0, 0, 0, 0.87)" : "white";
    document.documentElement.style.setProperty(key1, value1);
    document.documentElement.style.setProperty(key2, value2);
  }
};

const computeColors = (hex: string) => {
  return [
    getColorObject(tinycolor(hex).lighten(52), "50"),
    getColorObject(tinycolor(hex).lighten(40), "100"),
    // getColorObject(tinycolor(hex).lighten(37), "100"),
    getColorObject(tinycolor(hex).lighten(26), "200"),
    getColorObject(tinycolor(hex).lighten(12), "300"),
    getColorObject(tinycolor(hex).lighten(6), "400"),
    getColorObject(tinycolor(hex), "500"),
    getColorObject(tinycolor(hex).darken(6), "600"),
    getColorObject(tinycolor(hex).darken(12), "700"),
    getColorObject(tinycolor(hex).darken(18), "800"),
    getColorObject(tinycolor(hex).darken(24), "900"),
    getColorObject(tinycolor(hex).lighten(50).saturate(30), "A100"),
    getColorObject(tinycolor(hex).lighten(30).saturate(30), "A200"),
    getColorObject(tinycolor(hex).lighten(10).saturate(15), "A400"),
    getColorObject(tinycolor(hex).lighten(5).saturate(5), "A700"),
  ];
};

const getColorObject = (value: any, name: any) => {
  const c = tinycolor(value);
  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight(),
  };
};

export function getTextColor(backgroundColor: string): string {
  const hexColor = backgroundColor.replace("#", "");
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? "#000" : "#fff";
}
