import { syGetPathName } from "./sy-window";



const data: any = {}
const setKey: any = 'persist'

export const syGetPersistData = (key: any) => {
    const url = syGetPathName();
    if (!data[url]) {
        return undefined;
    }
    return data[url]?.[key];
}

export const sySetPersistData = (key: any, value: any) => {
    const url = syGetPathName();

    if (!data[url]) {
        data[url] = {};
    }
    data[url][key] = value;

}

export const syDeletePersistData = (key: any) => {
    const setKey: any = 'persist'
    const url = syGetPathName();
    delete data[url][key]


    // window[setKey] = data;
}