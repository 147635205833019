import { ReplyCard, CommentCard } from "./comment-card";
import Stylesheet from "./style.module.scss";
import Replyfield from "./replyfield";
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommentListData,
  setCommentList,
  updateMyDetailOnToggle,
  getNavDetails,
  getNavPath,
  onChangeNav,
  getCommentLoading,
  setCommentloading,
  onClickNavBtn,
} from "../../../../feature/simpler-slice";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { pageSizeOptions } from "../../../../../../shared/constants";
import { useEffect, useState } from "react";
import getCommentData from "../../service/comment-service";
import Popup from "../../../../../../shared/components/sy-model";
import SyTaskdetailsPopup from "../../pop-up/sy-taskdetails-popup";
import SyTextEditor from "../../../../../../shared/components/forms/sy-rich-text-edit";
import { Controller, useForm } from "react-hook-form";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { SyTooltip } from "../../../../../../shared/components";
import SyNavPath from "../../../../../../shared/components/sy-nav-path";
import { SyIcons, SyLoadingIcon } from "../../../../../../shared/components";

type Props = {
  onClickNavButton?: any;
  idVals: any;
  onClickCommentsPath?: any;
};

const Comments: React.FC<Props> = ({
  onClickNavButton,
  idVals,
  onClickCommentsPath,
}) => {
  const [submitLoading, setSubmitLoading] = useState<any>({
    sendReplyLoad: [],
    loadmoreLoad: [],
  });
  // const [loadMoreLoading, setLoadMoreLoading] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [openTaskDetalPopup, setOpenTaskDetalPopup] = useState(false);
  const [taskIdData, setTaskIdData] = useState<any | null>(null);
  const [dirtyValues, setDirtyValues] = useState<boolean>(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [commentsData, setCommentsData] = useState<any>();
  const [subTitleName, setSubTitleName] = useState("");
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const my_tasks = useSelector(updateMyDetailOnToggle);

  const navDetails = useSelector(getNavDetails);

  const navpath = useSelector(getNavPath);

  const commentList = useSelector(getCommentListData);
  const commentloading = useSelector(getCommentLoading);
 
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { isValid },
  } = useForm();

  const loadCommentList = ({ pageParam = 0 }) => {
    const getCommentPayload = {
      paging: {
        pageIndex: pageParam.toString(),
        pageSize: "5",
        pageSizeOptions: pageSizeOptions,
      },
      search: "",
      filter: {
        user_type: "",
      },
      show_inactive: false,
      mine: my_tasks,
    };
    return getCommentData.getCommentList({
      data: navDetails.path[navDetails.path.length - 1],
      getCommentPayload,
    });
  };

  useEffect(() => {
    dispatch(setCommentloading([]));
    queryClient.refetchQueries({
      queryKey: [
        `getCommentList${
          navDetails.path[navDetails.path.length - 1]?.masterId
        }_${navDetails.path[navDetails.path.length - 1]?.id}_${my_tasks}`,
      ],
      exact: true,
    });
  }, [my_tasks]);

  const {
    data: commentData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
    isLoading: loadComment,
    refetch,
    status,
    ...result
  } = useInfiniteQuery({
    queryKey: [`getCommentList${idVals?.masterId}_${idVals?.id}_${my_tasks}`],
    queryFn: loadCommentList,
    staleTime: Infinity,
    // keepPreviousData: true,
    getNextPageParam: (lastPage, pages) => {
      const total = lastPage?.data?.total || 0;
      const len = pages.length * 5;
      return len < total ? pages.length : undefined;
    },
    onSuccess(data) {
      const setCommentsData = JSON.parse(JSON.stringify(data));
      dispatch(setCommentList(setCommentsData));
    },
    // select:(data:any)=>data.pages.map((item:any)=>item.data)
  });

  const {
    data: loadmoreData,
    mutate: loadMoreReply,
    isLoading: loadMoreReplyLoading,
  } = useMutation({
    mutationFn: getCommentData.getLoadmore,
    onSuccess(value, variables) {
      loadMoreReplyClick({ value, id: variables.taskId });
      const loadmoreLoadIngindexVal = { ...submitLoading };
      loadmoreLoadIngindexVal.loadmoreLoad = [
        ...loadmoreLoadIngindexVal.loadmoreLoad,
      ];
      loadmoreLoadIngindexVal.loadmoreLoad =
        loadmoreLoadIngindexVal.loadmoreLoad.filter(
          (val: any) => val !== variables.taskId
        );
      setSubmitLoading(loadmoreLoadIngindexVal);
    },
  });

  const { data: replydata, mutate: saveReply } = useMutation({
    mutationFn: getCommentData.saveReplyComment,
    onSuccess(data, variables) {
      refetch();
      const submitLoadingVal = { ...submitLoading };
      submitLoadingVal.sendReplyLoad = [...submitLoadingVal.sendReplyLoad];
      submitLoadingVal.sendReplyLoad = submitLoadingVal.sendReplyLoad.filter(
        (id: any) => id !== variables.savePayload.mco_parent_id
      );
      setSubmitLoading(submitLoadingVal);
    },
  });

  const { mutate: saveComment, isLoading: saveCommentsLoading } = useMutation({
    mutationFn: getCommentData.saveReplyComment,
    onSuccess() {
      setOpenEditPopup(false);
      refetch();
    },
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: getCommentData.deleteComment,
    onSuccess(data, variables) {
      refetch();
      SyToast("deleted successfully", "bottom-right", "short", "success");
    },
  });

  const getCommentDetails = (data: any) => {
    onClickMenuButton({ data });
    setCommentsData(data.data);
  };

  const onClickMenuButton = (data: any) => {
    if (data?.data.val?.label === "delete") {
      deleteMutate(data?.data?.data?.mco_id);
    }
    if (data?.data.val?.label === "edit") {
      setOpenEditPopup(true);
      setValue("mco_text", data?.data?.data.mco_text);
    }
  };

  const handleSendReply = (
    data: any,
    id: any,
    taskid: any,
    index: any,
    pro_id: any
  ) => {
    const submitLoadingVal = { ...submitLoading };
    submitLoadingVal.sendReplyLoad = [...submitLoadingVal.sendReplyLoad];
    submitLoadingVal.sendReplyLoad.push(id);
    const savePayload = {
      mco_text: data.reply ? data.reply : null,
      mco_pro_id: pro_id,
      mco_task_id: taskid,
      mco_parent_id: id,
    };
    saveReply({ savePayload, index });
    setSubmitLoading(submitLoadingVal);
  };

  const loadMoreClick = (data: any) => {
    const loadmoreLoadIngindexVal = { ...submitLoading };
    loadmoreLoadIngindexVal.loadmoreLoad = [
      ...loadmoreLoadIngindexVal.loadmoreLoad,
    ];
    loadmoreLoadIngindexVal.loadmoreLoad.push(data.id);
    const payload = {
      paging: {
        pageIndex: "1",
        pageSize: "10",
      },
      current_index: "5",
    };
    loadMoreReply({
      itemId: data.pro_id,
      payload,
      taskId: data.id,
      index: data.index,
    });
    setSubmitLoading(loadmoreLoadIngindexVal);
  };

  const loadMoreReplyClick = (val: any) => {
    queryClient.setQueryData(
      [`getCommentList${idVals.masterId}_${idVals.id}_${my_tasks}`],
      (data: any) => {
        const pages = data?.pages.map((page: any) => {
          return {
            ...page,
            data: {
              ...page.data,
              list: page.data.list.map((item: any) => {
                if (item.mco_id == val.id) {
                  const array = val.value?.data.replies;
                  return {
                    ...item,
                    replies: [...item.replies, ...array],
                  };
                } else {
                  return item;
                }
              }),
            },
          };
        });
        const result = { ...data, pages };
        dispatch(setCommentList(result));
        return { ...data, pages };
      }
    );
  };

  const loadMoreBtnClick = (id: any, pro_id: any, index: number) => {
    loadMoreClick({ id, pro_id, index });
  };

  const onClickNavLink = (val: any) => {
    if (val?.id) {
      dispatch(onChangeNav(val));
      onClickNavButton(val);
    }
  };

  const handleScroll = (e: any) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
      ) < 1;
    if (bottom && hasNextPage) {
      fetchNextPage({
        pageParam: currentPage + 1,
      });
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePopupClose = () => {
    if (dirtyValues) {
      onClickNavButton(navDetails.path[navDetails.path.length - 1], {
        from: "popup",
        mine: my_tasks,
      });
    }
  };

  const handleTaskDetailsPopup = (data: any) => {
    setSubTitleName(data.task_name);
    setTaskIdData({ projectId: data.mco_pro_id, taskId: data.mco_task_id });
    setOpenTaskDetalPopup(true);
  };

  const updateDash = (data: boolean) => {
    return setDirtyValues(data);
  };

  const onSubmit = () => {
    let formvalues = getValues();
    const savePayload = {
      mco_id: commentsData.mco_id,
      mco_text: formvalues.mco_text,
    };
    saveComment({ savePayload });
  };

  const onCancel = () => {
    setOpenEditPopup(false);
  };

  const editContent = () => {
    return (
      <div onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name={"mco_text"}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <SyTextEditor
                  value={value}
                  onChange={onChange}
                  name={"mco_text"}
                />
              );
            }}
          />
        </div>
        <div className="d-flex justify-content-end mt-4 pt-1">
          <>
            <Sybutton
              className={`Secondarybutton me-3`}
              label={t("cancel")}
              type={"reset"}
              onBtnClick={onCancel}
              size={"sm"}
            />
            <Sybutton
              type={"submit"}
              className={"primarybutton"}
              size={"sm"}
              label={t("send")}
              onBtnClick={onSubmit}
              disabled={saveCommentsLoading}
              Loadings={saveCommentsLoading}
            />
          </>
        </div>
      </div>
    );
  };

  const onClickPath = (data: any) => {
    onClickCommentsPath(data);
  };
  const getTaskNameOnSucess = (title: string) => {
    setSubTitleName(title);
  };

  return (
    <>
      <div
        className={`${Stylesheet.comments} sy-custom-scroll`}
        onScroll={handleScroll}
      >
        {commentloading ? (
          <div className=" d-flex justify-content-center align-items-center mt-5">
            <SyLoadingIcon size="sm" />
          </div>
        ) : (
          commentList?.pages?.length > 0 &&
          commentList?.pages?.map((data: any, index: number) =>
            data.data.total === 0 || null ? (
              <div className="card mt-4 ms-4 me-4 mb-5" key={index}>
                <div className="container rounded">
                  <p className=" text-center p-3 opacity-50 main-font">
                    {t("no_data_found")}
                  </p>
                </div>
              </div>
            ) : (
              <div key={index}>
                {data?.data?.list?.map((item: any, index: number) => {
                  console.log(item.limited_Access,'item.limited_Access')

  const disable =
  item?.main_access?.portfolio_access ||
  item?.main_access?.program_acess||
  item?.main_access?.project_access||
  item?.mopus_admin
      ? false
      : true;


                  return (
                    <div key={index} className="">
                      <div className="container-fluid">
                        <SyNavPath
                          paths={item.path}
                          onClick={onClickPath}
                          loading={false}
                          size="small-font"
                          disable={false}
                        />
                      </div>
                      <p className="sy-primary mt-0 ms-2 ps-1 me-2 mb-0 small-font">
                        <SyTooltip title={item.task_name}>
                          <span
                            className="pointer"
                            onClick={() => handleTaskDetailsPopup(item)}
                          >
                            {item.task_name}
                          </span>
                        </SyTooltip>
                      </p>
                      <div className="container">
                        <CommentCard
                          data={item}
                          getComment={getCommentDetails}
                        />

                        <div className="d-flex justify-content-end">
                          {item?.replies?.length > 0 && (
                            <SyIcons
                              name={"subdirectory_arrow_right"}
                              className={
                                "d-flex justify-content-center mt-2 col-2"
                              }
                            />
                          )}
                          <div className="d-flex container_fluid flex-column col-xl-10 col-sm-10 col-lg-10 col-md-10">
                            {item?.replies?.map((data: any, index: number) => {
                              return (
                                <div
                                  key={index}
                                  className="mt-1 container_fluid"
                                >
                                  <ReplyCard
                                    data={data}
                                    getComment={getCommentDetails}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {item?.count > item?.replies?.length ? (
                          <div className="d-flex align-items-center justify-content-center">
                            {submitLoading.loadmoreLoad.includes(
                              item.mco_id
                            ) ? (
                              <span className="p-1">
                                <SyLoadingIcon size="sm" />
                              </span>
                            ) : (
                              <span
                                className="pointer p-1"
                                onClick={() =>
                                  loadMoreBtnClick(
                                    item.mco_id,
                                    item.mco_pro_id,
                                    index
                                  )
                                }
                              >
                                {t("load_more")}
                              </span>
                            )}
                          </div>
                        ) : null}
                        {!disable ||
          item.limited_Access ||
          (item.access_member &&
            item.enable_comments === "1")? (
                          <Replyfield
                            index={index}
                            loading={submitLoading.sendReplyLoad.includes(
                              item.mco_id
                            )}
                            handleSendReply={handleSendReply}
                            item={item}
                          />
                        ) : (
                          void null
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          )
        )}
      </div>
      {openTaskDetalPopup && (
        <Popup
          open={openTaskDetalPopup}
          close={setOpenTaskDetalPopup}
          onClose={handlePopupClose}
          scroll="paper"
          showHeader={true}
          title={subTitleName}
          showCloseIcon={true}
          model_type={"alert"}
          maxWidth={"lg"}
          children={
            <SyTaskdetailsPopup
              getTaskNameOnSucess={getTaskNameOnSucess}
              oncloseclick={handlePopupClose}
              pro_id={taskIdData.projectId}
              task_id={taskIdData?.taskId}
              updateDashboard={updateDash}
            />
          }
        />
      )}
      {openEditPopup && (
        <Popup
          open={openEditPopup}
          close={setOpenEditPopup}
          showHeader={true}
          title={t("edit_comment")}
          children={editContent()}
          model_type={"responsive"}
        />
      )}
      {isFetchingNextPage && hasNextPage ? (
        <div className="d-flex justify-content-center">
          <SyLoadingIcon size="sm" />
        </div>
      ) : null}
    </>
  );
};
export default Comments;
