import React from "react";
import SyIcons from "../sy-icons/sy-icons";
import { SyLoadingIcon, SyTooltip } from "..";
import Stylesheet from "./style.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  paths: [];
  onClick?: any;
  icon?: string;
  loading?: boolean;
  size?: "main-font" | "sub-font" | "small-font";
  fromNav?: boolean;
  disable?: boolean;
};

const SyNavPath = (props: Props) => {
  const {
    paths,
    onClick,
    icon,
    loading,
    size,
    fromNav = false,
    disable,
  } = props;
  const { t } = useTranslation();
  const NavList = (props: any) => {
    const { item, index, onClick } = props;

    const handleClick = () => {
      if (!loading) {
        return onClick({ ...item, index: index });
      }
    };
    return (
      <p
        onClick={handleClick}
        className={`${
          fromNav && paths.length - 1 === index
            ? `ellipsis`
            : Stylesheet.navNameLength
        }  ellipsis mb-0  ${
          !disable && `pointer underlineAnimation`
        } sy-primary ${size ? size : "title-font semi-b"}`}
      >
        {item?.name}
      </p>
    );
  };

  return (
    <div className="d-flex align-items-center mb-1">
      {icon ? (
        <div className="d-flex align-items-center ">
          <SyIcons
            name={icon}
            className={`pointer sy-primary`}
            value={{ id: 1, masterId: 0 }}
            onClick={onClick}
            toolTipTitle={t('home')}
          />
          <SyIcons name={"chevron_right"} className={`sy-primary`} />
        </div>
      ) : null}
      {paths?.map((path: any, index: number) => (
        <div key={index} className="d-flex align-items-center ellipsis">
          <NavList item={path} index={index} onClick={onClick} />
          {paths?.length - 1 != index ? (
            <SyIcons
              name={"chevron_right"}
              className={`${Stylesheet.navicon} sy-primary`}
            />
          ) : null}
        </div>
      ))}
      {loading && (
        <div className="ps-1">
          <SyLoadingIcon />
        </div>
      )}
    </div>
  );
};

export default SyNavPath;
