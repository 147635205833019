import React, { useEffect, useState } from "react";
import SyCircularProgress from "@mui/material/CircularProgress";
import Stylesheet from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { getData } from "../../../../../../shared/utils";
import { Typography } from "@mui/material";

type Props = {};

interface CircleProps {
  value: number;
  total: number;
}
const SyCircleProgressbar: React.FC<CircleProps> = ({ value, total }) => {
  const [color, setColor] = useState<any>("");

  useEffect(() => {
    async function getSecColor() {
      const secondaryColor = await getData("secondary_color");
      setColor(secondaryColor);
    }
    getSecColor();
  }, []);

  const percentage = (value / total) * 100;
  const { t } = useTranslation();
  const size = 120,
    thickness = 4;
  const progressSx = {
    borderRadius: "50%",
    boxShadow: `inset 0 0 0 ${(thickness / 44) * size}px ${color}`,
    strokeLinecap: "round",
  };
  const inprogress = total - value;
  return (
    <div className="card border border-0 align-items-center">
      <div className="justify-content-center mb-3">
        <h6 className="main-font semi-b">{t("my_tasks")}</h6>
      </div>
      <div className="mb-3 ">
        {/* <span style={{position: 'absolute', top: '10px', left: '2px'}}>100%</span> */}
        <SyCircularProgress
          variant="determinate"
          value={percentage > 0 ? percentage : 0}
          size={70}
          thickness={7}
          sx={progressSx}
          classes={{ colorPrimary: Stylesheet.primaryColor }}
        />
        <span className="d-flex justify-content-center">
          <Typography
            position="absolute"
            style={{
              marginTop: "68px",
              fontSize: 17,
              fontWeight: 400,
            }}
          >
            <span>{inprogress ? inprogress : 0}</span>
          </Typography>
        </span>
      </div>

      <div className={`${Stylesheet.fontSize} mt-1 justify-content-center`}>
        <span className="d-flex justify-content-center">
          <p className="mt-0 mb-0 sub-font">
            {total > 0 ? total : 0} {t("task")}
          </p>
        </span>
        {/* <span className="d-flex ellipsis text-muted sub-font">
          {t('are_currently_assigned_to_me')}
        </span> */}
      </div>
    </div>
  );
};

export default SyCircleProgressbar;
