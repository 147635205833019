import React from 'react'

type Props = {
  className?: any
  name:string
  iconType?: 'icons'|'symbols'
  iconStyle?:'outlined' | 'filled'|'round'| 'sharp'|'two-tone'
  onClick?: any
  value?: any

}

const SyIcons = ({ className,name, iconType='icons',iconStyle='outlined', onClick, value }: Props) => {

const onClickIcon=()=>{
  if(onClick) onClick(value)
}

  return (
    <span className={`${className} material-${iconType}-${iconStyle}` }  onClick={onClickIcon}>
      {name}
    </span>
  )
}

export default SyIcons