import { createSlice } from "@reduxjs/toolkit";

interface RootState {
  commentList: any;
  commentListLoading: boolean;
}

const initialState: RootState = {
  commentList: "",
  commentListLoading: false,
};

export const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    setCommentList: (state, { payload }) => {
      state.commentList = payload;
      state.commentListLoading = false;
    },
    setCommentloading: (state, { payload }) => {
      state.commentList = [];
      state.commentListLoading = true;
    },
    resetCommentSlice:()=>initialState
    
  },
});

export const { setCommentList, setCommentloading,resetCommentSlice} = commentSlice.actions;

export const getCommentListData =(state:any)=> state.commentReducer.commentList;

export const getCommentLoading =(state:any)=> state.commentReducer.commentListLoading;

export default commentSlice.reducer;
