import { forwardRef } from "react";
import Stylesheet from "./style.module.scss";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import SyLoadingIcon from "../../sy-loading-icon";
import Errormessage from "../errormessage/errormessage";
interface InputFormProps {
  placeholder?: string;
  type: "text" | "number" | "email" | "password" | "checkbox";
  onChange?: (event: any) => void;
  onInputChange?: (event: any) => void;
  defaultValue?: string | number;
  value?: any;
  inputFieldWithIcon?: boolean;
  icontype?: string;
  submitLoading?: any;
  handleSubmit?: any;
  onInbutIconClick?: any;
  error?: any;
  click?: any;
  defaultChecked?: any;
  onclick?: any;
  checkboxPlaceholderSide?: "left" | "right";
  disable?:boolean
}

const InputForm = forwardRef<HTMLInputElement, InputFormProps>(
  (props: InputFormProps, ref) => {
    const {
      placeholder = "",
      type,
      defaultValue,
      onChange,
      onInputChange,
      value,
      inputFieldWithIcon,
      icontype,
      submitLoading,
      disable,
      handleSubmit,
      onInbutIconClick,
      defaultChecked,
      error,
      click,
      onclick,
      checkboxPlaceholderSide = "right",
      ...rest
    } = props;

    if (type === "checkbox") {
      return (
        <>
          <label className="d-flex">
            {checkboxPlaceholderSide === "left" && (
              <p className="sub-font me-2">{placeholder}</p>
            )}
            <input
              ref={ref}
              type="checkbox"
              className={`${Stylesheet.form_check} me-2 pointer`}
              {...rest}
              onChange={onChange}
              onClick={onclick}
              checked={defaultChecked}
              value={value}
              disabled={disable}
              
            />
            {checkboxPlaceholderSide === "right" && (
              <p className={`${disable? 'opacity-50' :''} sub-font `}>{placeholder}</p>
            )}
          </label>
        </>
      );
    }

    return (
      <>
        {!inputFieldWithIcon && (
          <TextField
            ref={ref}
            fullWidth
            label={placeholder}
            id="outlined-size-small"
            defaultValue={defaultValue}
            onChange={onInputChange}
            value={value}
            size="small"
            type={type}
            disabled={disable}
            {...rest}
          />
        )}

        {inputFieldWithIcon && (
          <TextField
            {...rest}
            ref={ref}
            fullWidth
            label={placeholder}
            id="outlined-size-small"
            defaultValue={defaultValue}
            onChange={onInputChange}
            value={value}
            size="small"
            type={type}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {submitLoading ? (
                    <SyLoadingIcon />
                  ) : (
                    <span
                      className={`${Stylesheet.icon_color} material-icons-round pointer display-none`}
                      onClick={() => {
                        if (value) {
                          onInbutIconClick();
                        }
                      }}
                    >
                      {icontype}
                    </span>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}

        {error ? (
          <span className="d-flex justify-content-end">
            <Errormessage message={error} />
          </span>
        ) : null}
      </>
    );
  }
);

export default InputForm;
