import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const overlayLoadingSlice = createSlice({
  name: "overlayLoading",
  initialState,
  reducers: {
    displayOverlayLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export const { displayOverlayLoading } = overlayLoadingSlice.actions;

export const getIsLoading = (state: any) => state.overLayLoading.loading;

export default overlayLoadingSlice.reducer;
