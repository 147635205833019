
const updateObject = (data: any, changable: any) => {
    data={...data}
    data.ts_color = changable[0].ts_color;
    data.ts_id = changable[0].ts_id;
    data.ts_name = changable[0].ts_name;
    data.ts_value = changable[0].ts_value;    
    return data;
   };  

 export const chipChange = (taskList: any, dropdownindex: any,variables:any) => {

  
   const list= taskList?.data?.map((data: any, index: number) => {

      
      return index === dropdownindex
        ? updateObject(data, variables)
        : data;
    });
    return {data:list}
  };
  export const masterStatus = [
    { ts_value:"1",ts_name:"offen"},
    { ts_value:"2",ts_name:"in Bearbeitung"},
    { ts_value:"3",ts_name:"erledigt"},
    { ts_value:"4",ts_name:"abgeschlossen"},
  ]