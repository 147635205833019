import { createSlice } from "@reduxjs/toolkit";

interface RootState {
  analyticData: any;
  analyticLoading: boolean;
}

const initialState: RootState = {
  analyticData: '',
  analyticLoading: false,
}


export const analyticSlice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    setAnalyticData: (state, { payload }) => {
      state.analyticData = payload;
      state.analyticLoading = false;
    },
    setAnalyticLoading: (state, { payload }) => {
      state.analyticData = [];
      state.analyticLoading = true;
    },
    resetAnalyticSlice: () => initialState

  }
})
export const { setAnalyticData, setAnalyticLoading,resetAnalyticSlice } = analyticSlice.actions

export const getAnalyticsDate = (state: any) => state.analyticReducer.analyticData;

export const getAnalyticsLoading = (state: any) => state.analyticReducer.analyticLoading;


export default analyticSlice.reducer;