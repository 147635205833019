import { ClickAwayListener, Fade, Tooltip } from "@mui/material";

type Props = {
  htmlElement: any;
  children: any;
  open?: boolean;
  disableHover?: boolean;
  disableFocus?: boolean;
  disableInteractive?: boolean;
  disableTouch?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onClickAway?: () => void;
};

const SyHtmlTooltip = (props: Props) => {
  const {
    htmlElement,
    children,
    onClose,
    onOpen,
    onClickAway = () => void null,
    open,
    disableFocus,
    disableHover,
    disableInteractive,
    disableTouch,
  } = props;

  const componentsProps = {
    tooltip: {
      sx: {
        boxShadow: "2px 2px 2px 2px lightgrey",
        bgcolor: "white",
        color: "black",
        "& .MuiTooltip-arrow": {
          color: "white",
        },
      },
    },
  };

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <Tooltip
          children={children}
          title={htmlElement}
          disableHoverListener={disableHover}
          disableFocusListener={disableFocus}
          disableInteractive={disableInteractive}
          disableTouchListener={disableTouch}
          enterDelay={500}
          leaveDelay={500}
          TransitionComponent={Fade}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          componentsProps={componentsProps}
        />
      </ClickAwayListener>
    </>
  );
};

export default SyHtmlTooltip;
