import { useForm, Controller } from "react-hook-form";
import styleSheet from "./style.module.scss";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import { InputForm } from "../../../../../../shared/components/forms";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../service/comment-service";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { processSchema } from "./process-schema";
import dayjs from "dayjs";
import Datepicker from "../../../../../../shared/components/forms/date-picker";
import { useNavigate } from "react-router-dom";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  displayOverlayLoading,
  getModuleDetails,
} from "../../../../feature/common-slice";
import {
  getNavDetails,
  updateMasterItems,
} from "../../../../feature/simpler-slice";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { useSelector } from "react-redux";
import { syWindowReload } from "../../../../../../shared/utils";
import { SyIcons } from "../../../../../../shared/components";
import { loadingIconButton } from "../create-process-footer/create-process-footer";
import { status } from "./config";
import { getStatus } from "./helper";
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";
interface Props {
  taskId: (id: number) => void;
  switchTab: (id: string) => void;
  statusId: (id: string) => void;
  nullclick: () => void;
  getloading: (loading: boolean) => void;
  clicked: any;
  editId: any;
  edit: boolean;
  programId: any;
  handleClose: any;
}

type formValues = {
  pro_name: string;
  pro_start_date: string;
  pro_end_date: string | null;
  pro_status: any;
  pro_budget: number | null;
  labels: any;
  pro_prm_id: any;
  parent_name: any;
};

const currentDate = Date();

const ProcessManage = (props: Props) => {
  const dispatch = useDispatch();
  const {
    programId,
    taskId,
    editId,
    clicked,
    switchTab,
    getloading,
    nullclick,
    statusId,
    edit,
    handleClose,
  } = props;
  const { t } = useTranslation();
  const [label, setlabel] = useState();
  const [loading, setloading] = useState<"save" | "next" | null>(null);
  const [task, setTask] = useState();
  const [cancel, setCancel] = useState(false);
  const navigate = useNavigate();

  const navDetails = useSelector(getNavDetails);
  const lableDetails = useSelector(getModuleDetails);

  const {
    data,
    mutate: getProcessDetailsMutate,
    isLoading: editProLoading,
  } = useMutation({
    mutationFn: commentService.editProcess,
    onSuccess(data: any) {
      setFormValue(data.data);
    },
  });

  useEffect(() => {
    dispatch(displayOverlayLoading(editProLoading ? 1 : 0));
  }, [editProLoading]);

  useEffect(() => {
    edit ? getProcessDetailsMutate(editId) : void null;
  }, [edit]);

  useEffect(() => {
    clicked === "save"
      ? onSubmit("close")
      : clicked === "savenext"
      ? onSubmit("next")
      : void null;
    nullclick();
  }, [clicked]);

  const { mutate: getLabelMutate, isLoading } = useMutation({
    mutationFn: commentService.getProcessLabel,
    onSuccess(data) {
      labelOptions(data);
    },
  });
  const { mutate: portfolioList, isLoading: programLoading } = useMutation({
    mutationFn: commentService.getProgramList,
    onSuccess(data) {
      parentOptions(data);
    },
  });

  const parentOptions = (data: any) => {
    const optionsArray: any = [];
    data?.data?.forEach((data: any) => {
      optionsArray.push(data);
    });

    setTask(optionsArray);
  };

  const handleParentLabel = (data: any) => data?.prm_name;
  const handleParentFilter = (val: any) => {
    portfolioList(val);
  };

  const newPfoId = navDetails?.path[1]?.id;
  const {
    data: saveData,
    mutate: saveTaskMutate,
    isLoading: isloading,
  } = useMutation({
    mutationFn: commentService.saveNewTask,
    onSuccess(data: any, variables) {
      const obj = {
        id: data.data.pro_id,
        name: data.data.pro_name,
        desc: "",
        pro_prm_id: data.data.pro_prm_id,
        status: data.data.pro_status,
        masterId: 3,
      };
      setCancel(
        obj.pro_prm_id != newPfoId && variables.action == "next" ? true : false
      );
      handleClose(
        obj.pro_prm_id != newPfoId && variables.action == "next" ? true : false
      );

      if (variables.action == "close") {
        navigate("/");
      } else {
        switchTab("employee");
      }
      navDetails.path.length !== 0 &&
        dispatch(
          updateMasterItems({
            data: obj,
            mode: !edit ? "add" : "edit",
            newPfoId,
            from: variables.action == "next" ? "" : "program",
          })
        );

      const message = data.data.message;
      SyToast(t(message), "top-right", "short", "success");
      taskId(data.data.pro_id);
      setloading(null);
    },
    onError(error: any) {
      const errorMessage = error.message;
      SyToast(t(errorMessage), "top-right", "short", "error");
    },
  });

  useEffect(() => {
    if (isloading) {
      getloading(true);
    } else {
      getloading(false);
    }
  }, [isloading]);

  const formDefaultValues = {
    pro_name: "",
    pro_start_date: currentDate,
    pro_end_date: edit ? data?.data.pro_end_date : null,
    pro_status: edit ? getStatus(data?.data.pro_status, status) : status[0],
    pro_budget: edit ? data?.data.pro_budget : null,

    labels: [],
  };

  const setFormValue = (editData: any) => {
    const defaultProgram = editData.project;

    setValue("pro_name", editData.pro_name, { shouldValidate: true });
    setValue("pro_start_date", editData.pro_start_date, {
      shouldValidate: true,
    });
    setValue("pro_end_date", editData.pro_end_date, { shouldValidate: true });
    setValue("pro_status", getStatus(editData.pro_status,status), {
      shouldValidate: true,
    });
    setValue("pro_budget", parseInt(editData.pro_budget), {
      shouldValidate: true,
    });
    setValue("labels", editData.labels, { shouldValidate: true });
    setValue("parent_name", defaultProgram);
  };

  const {
    getValues,
    control,
    trigger,
    setValue,
    setFocus,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<formValues>({
    mode: "all",
    defaultValues: formDefaultValues,
    shouldUnregister: true,
    resolver: yupResolver<yup.AnyObjectSchema>(processSchema),
  });

  useEffect(() => {
    setFocus("pro_name");
  }, []);

  const labelOptions = (data: any) => {
    const optionsArray: any = [];
    data.data.forEach((data: any) => {
      optionsArray.push(data);
    });
    setlabel(optionsArray);
  };
  
  const handleInput = (inputvalue: any) => {
    const payload = { data: inputvalue };
    getLabelMutate(payload);
  };

  const handleStatusFilter = () => void 0;

  const handleStatusLabel = (data: any) => data.value;

  const handleLabelOpt = (data: any) => data.l_name;

  const onSubmit = (action: "close" | "next") => {
    trigger();
    if (isValid) {
      const data = getValues();
      statusId(data.pro_status?.id);
      data.pro_status = data.pro_status ? data.pro_status?.id : null;
      data.pro_start_date = getDateLocaleString(data.pro_start_date,"dateFormate");
      data.pro_prm_id = edit ? data.parent_name.prm_id : programId;
      data.pro_end_date = data.pro_end_date
        ? getDateLocaleString(data.pro_end_date,"dateFormate")
        : null;

      const payload = {
        fields: {
          default: data,
        },
        labels: data.labels ? data.labels.map((data: any) => data.l_id) : null,
      };
      delete payload.fields.default.labels;
      delete payload.fields.default.parent_name;
      const taskid = edit
        ? editId
        : saveData?.data.pro_id
        ? saveData.data.pro_id
        : "";

      action === "close"
        ? saveTaskMutate({ taskid, payload, action: "close" })
        : action === "next"
        ? saveTaskMutate({ taskid, payload, action: "next" })
        : void null;
    }
  };

  const handleSaveClick = () => {
    setloading("save");
    onSubmit("close");
  };

  const handleCloseClick = () => {
    if (cancel) {
      navigate("/");
      syWindowReload();
    } else {
      navigate("/");
    }
  };
  const handleLabelFocus = () =>getLabelMutate({});
  const handleFormSubmit = (formVal: formValues) => {};
  return (
    <>
      <div className={` ${styleSheet.tab_style} rounded-top `}>
        <div className={` ${styleSheet.tab_color} rounded p-3`}>
          <div>
            <p className={`${styleSheet.title} mb-2`}>
              {edit ? t("edit_process") : t("new_process")}
            </p>
          </div>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="col-sm-6 col-xm-12 ps-2">
              <Controller
                name="pro_name"
                control={control}
                render={({ field, field: { value, onChange } }) => {
                  return (
                    <InputForm
                      {...field}
                      placeholder={t("process_area")}
                      type={"text"}
                      value={value}
                      onInputChange={onChange}
                      error={errors.pro_name && errors.pro_name.message}
                    />
                  );
                }}
              />
            </div>
            <div>
              <div className={` row g-0   mt-2`}>
                {/* datepickers */}
                <div className="col-md-3 col-sm-6 col-xm-12 ps-2 py-2">
                  <Controller
                    name="pro_start_date"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      const handleChange = (val: any) => {
                        onChange(val);
                        trigger("pro_end_date");
                      }
                      return (
                        <Datepicker
                          Placeholder={t("pro_start_date")}
                          date={value}
                          selectedDate={handleChange}
                          errorMessage={errors?.pro_start_date?.message}
                        />
                      );
                    }}
                  />
                </div>
                <div className="col-md-3 col-sm-6 col-xm-12 ps-2 py-2">
                  <Controller
                    name="pro_end_date"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Datepicker
                          Placeholder={t("pro_end_date")}
                          date={value}
                          selectedDate={onChange}
                          errorMessage={errors?.pro_end_date?.message}
                        />
                      );
                    }}
                  />
                </div>
                {/* status dropdown */}
                <div className="col-md-3 col-sm-6 col-xm-12 ps-2 py-2">
                  <Controller
                    name="pro_status"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Dropdown
                          {...field.onBlur}
                          options={status}
                          placeholder={t("status")}
                          controlledVal={field.value}
                          handleFilter={handleStatusFilter}
                          handleChange={field.onChange}
                          getoptlabel={handleStatusLabel}
                          clearIcon={true}
                          errorMessage={errors?.pro_status?.message}
                        />
                      );
                    }}
                  />
                </div>
                {/* pro_budget */}
                <div className="col-md-3 col-sm-6 col-xm-12 ps-2 py-2 ">
                  <Controller
                    name="pro_budget"
                    control={control}
                    render={({ field, field: { onChange, value } }) => {
                      return (
                        <InputForm
                          {...field}
                          placeholder={t("budget")}
                          type={"number"}
                          value={value ? value : ""}
                          onInputChange={onChange}
                          error={errors?.pro_budget?.message}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              {/* label search */}
              {lableDetails?.label_mgmt_mod && (
                <div className={`col-sm-6 xm-12 ps-2 py-2`}>
                  <div>
                    <Controller
                      name="labels"
                      control={control}
                      render={({ field }) => {
                       
                        return (
                          <Dropdown
                            {...field.onBlur}
                            options={label}
                            placeholder={t("label")}
                            enableSearchBox={true}
                            multiple={true}
                            loading={isLoading}
                            onFocus={handleLabelFocus}
                            controlledVal={field.value}
                            handleFilter={handleInput}
                            handleChange={field.onChange}
                            getoptlabel={handleLabelOpt}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              {editId && navDetails?.path[0]?.masterId != "3" ? (
                <div className={`col-sm-6 xm-12 ps-2 py-2`}>
                  <div>
                    <Controller
                      name="parent_name"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Dropdown
                            options={task}
                            placeholder={t("program")}
                            controlledVal={value ? value : null}
                            handleFilter={handleParentFilter}
                            loading={programLoading}
                            enableSearchBox={true}
                            handleChange={onChange}
                            getoptlabel={handleParentLabel}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className={`d-flex justify-content-end mt-3 pt-5 ${styleSheet.button_space}`}
              >
                <Sybutton
                  type={"button"}
                  className={`Secondarybutton d-none d-sm-block`}
                  size={"sm"}
                  label={t("cancel")}
                  onBtnClick={handleCloseClick}
                />
                 <Sybutton
                  type={"button"}
                  className={"iconButton d-flex align-items-center d-block d-sm-none"}
                  size={"sm"}
                  onBtnClick={handleCloseClick}
                  label={<SyIcons name="keyboard_backspace" />}
                />
                <Sybutton
                  type={"button"}
                  className={`primarybutton d-none d-sm-block`}
                  size={"sm"}
                  value={"save"}
                  label={t("save")}
                  Loadings={loading === "save" && isloading}
                  disabled={isloading ? true : false}
                  onBtnClick={handleSaveClick}
                />
                <Sybutton
                  type={"button"}
                  className={"iconButton  d-flex align-items-center d-block d-sm-none"}
                  size={"sm"}
                  value={'save'}
                  onBtnClick={handleSaveClick}
                  label={loading === "save" && isloading ? loadingIconButton : <SyIcons name="save" />}
                  disabled={!!isloading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ProcessManage;
