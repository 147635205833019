import React from "react";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import { useTranslation } from "react-i18next";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import commentService from "../../service/comment-service";
import { useMutation } from "@tanstack/react-query";

type Props = {
  list?: any;
  id: any;
  type: any;
  close?: any;
  updateList?: any;
  discription?:any
};

const UpdateStatus = ({ type, id, list, close, updateList,discription }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    formState: { isValid, errors },
  } = useForm<any>({
    mode: "all",
  });
  const { mutate: saveTag } = useMutation({
    mutationFn: commentService.updateTag,
    onSuccess(data, variables) {
      updateList("tag");
    },
  });
  const { mutate: saveStatus } = useMutation({
    mutationFn: commentService.updateStatus,
    onSuccess(data, variables) {
      updateList(true);
    },
  });
  const save = () => {
    close(false);
    const payload = getValues();

    if (type === "status") {
      saveStatus({ id: id.id, pro_id: id.pro_id, payload: payload.status });
    } else {
      saveTag({ id: id.id, pro_id: id.pro_id, payload: payload.tags });
    }
  };

  const handleInput = (data: any) =>
    type === "tags" ? data.pt_name : data.ts_name;
  const defaultList = list.filter((data: any) =>
    type === "tags" ? data.pt_id !== id.id : data.ts_id !== id.id
  );

  return (
    <form>
      <div>
        <div>
          <p className="mb-3">{t(discription)}</p>
        </div>
        <div className=" mb-3">
          <Controller
            name={type}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  options={defaultList}
                  placeholder={t(type)}
                  handleFilter={() => void null}
                  handleChange={onChange}
                  getoptlabel={handleInput}
                  value={value}
                />
              );
            }}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Sybutton
            type={"submit"}
            className={"primarybutton"}
            size={"md"}
            label={"save"}
            onBtnClick={save}
          />
        </div>
      </div>
    </form>
  );
};

export default UpdateStatus;
