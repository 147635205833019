import React from "react";
import styleSheet from "./style.module.scss";
import SyAsynSelect from "../../../../../../shared/components/sy-asyn-select";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import commentService from "../../service/comment-service";
import {
  asyncWildDatapayload,
  getAsyncWildData,
} from "../../../../../../shared/utils";
import * as yup from "yup";
import Sy_CustomFooter from "../../../../../../shared/components/sy-model/sy-customfooter";
import { useMutation } from "@tanstack/react-query";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { limitedAccess } from "../../schema-validation/schema-validation";

type Props = {};
interface AddPopup {
  taskId: number | null;
  getAccessMembers: any;
  limitedAcessPopup: (val: boolean) => void;
  limitedAccessList:any
}

const AddLimitedAccess = (props: AddPopup) => {
  const { limitedAcessPopup, taskId, getAccessMembers,limitedAccessList} = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "all",
    resolver: yupResolver<yup.AnyObjectSchema>(limitedAccess),
  });

  const { mutate: saveLimitedAccess, isLoading } = useMutation({
    mutationFn: commentService.saveLimitedAccess,
    onSuccess(data) {
      const message = data.data.message;
      SyToast(t(message), "top-right", "short", "success");

      handleCloseClick();
      getAccessMembers();
    },
    onError(error: any) {
      const errorMessage = error.response.data.message;
      SyToast(t(errorMessage), "top-right", "short", "error");
      handleCloseClick();
    },
  });

  const handleSaveClick = (saveData: any) => {
    if (saveData) {
      const id = taskId;
      const payload = {
        limited_role: saveData.user,
        limited_role_type: "4",
        our_pro_id: id,
      };

      saveLimitedAccess({ id, payload });
    } else handleCloseClick();
  };

  const handleCloseClick = () => {
    limitedAcessPopup(false);
  };

  return (
    <div className={` `}>
      <div className={`my-2 me-5`}>
        <SyAsynSelect
          name={"user"}
          placeholder={t("pro_employee")}
          control={control}
          optionValue={limitedAccessList}
          multiple={true}
          defaultVal={false}
          errorMessage={errors.user?.message}
          SERVICE_API_FN={commentService.getEmployeeTags}
          onSuccessAsyncSearch={getAsyncWildData}
          payload={asyncWildDatapayload.user}
        />
      </div>
      <div
        className={`d-flex justify-content-end  pt-3  ${styleSheet.buttonSpace}`}
      >
        <Sy_CustomFooter
          handleSubmit={handleSubmit}
          handleformSubmit={handleSaveClick}
          loading={isLoading}
          label={t("save")}
        />
      </div>
    </div>
  );
};

export default AddLimitedAccess;
