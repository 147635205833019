import StyleSheet from "./style.module.scss";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
interface ButtonData {
  navBar: boolean;
  id: string;
  label: string;
  target: string;
  selected: boolean;
  disabled: boolean;
  index: number;
  onClick: () => void;
  onclick: (id:any) => void;
  activeSection:any
}

const ButtonTab: React.FC<ButtonData> = ({
  navBar,
  id,
  label,
  target,
  selected,
  disabled,
  onClick,
  onclick,
  activeSection
}) => {
  const { t } = useTranslation();

  return(
   navBar ? (
    <Button
      disableRipple
      className={`nav-link p-2 ${activeSection === id ? `active ` : ""} ${
        disabled ? `disabled ` : ``
      }`}
      id={id}
      size="small" 
      href={target}
      onClick={()=>onclick(id)}
    >
      {t(label)}
    </Button>
  ) : (
    <button
      className={`nav-link  ${selected ? `  active ` : ""} ${
        disabled ? `disabled ${StyleSheet.disable}` : ``
      }`}
      id={id}
      data-bs-toggle="pill"
      data-bs-target={target}
      type="button"
      role="tab"
      aria-controls={target}
      aria-selected={selected}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {label}
    </button>
  )
  )
};

export default ButtonTab;
