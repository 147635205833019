import Stylesheet from "./style.module.scss";
import SyNavPath from "../../../../../../shared/components/sy-nav-path";
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";
import SyChip from "../../../../../../shared/components/sy-chip";
import { useSelector, useDispatch } from "react-redux";
import { getJwtToken } from "../../../../feature/common-slice";
import {
  getdocLoading,
  setdocList,
  getdocList,
  updateMyDetailOnToggle,
  getNavPath,
  onChangeNav,
  getNavDetails,
} from "../../../../feature/simpler-slice";
import {
  SyLoadingIcon,
  SyPagination,
  SyTooltip,
} from "../../../../../../shared/components";
import {
  SyIconReference,
  syOpenFile,
} from "../../../../../../shared/utils/sy-file-utils";
import { pageSizeOptions } from "../../../../../../shared/constants";
import { useMutation } from "@tanstack/react-query";
import getservice from "../../service/comment-service";
import { docHeaders } from "./doc-list-config";
import { useState } from "react";
import Popup from "../../../../../../shared/components/sy-model";
import SyTaskdetailsPopup from "../../pop-up/sy-taskdetails-popup";
import { useTranslation } from "react-i18next";
import SyAccordion from "../../../../../../shared/components/sy-accordion";

type Props = {
  data?: any;
  onClickNavBtn?: any;
  tableupdated?: any;
  onClickDocPath?:any;
};

const FileList = ({ onClickNavBtn, tableupdated ,onClickDocPath}: Props) => {
  const dispatch = useDispatch();
  const [openTaskDetalPopup, setOpenTaskDetalPopup] = useState(false);
  const [taskIdData, setTaskIdData] = useState<any | null>(null);
  const [dirtyValues, setDirtyValues] = useState<boolean>(false);
  const [subTitleName, setSubTitleName] = useState("");
  const { t } = useTranslation();

  const jwtToken = useSelector(getJwtToken);

  const docListItems = useSelector(getdocList);

  const doclistLoading = useSelector(getdocLoading);

  const my_tasks = useSelector(updateMyDetailOnToggle);

  const navDetails = useSelector(getNavDetails);

  const navpath = useSelector(getNavPath);

  const { mutate: getDocListData } = useMutation({
    mutationFn: getservice.getDocList,
    onSuccess(data) {
      dispatch(setdocList(data?.data ? data?.data : []));
    },
  });

  const onClickNavLink = (val: any) => {
    if (val?.id) {
      dispatch(onChangeNav(val));
      onClickNavBtn(val);
    }
  };

  const handlePaginateClick = ({
    pageIndex,
    rowPerPage,
  }: {
    pageIndex: any;
    rowPerPage: any;
  }) => {
    const docPayload = {
      paging: {
        pageIndex: pageIndex,
        pageSize: rowPerPage,
        pageSizeOptions: pageSizeOptions,
      },

      mine: my_tasks,
    };
    getDocListData({
      data: navDetails.path[navDetails.path.length - 1],
      docPayload,
    });
  };

  const handleTaskDetailsPopup = (data: any) => {
    setSubTitleName(data.task_name);
    setTaskIdData({ projectId: data.md_pro_id, taskId: data.md_task_id });
    setOpenTaskDetalPopup(true);
  };

  const handlePopupClose = () => {
    if (dirtyValues) {
      onClickNavBtn(
        navDetails.path[navDetails.path.length - 1],
        { from: 'popup', mine: my_tasks }
      );
    }
  };

  const updateDash = (data: boolean) => {
    return setDirtyValues(data);
  };

  const onClickPath = (data: any) => {
    onClickDocPath(data);
  };

  return (
    <div className="p-2 mb-2">
      <SyAccordion title={t("doc_list")} id="docList">
        <div className={`${Stylesheet.file_container} rounded ps-3 pe-3 pb-3`}>
          <div>
            {/* <div className="d-flex justify-content-between mb-2"> */}
            {/* <div className="ms-2 mt-1">
              <h5 className="mb-0 main-font semi-b">{t("doc_list")}</h5>
            </div> */}
            {/* <div className="mt-3">
            <SyIcons name={"search"} className={"me-2 pointer"} />
            <SyIcons name={"filter_list"} className={"me-2 pointer"} />
            </div> */}
            {/* </div> */}
            <div className="sy_horizontal_scroll">
              <table className="table ">
                <thead>
                  <tr>
                    {docHeaders.map((header, index) => (
                      <th key={index} scope="col" className="sub-font fw-bold">
                        {t(header.label)}
                      </th>
                    ))}
                  </tr>
                </thead>

                {doclistLoading === true ? (
                  <tbody>
                    <tr>
                      <td colSpan={8}>
                        <div className="d-flex justify-content-center p-4">
                          <SyLoadingIcon size="sm" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : null}

                <tbody className="table-group-divider">
                  {docListItems &&
                    docListItems?.data?.length > 0 &&
                    docListItems?.data?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td
                          className=""
                          onClick={() =>
                            syOpenFile({
                              fileName: item.md_name,
                              path: "mopus_docs",
                              authToken: jwtToken,
                            })
                          }
                        >
                          <span className="pointer">
                            {SyIconReference(item.md_name)}
                          </span>
                        </td>
                        <td className={`${Stylesheet.docname} sy-primary`}>
                          <span
                            className="d-flex pointer sub-font"
                            onClick={() =>
                              syOpenFile({
                                fileName: item.md_name,
                                path: "mopus_docs",
                                authToken: jwtToken,
                              })
                            }
                          >
                            <span className="ellipsis">
                              {item.md_name.substring(
                                0,
                                item.md_name.lastIndexOf(".")
                              )}
                            </span>
                            {"."}
                            {item.md_name.split(".").pop()}
                          </span>
                        </td>
                        <td
                          className={`${Stylesheet.taskname} ellipsis sy-primary`}
                        >
                          <SyTooltip title={item.task_name}>
                            <span
                              className="pointer sub-font"
                              onClick={() => handleTaskDetailsPopup(item)}
                            >
                              {item.task_name}
                            </span>
                          </SyTooltip>
                        </td>
                        <td>
                          <span
                            className={`${Stylesheet.tagsList} d-flex flex-wrap`}
                          >
                            {item.tags.length > 0
                              ? item.tags.map((tag: any, index: number) => (
                                  <span key={index} className=" p-1">
                                    <SyChip
                                      label={tag.pt_name}
                                      color={tag.pt_color}
                                      key={index}
                                      size="sm"
                                    />
                                  </span>
                                ))
                              : null}
                          </span>
                        </td>

                        <td className="text-primary ellipsis_scroll">
                          <SyNavPath
                            paths={item?.path}
                            onClick={onClickPath}
                            loading={false}
                            size="small-font"
                            disable={false}
                          />
                        </td>
                        <td>
                          <span className="opacity-50 small-font">
                            {getDateLocaleString(item.created_at, "date")}
                          </span>
                        </td>
                        <td>
                          <span className="opacity-50 small-font">
                            {getDateLocaleString(item.created_at, "time")}
                          </span>
                        </td>
                        {/* <td>{item.size}</td> */}
                      </tr>
                    ))}
                </tbody>
                {openTaskDetalPopup && (
                  <Popup
                    open={openTaskDetalPopup}
                    close={setOpenTaskDetalPopup}
                    onClose={handlePopupClose}
                    scroll="paper"
                    showHeader={true}
                    title={subTitleName}
                    showCloseIcon={true}
                    model_type={"alert"}
                    maxWidth={"lg"}
                    children={
                      <SyTaskdetailsPopup
                        oncloseclick={handlePopupClose}
                        pro_id={taskIdData.projectId}
                        task_id={taskIdData?.taskId}
                        updateDashboard={updateDash}
                      />
                    }
                  />
                )}
                {(docListItems?.data?.length === 0 && !doclistLoading) ||
                (docListItems?.length === 0 && !doclistLoading) ? (
                  <tbody>
                    <tr>
                      <td colSpan={8}>
                        <p className="d-flex justify-content-center opacity-50 main-font">
                          {t("no_data_found")}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ) : null}
              </table>
            </div>
            {docListItems?.total > 0 && (
              <div className="d-flex justify-content-end">
                <p className="opacity-50 pe-3">
                  {`${t("total")}: `}
                  {docListItems?.total > 0 && docListItems
                    ? docListItems?.total
                    : 0}
                </p>
              </div>
            )}
            {docListItems?.total > 100 ? (
              <div
                className={`${Stylesheet.pagination} d-flex justify-content-center border rounded`}
              >
                <SyPagination
                  total={docListItems?.total ? docListItems?.total : 0}
                  handlePaginateClick={handlePaginateClick}
                />
              </div>
            ) : null}
          </div>
        </div>
      </SyAccordion>
    </div>
  );
};

export default FileList;
