export const payload = {
  filter: {
    task_status: null,
    assignees: [],
    tags: [],
    created_by: [],
    labels: [],
    label_ids: [],
  },
  paging: {
    pageIndex: 0,
    pageSize: 100,
    globalFilter: null,
    pageSizeOptions: ["100", "150", "200"],
  },
  showArchived: false,
};

export const taskHeaders = [
  { key: "task_id", label: "id" },
  { key: "task_name", label: "task_name" },
  { key: "task_start_date", label: "task_start_date" },
  { key: "task_status", label: "task_status" },
  { key: "task_end_date", label: "task_end_date" },
  { key: "task_priority", label: "priority" },
  { key: "created_at", label: "created_at" },
  { key: "task_weight", label: "task_weight" },
];

export const subtaskHeaders = [
  { key: "task_id", label: "id" },
  { key: "task_name", label: "task_name" },
  { key: "task_start_date", label: "task_start_date" },
  { key: "task_status", label: "task_status" },
  { key: "task_end_date", label: "task_end_date" },
  { key: "task_priority", label: "priority" },
  { key: "task_weight", label: "task_weight" },
];
