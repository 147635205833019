import { createSlice } from "@reduxjs/toolkit";
import { syWindowReload } from "../../../../shared/utils";

interface RootState {
  path: any[];
  master: any[];
  isMopusAdmin:boolean;
  navListLoading: boolean;
}

const initialState: RootState = {
  path: [],
  master: [],
  isMopusAdmin:false,
  navListLoading: false,
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    updateInitialNav: (state, { payload }) => {
      const path: any[] = [];
      state.isMopusAdmin=payload.mopus_admin
      const index = state.master.findIndex((val) => {
        return val.masterId == payload.masterId;
      });
      if (index >= 0) {
        state.master[index] = {
          ...payload,
          selected: state.master[index].selected,
        };
      } else {
        state.master.push(payload);
      }
      if (!payload?.selected || payload.masterId=='3') {
        state.master.forEach((val: any) => {
          if (val.selected) path.push(val.selected)
        })
        state.path = path;
      }

    },
    onClickNavBtn: (state, { payload }) => {
      const path: any[] = [];
      state.master = state.master.map((val) => {
        if (val.masterId === +payload.masterId) {
          path.push(payload);
          return { ...val, selected: payload };
        } else {
          path.push(val.selected);
          return val;
        }
      });
      state.path = path;
    },
    onChangeNav: (state, { payload }) => {
      const path: any = [];
      state.master = state.master
        .filter((val, index) => {
          if (
            (!payload?.index && !index) ||
            (payload?.index >= 0 &&
              payload?.index <= 2 &&
              index <= payload?.index + 1)
          ) {
            return true;
          }
          return false;
        })
        .map((val, index, arr) => {
          if (arr.length - 1 == index && payload.masterId != 3) {
            return { ...val, selected: "" };
          } else {
            path.push(val.selected);
            return val;
          }
        });

      state.path = path;
    },
    updateMasterItems: (state, { payload }) => {
      if (payload.mode === "add") {
        const IndexOfItem = state?.master[
          state.master.length - 1
        ].items.findIndex((val: any) => val.id === payload.data.id);
        if (payload.data.masterId === 3 && IndexOfItem !== -1) {
          state.master[state.master.length - 1].items[IndexOfItem] =
            payload.data;
        } else {
          state.master[state.master.length - 1].items.push(payload.data);
        }
        state.path[state.master.length - 1] = payload.data;
        state.master[state.master.length - 1].selected = payload.data;
      } else {
        if (
          (payload.data.pro_prm_id != payload.newPfoId &&
            payload.from == "program") ||
          (payload.data.prm_pfo_id != payload.newPfoId &&
            payload.from == "project")
        ) {
          syWindowReload();
        } else {
          const masterIndex = state.master.findIndex(
            (val) => val.masterId == +payload.data.masterId
          );
          const itemIndex = state.master[masterIndex].items?.findIndex(
            (val: any) => val.id == payload.data.id
          );
          state.master[masterIndex].items[itemIndex] = payload?.data;
          state.master[masterIndex].selected = payload.data;
          state.path[state.path.length - 1] = payload.data;
        }
      }
    },
    delMaterItems: (state, { payload }) => {
      const path: any = [];
      const masterIndex = state.master.findIndex(
        (val) => val.masterId == payload.masterId
      );
      const itemIndex = state.master[masterIndex].items.findIndex(
        (val: any) => val.id == payload.id
      );
      state.master.splice(masterIndex + 1);
      state.master[masterIndex].items.splice(itemIndex, 1);
      state.master[masterIndex].selected = "";
      state.master.forEach((val: any) => {
        if (val.selected) {
          path.push(val.selected);
        }
      });
      state.path = path;
    },
    setNavListLoading: (state, { payload }) => {
      state.navListLoading = payload;
    },
    updateHierarchyData:(state, { payload })=>{
      state.master = payload.master;
      state.path=payload.path;
      state.isMopusAdmin=payload.isMopusAdmin;
    }
  },
});

export const {
  updateInitialNav,
  onClickNavBtn,
  onChangeNav,
  updateMasterItems,
  setNavListLoading,
  delMaterItems,
  updateHierarchyData
} = navSlice.actions;

export const getNavPath = (state: any) => state.navReducer.path;
export const getNavListLoading = (state: any) =>
  state.navReducer.navListLoading;
export const getNavDetails = (state: any) => state.navReducer;

export default navSlice.reducer;
