import { useEffect, useState } from "react";
import styleSheet from "./style.module.scss";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../service/comment-service";
import AddAssignee from "./add-employee";
import Popup from "../../../../../../shared/components/sy-model";
import Chip from "../../../../../../shared/components/sy-chip";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import SyLoadingIcon from "../../../../../../shared/components/sy-loading-icon";
import { useTranslation } from "react-i18next";
import { roles } from "./config";
import { assigneeType } from "./helper";
interface Props {
  taskId: number | null;
  tableData: Array<object>;
  assigneeTableLoading: boolean;
  updatetable: (val: boolean) => void;
  switchTab: (id: string) => void;
}

const AssigneeManage = (props: Props) => {
  const { taskId, tableData, updatetable, assigneeTableLoading } = props;

  const { t } = useTranslation();
  const [openPOPUP, setOpenPOPUP] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deletableid, setdeletableid] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState<any>([]);

  useEffect(() => {
    updatetable(true);
  }, []);

  //deleteTaskType
  const { mutate: deleteAssigneeMutate, isLoading } = useMutation({
    mutationFn: commentService.deleteTaskAssignee,
    onSuccess(data,variables) {
      const message = data.data.message;
      SyToast(t(message), "bottom-right", "short", "success");
      updatetable(true);
      const indexval = [...submitLoading];
      indexval[variables.index] = false;      
      setSubmitLoading(indexval);
    },
    onError(error:any) {
      const errorMessage = error.message;
      SyToast(t(errorMessage), "bottom-right", "short", "error");
    },
  });

  const { mutate: deleteTypeMutate } = useMutation({
    mutationFn: commentService.deleteTaskType,
    onSuccess() {
      updatetable(true);
    },
  });

  const handleChipAction = (data: any) => {
    const taskid = taskId;
    const typeid = data.type;
    const userid = data.data.user.id;
    deleteTypeMutate({ taskid, typeid, userid });
    return void 0;
  };

  const emptyEmployeeList = (
    <tr>
      <td className="col-5"></td>
      <td className="col-6 ps-5 text-black-50">{t("no_data_found")}</td>
      <td className="col-1"></td>
    </tr>
  );

  const loadingEmployeeList = (
    <tr>
      <td className="col-5"></td>
      <td className="col-6 ps-5 text-black-50">{t("loading")}...</td>
      <td className="col-1"></td>
    </tr>
  );
  
  const onConfirming = () => {
    deleteAssigneeMutate(deletableid)
    const submitLoadingVal = [...submitLoading];
    submitLoadingVal[deletableid.index] = true;
    setSubmitLoading(submitLoadingVal);
  };

  const tablecontent = (data: any) => {
    const disable_delete=data.length === 1;
    return data.map((data: any, index: number) => {
      const deleteClick = () => {
        setConfirmPopup(true);
        setdeletableid({id : data.user.our_id,index});
      };
      const typeSet = Array.from(new Set(data.type));
      const disable_chip_delete = typeSet.length === 1;      
      return (
        <tr key={index}>
          <td className="col-5">{data?.user?.name}</td>
          <td className="col-6">
            {typeSet.map((type: any, index: any) => {
              const handleOnClick = () => handleChipAction({ data, type });
              return assigneeType(type) ? (
                <span key={index} className="m-1">
                  <Chip
                    label={assigneeType(type)}
                    color={"#ccc"}
                    actionIcon={!disable_chip_delete ? !disable_chip_delete : !disable_delete  }
                    onClick={handleOnClick}
                  />
                </span>
              ) : (
                void null
              );
            })}
          </td>
          <td className="col-1">
            <div className={`d-flex justify-content-around select-none  `}>
              {!disable_delete ? (
                submitLoading[index] && isLoading ? (
                  <SyLoadingIcon />
                ) : (
                  <span
                    className={`material-icons pointer `}
                    id={data.user.our_id}
                    onClick={deleteClick}
                  >
                    delete_outline
                  </span>
                )
              ) : (
                void 0
              )}
            </div>
          </td>
        </tr>
      );
    });
  };
  const updateTable = () => {
    updatetable(true);
  };

  const handlePopupButtonClick = () => {
    setOpenPOPUP(true);
  };

  const handleOpenPopup = (val: boolean) => {
    setOpenPOPUP(val);
  };

  return (
    <div className={`${styleSheet.innertab_style}  rounded p-3`}>
      <SyconfirmPopup
        open={confirmPopup}
        close={setConfirmPopup}
        onConfirmClick={onConfirming}
      />
      <div className="d-flex flex-wrap justify-content-between">
        <p className={`${styleSheet.title}`}>{t("employee_overview")}</p>
      </div>
      <div
        className={`d-flex justify-content-between ${styleSheet.element_padding}`}
      >
        <button
          className={`${styleSheet.buttonPrimary} btn`}
          type="button"
          onClick={handlePopupButtonClick}
        >
          {t("add")}
        </button>
        {openPOPUP && (
          <Popup
            model_type={"responsive"}
            showHeader={openPOPUP}
            open={openPOPUP}
            close={setOpenPOPUP}
            children={
              openPOPUP ? (
                <AddAssignee
                  roleoptions={roles}
                  renderUpdatedTable={updateTable}
                  openPOPUP={handleOpenPopup}
                  taskId={taskId}
                  tableData={tableData}
                />
              ) : null
            }
            title={t("add")}
          />
        )}
      </div>
      <div className={` ${styleSheet.elementm_padding} sy_horizontal_scroll`}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="col-5">
                {t("name")}
              </th>
              <th scope="col" className="col-5">
                {t("role")}
              </th>
              <th scope="col" className="col-2">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {assigneeTableLoading
              ? loadingEmployeeList
              : tableData.length !== 0
              ? tablecontent(tableData)
              : emptyEmployeeList}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssigneeManage;
