import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../forms/dropdown";
import { Controller } from "react-hook-form";
import commentService from "../../../projects/simpler/modules/simpler-module/service/comment-service";
import { useMutation } from "@tanstack/react-query";
import { type_id_icon } from "../../constants/constants";
import SyIcons from "../sy-icons";
import { asyncWildDatapayload } from "../../utils";

type Props = {
  control: any;
  name: any;
  popupIcon?: boolean;
  placeholder: string;
  optionValue: any;
  SERVICE_API_FN: any;
  multiple: any;
  defaultVal: any;
  taskProjectId?: any;
  payload: any;
  errorMessage?: any;
  limitItems?: any;
  style?: any;
  onSuccessAsyncSearch: any;
  disabled?:boolean
};

const SyAsynSelect = (props: Props) => {
  const {
    control,
    name,
    disabled,
    popupIcon = true,
    placeholder,
    optionValue,
    SERVICE_API_FN,
    multiple,
    defaultVal,
    taskProjectId = null,
    payload,
    errorMessage = undefined,
    limitItems = undefined,
    onSuccessAsyncSearch,
    style = { width: "100%", height: "100%" },
  } = props;
  const [asyncList, setAsyncList] = useState([]);
  const { mutate, isLoading } = useMutation({
    mutationFn: SERVICE_API_FN,
    onSuccess(data: any, variables, context: any) {
      setAsyncList(onSuccessAsyncSearch(data, optionValue));
    },
  });

  const { mutate: taskMembersMutate, isLoading: loading } = useMutation({
    mutationFn: commentService.getTaskMembers,
    onSuccess(data: any, _context: any) {
      if (optionValue) {
        const list = data.data
          ? data.data.filter((ele: any) => {
              return !optionValue.some((f: any) => {
                return f.id === ele.id && f.type_id === ele.type_id;
              });
            })
          : [];

        setAsyncList(list);
      } else {
        setAsyncList(data.data);
      }
    },
  });

  // const {mutate:refineDetail} = useMutation({
  //   mutationFn:commentService.getRefineDetails,
  //   onSuccess(data){
  //     console.log(data);
  //   }
  // })

  // const getfilterpayload = asyncWildDatapayload[multiUser];

  const handleInput = (inputvalue: any) => {
    const inputValCheck = (name: any) => {
      if (name === inputvalue) {
        return true;
      }
    };

    !asyncList.map((data: any) => data.name).some(inputValCheck)
      ? taskProjectId
        ? taskMembersMutate({
            proId: taskProjectId,
            inputvalue,
            payload,
          })
        : (mutate as any)({
            inputvalue,
            payload,
          })
      : setAsyncList([]);
  };

  const handleFocus = () => {
    taskProjectId
      ? taskMembersMutate({
          proId: taskProjectId,
          inputvalue: "",
          payload,
        })
      : (mutate as any)({
          inputvalue: "",
          payload,
        });
  };
  const handleName = (data: any) => data?.name;

  const getIcon = (data: any) => {
    const icon = type_id_icon[data.type_id];

    return <SyIcons name={icon} iconStyle="" className={"me-3"} />;
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Dropdown
              onFocus={handleFocus}
              options={asyncList}
              placeholder={placeholder}
              value={defaultVal ? value : undefined}
              controlledVal={defaultVal ? undefined : value}
              enableSearchBox={false}
              loading={isLoading || loading}
              handleFilter={handleInput}
              handleChange={onChange}
              multiple={multiple ? true : false}
              getoptlabel={handleName}
              getIcon={getIcon}
              limitItems={limitItems}
              clearIcon={true}
              popupIcon={popupIcon}
              style={style}
              errorMessage={errorMessage}
              disabled={disabled}
            />
          );
        }}
      />
    </div>
  );
};
export default SyAsynSelect;
