import { useTranslation } from "react-i18next";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import styleSheet from "./style.module.scss";
import { SyIcons, SyLoadingIcon } from "../../../../../../shared/components";

type Props = {
  pageType: "saveNextTab" | "navTab" | "lastTab" | "saveTab";
  saveClick?: () => void;
  saveNextClick?: () => void;
  closeClick?: () => void;
  nextClick?: () => void;
  backClick?: () => void;
  saveloading?: boolean;
  savenextloading?: boolean;
  disabled?: boolean;
};

export const loadingIconButton = (
  <div
    className={`${styleSheet.loadingIcon} d-flex justify-content-center align-items-center`}
  >
    <SyLoadingIcon color="#fff" />
  </div>
);

const FooterButtons = (props: Props) => {
  const {
    pageType,
    saveClick,
    saveNextClick,
    closeClick,
    nextClick,
    backClick,
    saveloading,
    savenextloading,
    disabled = false,
  } = props;
  const { t } = useTranslation();


  const closeIcon = <SyIcons name="keyboard_backspace" />;
  const saveIcon = <SyIcons name="save" />;
  const saveNextIcon = (
    <>
      {savenextloading ? loadingIconButton : saveIcon}
      <SyIcons name="keyboard_double_arrow_right" />
    </>
  );
  
  return (
    <div>
      {pageType === "saveNextTab" ? (
        <div className={`d-flex justify-content-end mt-3 `}>
          <Sybutton
            type={"button"}
            className={`Secondarybutton me-2 d-none d-sm-block`}
            size={"sm"}
            label={t("cancel")}
            onBtnClick={closeClick}
          />
          <Sybutton
            type={"button"}
            className={"iconButton me-2 d-flex align-items-center d-block d-sm-none"}
            size={"sm"}
            onBtnClick={closeClick}
            label={closeIcon}
          />
          <Sybutton
            type={"button"}
            className={`primarybutton me-2 d-none d-sm-block`}
            size={"sm"}
            value={'save'}
            label={t("save")}
            Loadings={saveloading}
            onBtnClick={saveClick}
            disabled={disabled || savenextloading || saveloading? true : false}
          />
          <Sybutton
            type={"button"}
            className={"iconButton me-2 d-flex align-items-center d-block d-sm-none"}
            size={"sm"}
            value={'save'}
            onBtnClick={saveClick}
            label={saveloading ? loadingIconButton : saveIcon}
            disabled={disabled || savenextloading || saveloading? true : false}
          />
          <Sybutton
            type={"button"}
            className={`primarybutton  d-none d-sm-block`}
            size={"sm"}
            value={'save_and_next'}
            label={t("save_and_next")}
            Loadings={savenextloading}
            onBtnClick={saveNextClick}
            disabled={disabled || savenextloading || saveloading? true : false}
          />
           <Sybutton
            type={"button"}
            className={"iconButton me-2 d-flex align-items-center d-block d-sm-none"}
            size={"sm"}
            value={'save_and_next'}
            onBtnClick={saveNextClick}
            label={saveNextIcon}
            disabled={disabled || savenextloading || saveloading? true : false}
          />
        </div>
      ) : (
        void null
      )}
      {pageType === "saveTab" ? (
        <div className={`d-flex justify-content-end mt-3 `}>
          <Sybutton
            type={"button"}
            className={`Secondarybutton me-2 d-none d-sm-block`}
            size={"sm"}
            label={t("cancel")}
            onBtnClick={closeClick}
          />
          <Sybutton
            type={"button"}
            className={"iconButton me-2 d-flex align-items-center d-block d-sm-none"}
            size={"sm"}
            onBtnClick={closeClick}
            label={closeIcon}
          />
          <Sybutton
            type={"button"}
            className={`primarybutton me-2 d-none d-sm-block`}
            size={"sm"}
            value={'save'}
            label={t("save")}
            Loadings={saveloading}
            onBtnClick={saveClick}
            disabled={saveloading ? true : false}
          />
          <Sybutton
            type={"button"}
            className={"iconButton me-2 d-flex align-items-center d-block d-sm-none"}
            size={"sm"}
            value={'save'}
            onBtnClick={saveClick}
            label={saveloading ? loadingIconButton : saveIcon}
            disabled={saveloading ? true : false}
          />
        </div>
      ) : (
        void null
      )}
      {pageType === "navTab" ? (
        <div
          className={`d-flex justify-content-end ${styleSheet.button_space}`}
        >
          <button
            className={`${styleSheet.button_primary} btn`}
            type="button"
            onClick={backClick}
          >
            back
          </button>
          <button
            className={`${styleSheet.button_primary} btn`}
            type="button"
            onClick={nextClick}
          >
            next
          </button>
          <button
            className={`${styleSheet.button} btn`}
            type="button"
            onClick={closeClick}
          >
            close
          </button>
        </div>
      ) : (
        void null
      )}
      {pageType === "lastTab" ? (
        <div
          className={`d-flex justify-content-end ${styleSheet.button_space}`}
        >
          <button
            className={`${styleSheet.button_primary} btn`}
            type="button"
            onClick={backClick}
          >
            back
          </button>
          <button
            className={`${styleSheet.button} btn`}
            type="button"
            onClick={closeClick}
          >
            close
          </button>
        </div>
      ) : (
        void null
      )}
    </div>
  );
};

export default FooterButtons;
