import styleSheet from "./style.module.scss";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import commentService from "../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AssigneeSchema } from "../../schema-validation/schema-validation";
import * as yup from "yup";
import Sy_CustomFooter from "../../../../../../shared/components/sy-model/sy-customfooter";
import { useTranslation } from "react-i18next";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import Errormessage from "../../../../../../shared/components/forms/errormessage/errormessage";
import SyAsynSelect from "../../../../../../shared/components/sy-asyn-select";
import {
  asyncWildDatapayload,
  getAsyncWildData,
} from "../../../../../../shared/utils";
interface AddPopup {
  roleoptions: Array<object>;
  taskId: number | null;
  renderUpdatedTable: () => void;
  openPOPUP: (val: boolean) => void;
  tableData: any;
}

type formValues = {
  user: Array<any>;
  type: Array<any>;
};

const formDefaultValues = {
  user: [],
  type: [],
};

const AddAssignee = (props: AddPopup) => {
  const { roleoptions, taskId, renderUpdatedTable, openPOPUP, tableData } =
    props;
  const { t } = useTranslation();

  const { mutate: saveAssigneeMutate, isLoading } = useMutation({
    mutationFn: commentService.saveTaskAssignee,
    onSuccess(data) {
      const message = data.data.message;
      SyToast(t(message), "top-right", "short", "success");
      renderUpdatedTable();
      openPOPUP(false);
      document.getElementById("closeButton")?.click();
      handleCloseClick();
    },
    onError(error: any) {
      const errorMessage = error.response.data.message;
      SyToast(t(errorMessage), "top-right", "short", "error");
      handleCloseClick();
    },
  });

  const getRoleLabel = (options: any) => {
    return options.role;
  };

  const handleCloseClick = () => {
    openPOPUP(false);
    return renderUpdatedTable();
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<formValues>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: formDefaultValues,
    resolver: yupResolver<yup.AnyObjectSchema>(AssigneeSchema),
  });

  const handleSaveClick = (saveData: any) => {
    if (saveData) {
      const typeArray = saveData.type.map((data: any) => data.id);
      const payload = {
        user: saveData.user,
        type: typeArray,
      };
      const id = taskId;
      saveAssigneeMutate({ id, payload });
    } else handleCloseClick();
  };

  return (
    <div className={` `}>
      <div className={`my-2 me-5`}>
        <SyAsynSelect
          name={"user"}
          placeholder={t("pro_employee")}
          control={control}
          optionValue={null}
          multiple={true}
          defaultVal={false}
          errorMessage={errors.user?.message}
          SERVICE_API_FN={commentService.getEmployeeTags}
          onSuccessAsyncSearch={getAsyncWildData}
          payload={asyncWildDatapayload.user}
        />
      </div>
      <div className={`my-3 pb-2 me-5`}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => {
            return (
              <Dropdown
                {...field.onBlur}
                options={roleoptions}
                placeholder={t("assign_role")}
                enableSearchBox={false}
                loading={false}
                multiple={true}
                handleChange={field.onChange}
                getoptlabel={getRoleLabel}
                errorMessage={errors.type?.message}
              />
            );
          }}
        />
      </div>
      <div className={`d-flex justify-content-end ${styleSheet.buttonSpace}`}>
        <Sy_CustomFooter
          handleSubmit={handleSubmit}
          handleformSubmit={handleSaveClick}
          loading={isLoading}
          label={t("save")}
        />
      </div>
    </div>
  );
};

export default AddAssignee;
