import { useEffect, useImperativeHandle, useState, forwardRef } from "react";
import Stylesheet from "./style.module.scss";
import { useMutation } from "@tanstack/react-query";
import getCommentData from "../../service/comment-service";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Popup from "../../../../../../shared/components/sy-model";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import { pageSizeOptions } from "../../../../../../shared/constants";
import Sy_Search from "../../../../../../shared/components/sy-search/sy-search";
import Sy_Pagination from "../../../../../../shared/components/sy-pagination";
import TaskHeader from "./task-header";
import SyIcons from "../../../../../../shared/components/sy-icons";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import commentService from "../../service/comment-service";
import SyLoadingIcon from "../../../../../../shared/components/sy-loading-icon";
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";
import {
  SyChip,
  SyPersist,
  SyTooltip,
} from "../../../../../../shared/components";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaskListData,
  getTaskListLoading,
  getNavDetails,
  setTaskList,
  updateMyDetailOnToggle,
  getViewControlDetail,
  getArchivedValue,
  setArchiveClick,
  getNavPath,
} from "../../../../feature/simpler-slice";
import SyTaskdetailsPopup from "../../pop-up/sy-taskdetails-popup";
import { taskHeaders, subtaskHeaders } from "./task-list-config";
import { useTranslation } from "react-i18next";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { chipChange } from "./helper";
import {
  sySetPersistData,
  syGetPersistData,
  syDeletePersistData,
  getData,
} from "../../../../../../shared/utils";
import { AnyAction } from "@reduxjs/toolkit";
import {
  getDashBoardParamPayload,
  setQueryParams,
} from "../../../../../../shared/utils/helper";
import { InputForm } from "../../../../../../shared/components/forms";
import FilterPopup from "./filter-popup";
import { getParamValue } from "../../screens/dashboard-screen/helper";
import {
  getsetSelectedTask,
  setSelectedTask,
  updateSelectedTask,
} from "../../../../feature/simpler-slice/task-list-slice";

type Props = {
  task_id?: any;
  pro_id?: any;
  mode?: "subTaskList" | "globalTaskList" | "taskList";
  onClick?: (taskId: any) => void;
  tableupdated?: () => void;
  onClickNavBtn?: any;
  updateGraph?: () => void;
  childern?: AnyAction;
  paramVal?: any;
  disable?: any;
};

const TaskListScreen = forwardRef((props: Props, ref) => {
  const { t } = useTranslation();
  const {
    mode,
    pro_id,
    disable,
    task_id,
    onClickNavBtn,
    tableupdated,
    updateGraph,
    childern,
    paramVal,
  } = props;

  const ids = {
    itemId: pro_id,
    taskId: task_id,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openconfirmPopup, setopenconfirmPopup] = useState(false);
  const [statusDoubleClick, setStatusDoubleClick] = useState<
    "dropDownView" | "chipView"
  >("chipView");
  const [dropdownindex, setdropdownindex] = useState<number>();
  const [statusValue, setStatusValue] = useState<any>();
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [PageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [taskIdData, setTaskIdData] = useState<any | null>(null);
  const [subtasklist, setsubtasklist] = useState<any>({});
  const [taskpopupOpen, settaskPopupOpen] = useState(false);
  const [dirtyValues, setDirtyValues] = useState<boolean>(false);
  const [subTitleName, setSubTitleName] = useState("");
  const [filterPayload, setFilterPayload] = useState<any>();
  const [deleteConfirmData, setDeleteConfirmData] = useState<any>({
    data: "",
    index: "",
  });
  const [submitLoading, setSubmitLoading] = useState<any>([]);
  const [archiveLoading, setArchiveLoading] = useState<any>([]);
  const [unArchiveLoading, setUnArchiveLoading] = useState<any>([]);
  const [taskListValue, setTaskListValue] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [sorting, setSorting] = useState<{
    active: string;
    direction: "asc" | "desc" | "";
  }>({
    active: "",
    direction: "",
  });
  const params = useParams();

  const { itemId, taskId } = mode === "subTaskList" ? ids : params;

  const GET_TASK_LIST = useSelector(getTaskListData);

  const taskList = mode === "subTaskList" ? subtasklist : GET_TASK_LIST;

  const taskLoading = useSelector(getTaskListLoading);

  const navPath = useSelector(getNavDetails);

  const getselectedId = navPath.path[navPath.path.length - 1];

  const my_tasks = useSelector(updateMyDetailOnToggle);

  const viewControlDetail = useSelector(getViewControlDetail);

  const my_task = useSelector(updateMyDetailOnToggle);

  const navDetails = useSelector(getNavDetails);

  const isArchive = useSelector(getArchivedValue);

  const getNav = useSelector(getNavPath);

  const getLastElementFromNav = getNav[getNav.length - 1];

  const selected = useSelector(getsetSelectedTask);

  const searchPersistVal = syGetPersistData(`${mode}_${task_id}_Search`) || "";

  const employeePersistVal =
    syGetPersistData(`${mode}_${task_id}_taskemployee`) || [];

  const statusPersistVal =
    syGetPersistData(`${mode}_${task_id}_status`) || null;

  const tagPersistVal = syGetPersistData(`${mode}_${task_id}_tag`) || [];

  const labelPersistVal = syGetPersistData(`${mode}_${task_id}_label`) || [];

  const payload = {
    filter: {
      task_status: null,
      assignees: [],
      tags: [],
      created_by: [],
      labels: [],
      label_ids: [],
    },
    paging: {
      pageIndex: pageIndex,
      pageSize: PageSize,
      globalFilter: null,
      pageSizeOptions: pageSizeOptions,
    },
    showArchived:
      isArchive.from === "tasklist" && mode !== "subTaskList"
        ? isArchive.data
        : false || (mode === "subTaskList" && isArchive.from !== "tasklist")
        ? isArchive.data
        : false,
    sorting: sorting,
    mine: my_tasks,
  };
  const {
    data: progressTaskData,
    mutate: getTasklist,
    isLoading: taskListLoading,
  } = useMutation({
    mutationFn: getCommentData.getGlobalTasklist,
    onSuccess(data, variables, context) {
      if (variables?.data?.masterId === getLastElementFromNav?.masterId) {
        if (variables?.data?.id === getLastElementFromNav?.id) {
          dispatch(setTaskList(data.data ? data.data : []));
        }
      }
    },
  });

  const { mutate: getSubTasklist, isLoading: subTaskLoading } = useMutation({
    mutationFn: getCommentData.getTasklist,
    onSuccess(data) {
      setsubtasklist(data.data ? data.data : []);
    },
  });

  const { mutate: getArchivedList } = useMutation({
    mutationFn: getCommentData.getArchivedList,
    onSuccess(data, variables) {
      const updateArchiveLoading = [...archiveLoading];
      updateArchiveLoading[variables.index] = false;
      setArchiveLoading(updateArchiveLoading);
      if (!isArchive?.data) {
        const archiveList = taskList?.data.filter((val: any) => {
          return val.task_id !== variables.data;
        });
        dispatch(setTaskList({ data: archiveList, total: taskList.total - 1 }));
      } else {
        taskPayload(searchValue, pageIndex, PageSize, sorting, isArchive.data);
      }
      if (!isArchive?.data) {
        const subTaskArchivedList = subtasklist?.data.filter((val: any) => {
          return val.task_id !== variables.data;
        });
        setsubtasklist({ data: subTaskArchivedList });
      } else {
        mode === "subTaskList" &&
          getSubTasklist({
            proId: pro_id,
            taskpayload: payload,
            taskId,
          });
      }
      tableupdated ? tableupdated() : void null;
    },
  });

  const { mutate: getUnarchiveList } = useMutation({
    mutationFn: getCommentData.getUnarchiveList,
    onSuccess(data, variables) {
      const updateUnArchiveLoading = [...archiveLoading];
      updateUnArchiveLoading[variables.index] = false;
      setUnArchiveLoading(updateUnArchiveLoading);
      if (!isArchive?.data) {
        const unArchiveList = taskList?.data.filter((val: any) => {
          return val.task_id !== variables.data;
        });
        dispatch(
          setTaskList({ data: unArchiveList, total: taskList.total - 1 })
        );
      } else {
        taskPayload(searchValue, pageIndex, PageSize, sorting, isArchive.data);
      }
      if (!isArchive?.data) {
        const subTaskUnArchivedList = subtasklist?.data.filter((val: any) => {
          return val.task_id !== variables.data;
        });
        setsubtasklist({ data: subTaskUnArchivedList });
      } else {
        mode === "subTaskList" &&
          getSubTasklist({
            proId: pro_id,
            taskpayload: payload,
            taskId,
          });
      }
      tableupdated ? tableupdated() : void null;
    },
  });

  const {
    data: allOptions,
    mutate: getOptionsMutate,
    isLoading: detailsLoading,
  } = useMutation({
    mutationFn: getCommentData.getProgressnullDetails,
  });

  const { mutate: statusSave } = useMutation({
    mutationFn: getCommentData.statusSave,
    onSuccess() {
      updateGraph && updateGraph();
      setStatusDoubleClick("chipView");
      mode === "subTaskList" && setDirtyValues(true);
      // getTasklist({  dont useeee
      //   data: navPath.path[navPath.path.length - 1],
      //   taskpayload: payload,
      // });
      // mode === "subTaskList"
      // ? getSubTasklist({
      //     proId: pro_id,
      //     taskpayload:payload,
      //     taskId,
      //   })
      // : void 0;
    },
  });

  useEffect(() => {
    if (selected.task && mode !== "subTaskList") {
      setTaskIdData({ projectId: selected.project, taskId: selected.task });

      const defaultTask = taskList?.data?.find(
        (option: any) => option.task_id === selected.task
      );
      setTaskListValue(defaultTask);
      settaskPopupOpen(true);
    }

    setSearchValue(searchPersistVal);
    setPageIndex(0);
    setPageSize(pageSizeOptions[0]);
  }, []);



  const getTasklistApi = (data: any = null, fromToggle = false) => {
    let taskpayload = {
      paging: {
        pageIndex: pageIndex,
        pageSize: PageSize,
        globalFilter: null,
        pageSizeOptions: pageSizeOptions,
      },
      search: searchPersistVal || "",
      filter: {
        tags:
          tagPersistVal?.map((data: any) => {
            return data.pt_value;
          }) || [],
        task_status: statusPersistVal?.ts_value || "",
        assignees: employeePersistVal,
        created_by: null,
        label_ids:
          labelPersistVal?.map((data: any) => {
            return data.l_id;
          }) || [],
        labels:
          labelPersistVal?.map((data: any) => {
            return data;
          }) || [],
      },
      mine: fromToggle ? data?.mine : my_tasks,
      showArchived:
        isArchive.from === "tasklist" && mode !== "subTaskList"
          ? isArchive.data
          : false || (mode === "subTaskList" && isArchive.from !== "tasklist")
          ? isArchive.data
          : false,
    };
    mode === "subTaskList"
      ? getSubTasklist({
          proId: pro_id,
          taskpayload,
          taskId,
        })
      : getTasklist({
          data: data?.data ? data?.data : navPath.path[navPath.path.length - 1],
          taskpayload,
        });
  };

  useEffect(() => {
    // getTasklistApi();
    filterChip();
  }, []);

  useImperativeHandle(ref, () => ({
    onChangeNav(data: any, fromToggle: any) {
      getTasklistApi(data, fromToggle);
    },
  }));

  const taskPayload = (
    searchValue: string,
    pageIndexValue: any = pageIndex,
    pageSize: any = PageSize,
    sort: any = { active: "", direction: "" },
    showArchived: boolean
  ) => {
    setSearchValue(searchValue);
    const payloadWithSorting = {
      ...payload,
      paging: {
        pageIndex: pageIndexValue,
        pageSize: pageSize,
        globalFilter: null,
        pageSizeOptions: pageSizeOptions,
      },
      filter: filterPayload
        ? filterPayload?.filter
        : {
            task_status: null,
            assignees: [],
            tags: [],
            created_by: [],
            labels: [],
            label_ids: [],
          },
      search: searchValue,
      showArchived: showArchived,
      mine: my_tasks,
      sorting: sort,
    };
    const taskParameter = taskId || "";
    mode === "subTaskList"
      ? getSubTasklist({
          proId: itemId,
          taskpayload: payloadWithSorting,
          taskId: taskParameter,
        })
      : getTasklist({
          data: navPath.path[navPath.path.length - 1],
          taskpayload: payloadWithSorting,
        });
  };

  const deleteConfirmPopup = (val: any) => {
    setDeleteConfirmData(val);
    setopenconfirmPopup(true);
  };

  const { mutate: deletTask, isLoading: deleteLoading } = useMutation({
    mutationFn: commentService.deleteTask,
    onSuccess(data, variables, context) {
      const deletedList = taskList?.data.filter((val: any) => {
        return val.task_id !== variables.data;
      });
      dispatch(setTaskList({ data: deletedList }));

      const indexval = [...submitLoading];
      indexval[variables.index] = false;
      setSubmitLoading(indexval);

      const subTaskDeletedList = subtasklist?.data.filter((val: any) => {
        return val.task_id !== variables.data;
      });
      setsubtasklist({ data: subTaskDeletedList });
      // mode === "subTaskList"
      //   && getSubTasklist({
      //       proId: itemId,
      //       taskpayload: payload,
      //       taskId: task_id,
      //     })
      //   : getTasklist({
      //       data: navPath.path[navPath.path.length - 1],
      //       taskpayload: payload,
      //     });
    },
  });

  const handleTaskDelete = () => {
    const submitLoadingVal = [...submitLoading];
    submitLoadingVal[deleteConfirmData.index] = true;
    setSubmitLoading(submitLoadingVal);
    deletTask(deleteConfirmData);
    tableupdated ? tableupdated() : void null;
  };
  const TaskList = ({ item, index }: { item: any; index: number }) => {
    const taskId = item.task_id;
    const projectId = item.task_project_id;

    const handleTaskIdClick = (item: any) => {
      setQueryParams(
        getDashBoardParamPayload(
          navDetails.path,
          my_task,
          viewControlDetail,
          isArchive
        )
      );
      setSubTitleName(item.task_name);
      setTaskIdData({ projectId, taskId });
      settaskPopupOpen(true);
      setTaskListValue(item);

      const selectedTask = {
        project: item.task_project_id,
        task: item.task_id,
      };

      dispatch(setSelectedTask(selectedTask));
    };

    const handleTaskEdit = (data: any) => {
      const editTaskId = data.task_id;
      //---parentId is not get from data ---//
      const parentId = data.task_parent_id;
      tableupdated ? tableupdated() : void null;
      const Id = data.task_project_id;

      taskId
        ? navigate(
            `/mopus/project/${Id}/task/edit/${
              parentId ? `${parentId}/` : ""
            }${editTaskId}`
          )
        : navigate(`/mopus/project/task/edit/${Id}/${editTaskId}`);
    };

    const getPriorityLabel = (val: any) => {
      const priority = allOptions?.data.priority_options.filter((data: any) => {
        return data.opt_value == val;
      });
      return priority ? priority[0].opt_name : void 0;
    };

    const getweightLabel = (val: any) => {
      const weight = allOptions?.data.weight_options.filter((data: any) => {
        return data.opt_value == val;
      });
      return weight ? weight[0].opt_name : void 0;
    };

    const statusOptions = allOptions?.data.status_options.map(
      (data: any) => data.ts_name
    );

    const getStatusLabel = (data: any) => {
      return data;
    };

    const onStatusDoubleClick = (val: any, index: number) => {
      setStatusValue({ ts_name: val.ts_name, task_id: val.task_id });
      if (!val?.has_children &&( item.portfolioAccess != null ||
        item.programAccess != null ||
        item.projectAccess != null ||
        item.mopus_admin) ) {
        getOptionsMutate(val.task_project_id);
        setStatusDoubleClick("dropDownView");
      }
      setdropdownindex(index);
    };

    const closeStatusDropDown = () => setStatusDoubleClick("chipView");
    const searchvalue = {};
    const getStatusValue = (data: any) => {
      const statusval = allOptions?.data.status_options.filter((val: any) => {
        return val.ts_name == data;
      });
      statusSave({
        task_id: statusValue.task_id,
        task_value: statusval[0]?.ts_value,
        payload: searchvalue,
        status: statusval,
      });
      mode === "subTaskList"
        ? setsubtasklist(chipChange(taskList, dropdownindex, statusval))
        : dispatch(setTaskList(chipChange(taskList, dropdownindex, statusval)));

      closeStatusDropDown();
      tableupdated ? tableupdated() : void null;
    };

    const onArchiveIconClick = (data: any) => {
      const archiveLoadingVal = [...archiveLoading];
      archiveLoadingVal[data.index] = true;
      setArchiveLoading(archiveLoadingVal);
      getArchivedList({ data: data.data, index: data.index });
    };

    const onUnArchiveIconClick = (data: any) => {
      const unArchiveLoadingVal = [...archiveLoading];
      unArchiveLoadingVal[data.index] = true;
      setUnArchiveLoading(unArchiveLoadingVal);
      getUnarchiveList({ data: data.data, index: data.index });
    };

    const ddstyle = { width: "130px", height: "100%" };

    return (
      <tr key={index}>
        <td className="sy-primary sub-font">
          <span className="pointer" onClick={() => handleTaskIdClick(item)}>
            {item.task_id}
          </span>
        </td>
        <td
          className={`${Stylesheet.tablename} sub-font sy-primary`}
          id={item.task_id}
        >
          <SyTooltip title={item.task_name} followCursor={true}>
            <span className="pointer" onClick={() => handleTaskIdClick(item)}>
              {item.task_name}
            </span>
          </SyTooltip>
          {item.has_children && (
            <SyIcons
              name="account_tree"
              iconStyle=""
              className={Stylesheet.subtaskInticatorIcon}
            />
          )}
        </td>
        {/* )} */}
        <td className="sub-font">
          {item.task_start_date
            ? getDateLocaleString(item.task_start_date, "date")
            : null}
        </td>
        <td className={`${item.ts_name && ` sub-font`}`}>
          {statusDoubleClick === "chipView" || dropdownindex !== index ? (
            item.ts_name ? (
              <SyTooltip
                title={!item?.has_children ? t("double_click_to_change") : ""}
              >
                <span
                  onDoubleClick={() => onStatusDoubleClick(item, index)}
                  className={`${!item?.has_children ? `pointer` : ""}`}
                >
                  <SyChip
                    label={item.ts_name}
                    color={item.ts_color}
                    size={mode === "subTaskList" ? "sm" : "sm"}
                  />
                </span>
              </SyTooltip>
            ) : null
          ) : (
            dropdownindex === index &&
            (item.ts_name ? (
              <span onClick={(event) => event.stopPropagation()}>
                <Dropdown
                  options={statusOptions}
                  controlledVal={statusValue.ts_name}
                  placeholder="stat.."
                  getoptlabel={getStatusLabel}
                  handleChange={getStatusValue}
                  style={ddstyle}
                  clearIcon={true}
                  loading={detailsLoading}
                  onBlur={closeStatusDropDown}
                />
              </span>
            ) : null)
          )}
        </td>
        <td className="sub-font">
          {item.task_end_date
            ? getDateLocaleString(item.task_end_date, "date")
            : null}
        </td>
        <td className="sub-font">
          {mode === "subTaskList"
            ? item.task_priority_name
            : item.task_priority
            ? getPriorityLabel(item.task_priority)
            : null}
        </td>
        {mode === "subTaskList" ? null : (
          <td className="text-black-50 sub-font">
            {getDateLocaleString(item.created_at, "date")}
          </td>
        )}
        <td className="sub-font">
          {mode === "subTaskList"
            ? item.task_weight_name
            : item.task_weight
            ? getweightLabel(item.task_weight)
            : null}
        </td>

        {item.portfolioAccess != null ||
        item.programAccess != null ||
        item.projectAccess != null ||
        item.mopus_admin ? (
          <td>
            <span className="d-flex sub-font align-items-center">
              <SyIcons
                name={"edit"}
                className={"pointer"}
                iconType="icons"
                toolTipTitle="edit"
                value={item}
                onClick={handleTaskEdit}
              />
              {submitLoading[index] ? (
                <SyLoadingIcon />
              ) : (
                <SyIcons
                  name={"delete"}
                  className={`pointer `}
                  iconType="icons"
                  toolTipTitle="delete"
                  value={{ data: item.task_id, index: index }}
                  onClick={deleteConfirmPopup}
                />
              )}

              {item.task_archived === null ? (
                archiveLoading[index] ? (
                  <SyLoadingIcon />
                ) : (
                  <SyIcons
                    name="archive"
                    toolTipTitle={t("archive")}
                    value={{ data: item.task_id, index: index }}
                    onClick={onArchiveIconClick}
                  />
                )
              ) : unArchiveLoading[index] ? (
                <SyLoadingIcon />
              ) : (
                <SyIcons
                  name="unarchive"
                  toolTipTitle={t("unarchive")}
                  value={{ data: item.task_id, index: index }}
                  onClick={onUnArchiveIconClick}
                />
              )}
            </span>
          </td>
        ) : null}
      </tr>
    );
  };

  const openPopUp = () => {
    setFilterPopupOpen(true);
  };

  const searchEventEmit = (searchValue: any) => {
    taskPayload(
      searchValue,
      pageIndex,
      PageSize,
      sorting,
      (isArchive.from === "tasklist" && isArchive.data) ||
        (mode === "subTaskList" &&
          isArchive.from === "subTasklist" &&
          isArchive.data)
    );
    sySetPersistData(`${mode}_${task_id}_Search`, searchValue);
  };

  const handlePaginateClick = ({
    pageIndex,
    rowPerPage,
  }: {
    pageIndex: any;
    rowPerPage: any;
  }) => {
    const payloadWithSorting = {
      ...payload,
      paging: {
        pageIndex: pageIndex,
        pageSize: rowPerPage,
        globalFilter: null,
        pageSizeOptions: pageSizeOptions,
      },
      search: searchValue,
      showArchived: isArchive.data,
      mine: my_tasks,
      sorting: sorting,
    };

    setPageIndex(pageIndex);
    setPageSize(rowPerPage);
    // getTasklist({
    //   data: navPath.path[navPath.path.length - 1],
    //   taskpayload: payloadWithSorting,
    // });
    taskPayload(
      searchValue,
      pageIndex,
      rowPerPage,
      sorting,
      isArchive.from === "tasklist" && isArchive.data
    );
  };

  const handleSort = (key: string) => {
    const sortedTable: any = {
      active: key,
      direction:
        sorting.active === key && sorting.direction === "asc" ? "desc" : "asc",
    };
    setSorting(sortedTable);
    taskPayload(searchValue, pageIndex, PageSize, sortedTable, isArchive.data);
  };

  // const handleformSubmit = (data: any) => {
  //   setPopupOpen(false);
  //   if (data) submitForm(data);
  // };

  const filterChip = () => {
    const employeePersistVal =
      syGetPersistData(`${mode}_${task_id}_taskemployee`) || [];
    const statusPersistVal =
      syGetPersistData(`${mode}_${task_id}_status`) || null;
    const tagPersistVal = syGetPersistData(`${mode}_${task_id}_tag`) || [];
    const labelPersistVal = syGetPersistData(`${mode}_${task_id}_label`) || [];

    const filterObj: { id: any; name: any; type: any }[] = [];

    employeePersistVal?.forEach((employee: any) => {
      const employeeObj = {
        id: employee.id,
        name: employee.name,
        type: "taskemployee",
      };
      filterObj.push({ ...employeeObj });
    });

    const statusObj = {
      id: statusPersistVal?.ts_value,
      name: statusPersistVal?.ts_name,
      type: "status",
    };
    if (statusPersistVal) {
      filterObj.push({ ...statusObj });
    }

    tagPersistVal?.forEach((tag: any) => {
      const employeeObj = {
        id: tag.pt_id,
        name: tag.pt_name,
        type: "tag",
      };
      filterObj.push({ ...employeeObj });
    });

    labelPersistVal?.forEach((label: any) => {
      const employeeObj = {
        id: label.l_id,
        name: label.l_name,
        type: "label",
      };
      filterObj.push({ ...employeeObj });
    });

    setFilterData(filterObj);
    getFilterListPayload();
  };

  const submitForm: SubmitHandler<FieldValues> = (data: any) => {
    
    sySetPersistData(`${mode}_${task_id}_taskemployee`, data?.taskemployee);

    sySetPersistData(`${mode}_${task_id}_status`, data?.status);

    sySetPersistData(`${mode}_${task_id}_tag`, data?.tagslist);

    sySetPersistData(`${mode}_${task_id}_label`, data?.label);

    filterChip();
    getFilterListPayload();
  };

  const getFilterListPayload = () => {
    const employeePersistVal =
      syGetPersistData(`${mode}_${task_id}_taskemployee`) || [];

    const statusPersistVal =
      syGetPersistData(`${mode}_${task_id}_status`) || null;

    const tagPersistVal = syGetPersistData(`${mode}_${task_id}_tag`) || [];

    const labelPersistVal = syGetPersistData(`${mode}_${task_id}_label`) || [];


  


    const payload = {
      paging: {
        pageIndex: 0,
        pageSize: 100,
        globalFilter: null,
        pageSizeOptions: pageSizeOptions,
      },
      filter: {
        tags:
          tagPersistVal?.map((data: any) => {
            return data.pt_value;
          }) || [],
        task_status: statusPersistVal?.ts_value || "",
        assignees: employeePersistVal,
        created_by: null,
        label_ids:
          labelPersistVal?.map((data: any) => {
            return data.l_id;
          }) || [],
        labels:
          labelPersistVal?.map((data: any) => {
            return data;
          }) || [],
      },
      search: searchValue,
      showArchived:
        isArchive.from === "tasklist" && mode !== "subTaskList"
          ? isArchive.data
          : false || (mode === "subTaskList" && isArchive.from !== "tasklist")
          ? isArchive.data
          : false,
      sorting: sorting,
      mine: my_tasks,
    };
    setFilterPayload(payload);
    const taskParameter = taskId || "";
    mode === "subTaskList"
      ? getSubTasklist({
          proId: itemId,
          taskpayload: payload,
          taskId: taskParameter,
        })
      : getTasklist({
          data: navPath.path[navPath.path.length - 1],
          taskpayload: payload,
        });
  };

  const handlePopupClose = () => {
    // mode === "subTaskList"
    //   ? setDirtyValues(!!dirtyValues)
    //   : settaskPopupOpen(false);
    dirtyValues && onClickNavBtn
      ? onClickNavBtn(navPath.path[navPath.path.length - 1], {
          from: "popup",
          mine: my_tasks,
        })
      : void null;
    if (mode === "subTaskList") {
      setDirtyValues(!!dirtyValues);
      settaskPopupOpen(false);
    } else {
      settaskPopupOpen(false);
      navigate("/");
      dispatch(updateSelectedTask(""));
      setQueryParams(
        getDashBoardParamPayload(
          navPath.path,
          my_tasks,
          viewControlDetail,
          isArchive.data
        )
      );
    }
  };

  const updateDash = (data: boolean) => {
    return setDirtyValues(data);
  };

  const updatedSubtask = (data: boolean) => {
    data ? tableupdated && tableupdated() : void 0;
    setDirtyValues(data);
  };
  const handleSubTask = () => {
    navigate(`/mopus/project/${pro_id}/task/add/${task_id}`);
    settaskPopupOpen(false);
  };

  const onChangePersist = (data: any) => {
    if (data.action === "remove") {
      taskPayload("", pageIndex, PageSize, sorting, isArchive.data);
      syDeletePersistData(`${mode}_${task_id}_Search`);
    }
  };

  const onRemoveFilter = (data: any) => {
    const deleteObj = syGetPersistData(`${mode}_${task_id}_${data.type}`);
    const deleteType = data.type;

    if (data.type !== "status") {
      const id =
        data.type === "tag" ? "pt_id" : data.type === "label" ? "l_id" : "id";

      const name =
        data.type === "tag"
          ? "pt_name"
          : data.type === "label"
          ? "l_name"
          : "name";

      const updateFilterData = deleteObj?.filter((item: any) => {
        if (item[id] != data.id) {
          return true;
        }
      });

      sySetPersistData(`${mode}_${task_id}_${data.type}`, updateFilterData);

      const findCloseChip = filterData?.filter((item: any) => {
        if (item.type != data.type) {
          return true;
        }
      });

      updateFilterData?.forEach((data: any) => {
        const employeeObj = {
          id: data[id],
          name: data[name],
          type: deleteType,
        };
        findCloseChip.push({ ...employeeObj });
      });

      setFilterData(findCloseChip);
    } else {
      syDeletePersistData(`${mode}_${task_id}_${data.type}`);
      const updateStatusData = filterData?.filter((item: any) => {
        if (item.type != data.type) {
          return true;
        }
      });
      setFilterData(updateStatusData);
    }

    getFilterListPayload();
  };

  const closeStatusDropDown = (e: any) => {
    setStatusDoubleClick("chipView");
  };

  const onTaskArchiveClick = (data: any) => {
    dispatch(setArchiveClick({ data: data.target.checked, from: "tasklist" }));
    taskPayload(searchValue, pageIndex, PageSize, sorting, data.target.checked);
  };
  const onSubtaskArchiveClick = (data: any) => {
    dispatch(
      setArchiveClick({ data: data.target.checked, from: "subTasklist" })
    );
    taskPayload(searchValue, pageIndex, PageSize, sorting, data.target.checked);
  };

  const closeFilterPopup = () => {
    setFilterPopupOpen(false);
  };

  const getTaskNameOnSucess = (title: string) => {
    setSubTitleName(title);
  };

  const getFilterValue ={
    employeePersistVal:employeePersistVal,
    statusPersistVal:statusPersistVal,
    tagPersistVal:tagPersistVal,
    labelPersistVal:labelPersistVal  
  }
  return (
    <>
      {taskpopupOpen &&
        (mode === "subTaskList" ? (
          <Popup
            open={taskpopupOpen}
            close={settaskPopupOpen}
            onClose={handlePopupClose}
            scroll="paper"
            maxWidth={"lg"}
            showHeader={true}
            title={subTitleName}
            showCloseIcon={true}
            model_type={"responsive"}
            children={
              <SyTaskdetailsPopup
                oncloseclick={handlePopupClose}
                pro_id={taskIdData.projectId}
                task_id={taskIdData?.taskId}
                taskValue={taskListValue}
                updateDashboard={updatedSubtask}
                mode={"subTaskDetails"}
              />
            }
          />
        ) : (
          <Popup
            open={taskpopupOpen}
            close={settaskPopupOpen}
            onClose={handlePopupClose}
            scroll="paper"
            showHeader={true}
            title={subTitleName}
            showCloseIcon={true}
            model_type={"alert"}
            maxWidth={"xl"}
            children={
              <SyTaskdetailsPopup
                getTaskNameOnSucess={getTaskNameOnSucess}
                oncloseclick={handlePopupClose}
                pro_id={taskIdData.projectId}
                task_id={taskIdData?.taskId}
                taskValue={taskListValue}
                updateDashboard={updateDash}
              />
            }
          />
        ))}
      <div
        className={`${Stylesheet.container}  ${
          mode === "subTaskList" ? "" : "pt-2"
        }`}
        onClick={closeStatusDropDown}
      >
        <div
          className={`${Stylesheet.tablecontainer} rounded ${
            mode === "subTaskList" ? "" : "p-3"
          } `}
        >
          <div
            className={`d-flex align-items-center justify-content-between flex-wrap`}
          >
            <div className="d-flex align-items-center">
              {mode === "subTaskList" ? (
                !childern && mode === "subTaskList" ? (
                  ""
                ) : (
                  <div className="ms-1">
                    <h6 className="text-black-50 mb-0 sub-font semi-b">
                      {t("sub_task_list")}
                    </h6>
                  </div>
                )
              ) : (
                <div className="ms-1">
                  {/* <h6 className="main-font semi-b mb-0">
                    {t("all")} {t("task")}
                  </h6> */}
                </div>
              )}
            </div>

            <div className={` d-flex align-items-center`}>
              {!childern && mode === "subTaskList" ? (
                ""
              ) : (
                <div className="me-2">
                  <InputForm
                    type={"checkbox"}
                    placeholder={t("show_archived")}
                    onChange={
                      mode !== "subTaskList"
                        ? onTaskArchiveClick
                        : onSubtaskArchiveClick
                    }
                    defaultChecked={
                      isArchive.from === "tasklist" &&
                      mode !== "subTaskList" &&
                      isArchive.data
                        ? isArchive.data
                        : isArchive.from === "subTasklist" &&
                          mode === "subTaskList" &&
                          isArchive.data
                        ? isArchive.data
                        : void null
                    }
                    checkboxPlaceholderSide="left"
                  />
                </div>
              )}
              {/* DO NOT DELETE */}
              {!childern && mode === "subTaskList" ? (
                ""
              ) : (
                <Sy_Search
                  searchEventEmit={searchEventEmit}
                  searchValue={searchValue}
                  showFilterIcon={mode === "subTaskList" ? true : true}
                  onFilterClick={openPopUp}
                />
              )}
              {mode === "subTaskList" && (
                <div className="ms-2">
                  <Sybutton
                    type={"button"}
                    className={`${Stylesheet.addButton} iconButton d-flex align-items-center`}
                    size={"sm"}
                    onBtnClick={handleSubTask}
                    label={t("add_subtask")}
                    icontype="add"
                    value={t("add_subtask")}
                    disabled={disable}
                  />
                </div>
              )}
              {filterPopupOpen && (
                <Popup
                  model_type={"responsive"}
                  open={filterPopupOpen}
                  close={setFilterPopupOpen}
                  onClose={closeFilterPopup}
                  showHeader={true}
                  title={t("filters")}
                  style={{ width: "400px" }}
                >
                  <FilterPopup
                    mode={mode}
                    ids={ids}
                    getFilterValue={getFilterValue}
                    Open={setFilterPopupOpen}
                    onSubmit={submitForm}
                  />
                </Popup>
              )}
            </div>
          </div>
          {openconfirmPopup && (
            <SyconfirmPopup
              onConfirmClick={handleTaskDelete}
              open={openconfirmPopup}
              close={setopenconfirmPopup}
            />
          )}

          <div className={`${Stylesheet.persist} m-2 d-flex flex-wrap  `}>
            <SyPersist
              filterValue={filterData}
              searchValue={searchValue}
              onChange={onChangePersist}
              filterDelete={onRemoveFilter}
            />
          </div>

          {!childern && mode === "subTaskList" ? (
            ""
          ) : (
            <div
              className={`${
                mode === "subTaskList"
                  ? `${Stylesheet.table_div} " sy_horizontal_scroll sy_vertical_scroll "`
                  : "sy_horizontal_scroll"
              }`}
            >
              {/* {taskLoading ? (
              <div className="d-flex justify-content-center">
                <SyLoadingIcon color="primary" size="sm" />
              </div>
            ) : ( */}

              <table className="table ellipsis">
                <thead>
                  <tr>
                    {mode === "subTaskList"
                      ? subtaskHeaders.map((header) => (
                          <TaskHeader
                            key={header.key}
                            header={header}
                            handleSort={handleSort}
                            sorting={sorting}
                            list={mode === "subTaskList" && "subTaskList"}
                          />
                        ))
                      : taskHeaders.map((header) => (
                          <TaskHeader
                            key={header.key}
                            header={header}
                            handleSort={handleSort}
                            sorting={sorting}
                          />
                        ))}
                    {/* {(taskListValue.portfolioAccess != null ||
          taskListValue.programAccess != null ||
          taskListValue.projectAccess != null) &&
          taskListValue.access_member ? */}
                    <th
                      className={`${Stylesheet.theader} sub-font ${
                        mode === "subTaskList" ? "pb-1" : ""
                      }`}
                      scope="col"
                    >
                      {t("action")}
                    </th>
                    {/* :null} */}
                  </tr>
                </thead>
                {taskLoading || subTaskLoading || taskListLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={8}>
                        <p className="d-flex justify-content-center opacity-50">
                          {t("loading") + "..."}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ) : taskList?.total == 0 ||
                  !taskList ||
                  taskList?.data?.length == 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan={8}>
                        <p className="d-flex justify-content-center opacity-50">
                          {t("no_data_found")}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="table-group-divider ">
                    {taskList &&
                      taskList?.data?.length > 0 &&
                      taskList?.data.map((item: any, index: any) => (
                        <TaskList key={index} item={item} index={index} />
                      ))}
                  </tbody>
                )}
                {/* {taskList?.length === 0 && !taskLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={8}>
                      <p className="d-flex justify-content-center opacity-50">
                        no_data_found
                      </p>
                    </td>
                  </tr>
                </tbody>
              ) : null} */}
              </table>
              {/* )} */}
            </div>
          )}
          {mode === "subTaskList"
            ? null
            : taskList.total > 0 && (
                <div className="d-flex justify-content-end pe-2">
                  <p className="opacity-50">
                    {t("total")}:{taskList ? taskList?.total : null}
                  </p>
                </div>
              )}
          {taskList?.total > 100 && !taskLoading ? (
            <div
              className={`${Stylesheet.pagination} d-flex justify-content-center border rounded `}
            >
              <Sy_Pagination
                total={taskList?.total > 0 ? taskList?.total : 0}
                handlePaginateClick={handlePaginateClick}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
});

export default TaskListScreen;
