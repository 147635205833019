import dayjs from "dayjs";
import * as yup from "yup";
import {getDateLocaleString} from "../../../../../../shared/utils/dayjs"

export const processSchema = yup.object({
  pro_name: yup.string().trim().required("field_required"),
  pro_start_date: yup.date().nullable().required("field_required"),
  pro_end_date: yup
    .date()
    .nullable()
    .test("", "invalid_time_range", (value, context) => {
      const { pro_start_date } = context.parent;
      if (pro_start_date && value) {
        return getDateLocaleString(value,"date") >= getDateLocaleString(pro_start_date,"date");
      }
      return true;
    }),
  pro_status: yup.mixed().nullable().required("field_required"),
  pro_budget: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) || value === 0 ? null : value))
    .positive("dont_enter_a_negative_number"),
  labels: yup.array().nullable(),
});
