// import { getCurrentLocation } from "../../../../shared/utils"

export const getParamPayload = (value: any) => {
  let param = "";
  if (value && value.length > 0) {
    param = value.reduce((acc: any, val: any, index: number) => {
      const key =
        val.masterId === "1" ? "pf" : val.masterId === "2" ? "pg" : "pj";
      return `${acc}${index === 0 ? key : "&" + key}=${val.id}`;
    }, param);
  }
  return param;
};

export const getParamValue = (searchParams: any) => {
  return {
    pf: searchParams.get("pf") ? searchParams.get("pf") : null,
    pg: searchParams.get("pg") ? searchParams.get("pg") : null,
    pj: searchParams.get("pj") ? searchParams.get("pj") : null,
    mine: searchParams.get("mine") ? searchParams.get("mine") : null,
    view: searchParams.get("view")
      ? searchParams.get("view") == "grid"
        ? "grid"
        : "list"
      : null,
    archive: searchParams.get("archive") ? searchParams.get("archive"):null,
    selected: searchParams.get("sel") ? searchParams.get("sel"):null,
  };
};
