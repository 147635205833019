import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { forwardRef, useEffect, useRef } from "react";
import Errormessage from "../errormessage/errormessage";

type Props = {
  Placeholder: string;
  value?: string | null;
  date?: any;
  selectedDate: (value: string) => void;
  errorMessage?: any;
};

const Datepicker = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { Placeholder, value, selectedDate, date, errorMessage, ...rest } = props;

  const currentDate = new Date();

  const handleValAccept = (val: any) => {
    selectedDate(val ? (val.$d ? val.$d : null) : null);
    // selectedDate(val.format("YYYY-MM-DD"));
  };

  const defDate =
    value === null ? null : value ? dayjs(value) : dayjs(currentDate);

  const datePickerRef = useRef<HTMLInputElement>(null);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          defaultValue={defDate}
          value={dayjs(date)}
          ref={datePickerRef}
          label={Placeholder}
          onChange={handleValAccept}
          format="DD-MM-YYYY"
          slotProps={{
            actionBar: {
              actions: ["clear", "cancel", "accept", "today"],
            },
            textField: {
              size: "small",
              error: false,
              fullWidth: true,
            },
          }}
          {...rest}
        />
      </LocalizationProvider>
      {errorMessage ? (
          <span className="d-flex justify-content-end">
            <Errormessage message={errorMessage} />
          </span>
        ) : (
          void null
        )}
    </div>
  );
});

export default Datepicker;
