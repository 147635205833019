// Get the material ui color codes from below link
// https://materialui.co/colors/

const APP_THEME_COLORS = {
  0: {
    primary: "#01335B",
    secondary: "#01335B20",
  },
  1: {
    primary: "#0C5EA7",
    secondary: "#0C5EA720",
  },
  2: {
    primary: "#1A9DD9",
    secondary: "#1A9DD920",
  },
  3: {
    primary: "#05005A",
    secondary: "#05005A20",
  },
  4: {
    primary: "#E3000E",
    secondary: "#E3000E20",
  },
  5: {
    primary: "#008000",
    secondary: "#00800020",
  },
  6: {
    primary: "#32CD32",
    secondary: "#32CD3220",
  },
  7: {
    primary: "#2F4F4F",
    secondary: "#2F4F4F20",
  },
};

const APP_COLORS = {
  primary: "#3286C3", // default primary color
  white: "#FFFFFF",

  black: "#000000",
  black_600: "#333333",

  blue_600: "#1E88E5",

  light_blue_100:"#B3E5FC",
  active_green:"rgb(200,235,182)",
  inactive_red:"rgb(235,182,182)",
  gray_85: "#d9d9d9",
  gray_100: "#EBEBEB",
  gray_200: "#E0E0E0",
  gray_400: "#BDBDBD",
  gary_600: "#757575",

  green_600: "#43A047",

  danger: "#ff3f34",
  danger_200: "rgb(186, 26, 26)",

  badge: "#F1C04A",

  backdrop_light: "rgba(0,0,0,0.2)",
  backdrop: "rgba(0,0,0,0.7)",
  commentBg: "#cbd1f0",
  badge: "#F1C04A",
};



export let THEME = {
  colors: {
    ...APP_COLORS,
  },
};


export const primaryColor = APP_COLORS.primary;
