import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Demo from './projects/demo';
import Simpler from './projects/simpler';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const projects:any = {
  demo: Demo,
  simpler: Simpler,
};
console.log(process.env.REACT_APP_PROJECT);
const currentProject = process.env.REACT_APP_PROJECT || 'simpler';

const App = projects[currentProject];


root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
