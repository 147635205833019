import Stylesheet from "./style.module.scss";
type Props = { vertical: boolean; value: number; dayData?: any };

const ProgressBar = (props: Props) => {
  const { vertical = false, value, dayData } = props;
  return (
    <>
      <div
        className={
          vertical
            ? `${Stylesheet.chart2} ${Stylesheet.progress} progress ${Stylesheet.vertical} vertical `
            : `${Stylesheet.chart} progress ${Stylesheet.progress}`
        }
      >
        <div
          className={`${Stylesheet.progress_bar} `}
          style={{ width: `${value}%` }}
        ></div>
      </div>
      {dayData && (
        <div className={`${Stylesheet.progress_day} pt-5`}>
          <p className="ps-4 ms-4 d-flex p-0 opacity-50">{dayData}</p>
        </div>
      )}
    </>
  );
};

export default ProgressBar;
