export const status = [
  {
    id: "1",
    value: "active",
  },
  {
    id: "2",
    value: "inactive",
  },
];
