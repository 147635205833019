export const headerName = (val: any) => {
  if (val.parentId) {
    return "add_subtask";
  }
  if (val.editTaskId && val.subTask.task) {
    return "edit_subtask";
  }
  if (val.editTaskId && !val.parentId) {
    return "edit_task";
  } else {
    return "add_task";
  }
};
