import { clearAll, deleteCookie, getJsonData } from "../../../../shared/utils";
import config from "../../../../environments/env-config";


export const doLogOut=(()=>{
    deleteCookie(config.auth_token, config.auth_domain);
    clearAll();
    // dispatch({ type: "SIGN_OUT", authToken: "" });
    if (process.env.REACT_APP_ENV) {
      window.location.href = `${config?.auth_url}/?al=2`;
    } else {
      window.location.href = `${config?.simpler_url }`;
    }
})