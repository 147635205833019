import React from "react";
import ProgressBar from "../../../../../../shared/components/progress-bar/progress-bar";
import Stylesheet from "./style.module.scss";
import { useTranslation } from "react-i18next";

type Props = {};
interface ProgressProps {
  value: number;
  vertical: boolean;
  total: number;
}
const Progressbar: React.FC<ProgressProps> = ({ value, vertical, total }) => {
  const percentage = (value / total) * 100;
  const inprogress = total - value;
  const { t } = useTranslation();
  return (
    <div className="card border border-0 align-items-center">
      <div className="mb-4 justify-content-center">
        <h6 className="main-font semi-b">
          {t("all")} {t("task")}{" "}
        </h6>
      </div>
      <div className={`${Stylesheet.bar} pt-3`}>
        <ProgressBar
          value={percentage > 0 ? percentage : 0}
          vertical={vertical}
        />
      </div>
      <span className={`${Stylesheet.progressVal}`}>
        {inprogress ? inprogress : 0}
      </span>
      <div
        className={`${Stylesheet.fontSize} mt-4 pt-1 justify-content-center`}
      >
        <span className="d-flex justify-content-center">
          <p className="sub-font mb-0 mt-0">
            {total > 0 ? total : 0} {t("task")}
          </p>
        </span>
        {/* <span className="d-flex fw-normal ellipsis text-muted sub-font">
          {t("are_currently_in_circulation")}
        </span> */}
      </div>
    </div>
  );
};

export default Progressbar;
