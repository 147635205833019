import React, { useEffect, useState } from "react";
import Sy_CustomFooter from "../../../../../../shared/components/sy-model/sy-customfooter";
import { Controller, useForm } from "react-hook-form";
import getCommentData from "../../service/comment-service";
import { useSelector } from "react-redux";
import { getNavDetails } from "../../../../feature/simpler-slice";
import { masterStatus } from "./helper";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import { useMutation } from "@tanstack/react-query";

type Props = {
  mode: any;
  ids: any;
  Open: any;
  onSubmit: any;
  getFilterValue:any
};

const FilterPopup = ({
  mode,
  ids,
  Open,
  onSubmit,
  getFilterValue
}: Props) => {
  const navPath = useSelector(getNavDetails);

  const getselectedId = navPath.path[navPath.path.length - 1];

  const [labels, setLabels] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [taskStatus, setTaskStatus] = useState<any>([]);
  const [taskEmployee, setTaskEmployee] = useState<any>([]);
  const { t } = useTranslation();

  const { handleSubmit, control,setValue } = useForm();

  const { mutate: getTaskStatus, isLoading: statusLoading } = useMutation({
    mutationFn: getCommentData.TaskStatus,
    onSuccess(data) {
      setTaskStatus(data.data ? data.data : []);
    },
  });

  const { mutate: getLabel, isLoading: labelLoading } = useMutation({
    mutationFn: getCommentData.getProcessLabel,
    onSuccess(data) {
      setLabels(data.data ? data.data : []);
    },
  });

  const { mutate: getAllTags, isLoading: tagsLoading } = useMutation({
    mutationFn: getCommentData.getTaskTags,
    onSuccess(data) {
      setTags(data.data ? data.data : []);
    },
  });
  const { mutate: getTags } = useMutation({
    mutationFn: getCommentData.getTags,
    onSuccess(data) {
      setTags(data.data ? data.data : []);
    },
  });

  const { mutate: getTaskUsers, isLoading: employeeLoading } = useMutation({
    mutationFn: getCommentData.getTaskListUsers,
    onSuccess(data) {
      setTaskEmployee(data?.data?.data ? data?.data?.data : []);
    },
  });
  const { mutate: getAssignedProUsers } = useMutation({
    mutationFn: getCommentData.getAssignedProUsers,
    onSuccess(data) {
      setTaskEmployee(data?.data ? data?.data : []);
    },
  });

  const handlestatusfilter = () => {
    return;
  };

  const handleLabelLoading = labelLoading
    ? labelLoading
    : labels.length == 0 && "nodata";

  const handleTagsLoading = tagsLoading
    ? tagsLoading
    : tags.length == 0 && "nodata";

  const handleEmployeeLoading = employeeLoading
    ? employeeLoading
    : taskEmployee.length == 0 && "nodata";

  const searchvalue = {};

  const handleLabels = (inputdata: any) => {
    getLabel(searchvalue);
  };

  const handleTaskEmployeeList = (inputdata: any) => {
    const value = inputdata ? inputdata : "";
    mode !== "subTaskList"
      ? getTaskUsers({
          data: navPath.path[navPath.path.length - 1],
          val: value,
        })
      : getAssignedProUsers({ data: ids.itemId, val: value });
  };

  const handleTagInput = (inputdata: any) => {
    const value = inputdata ? inputdata : "";
    const tagsPayload = {
      search: value,
    };
    mode !== "subTaskList"
      ? getAllTags({ data: navPath.path[navPath.path.length - 1] })
      : getTags({ itemId: ids.itemId, val: value });
  };
  
  const setValues=()=>{

    setValue("status", getFilterValue.statusPersistVal);
    setValue("label", getFilterValue.labelPersistVal);
    setValue("taskemployee",getFilterValue.employeePersistVal);
    setValue("tagslist", getFilterValue.tagPersistVal);

  }

  useEffect(() => {
    setValues()
    if (mode !== "subTaskList") {
      getAllTags({ data: getselectedId });
      getTaskUsers({ data: getselectedId });
    } else {
      getAssignedProUsers({ data: ids.itemId });
      getTags({ itemId: ids.itemId });
    }
    getLabel(searchvalue);
    getTaskStatus({
      data: ids.itemId
        ? { id: ids.itemId }
        : getselectedId.masterId == 3
        ? getselectedId
        : { id: ids.itemId },
    });
  }, []);

  const handleformSubmit = (data: any) => {
    Open(false);
    if (data) onSubmit(data);
    
  };

  return (
    <>
      <div className="mb-3">
        <Controller
          name="status"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Dropdown
                options={
                  (navPath.path[navPath.path.length - 1].masterId == 1 &&
                    mode !== "subTaskList") ||
                  (navPath.path[navPath.path.length - 1].masterId == 2 &&
                    mode !== "subTaskList")
                    ? masterStatus
                    : taskStatus
                }
                placeholder={t("status")}
                enableSearchBox={false}
                loading={false}
                handleFilter={handlestatusfilter}
                handleChange={onChange}
                controlledVal={value?value:null}
                multiple={false}
                getoptlabel={function (val: any): string {
                  return val.ts_name;
                }}
              />
            );
          }}
        />
      </div>
      <div className="mb-3">
        <Controller
          name="label"
          control={control}
          render={({ field: { onChange, value } }) => {
   
            return (
              <Dropdown
                options={labels}
                controlledVal={value?value:[]}
                placeholder={t("label")}
                enableSearchBox={true}
                loading={handleLabelLoading}
                handleFilter={handleLabels}
                handleChange={onChange}
                multiple={true}
                getoptlabel={function (val: any): string {
                  return val.l_name;
                }}
              />
            );
          }}
        />
      </div>
      <div className="mb-3">
        <Controller
          name="taskemployee"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Dropdown
                options={taskEmployee}
                controlledVal={value?value:[]}
                placeholder={t("task_employee")}
                enableSearchBox={true}
                loading={handleEmployeeLoading}
                handleFilter={handleTaskEmployeeList}
                handleChange={onChange}
                multiple={true}
                getoptlabel={function (val: any): string {
                  return val.name;
                }}
              />
            );
          }}
        />
      </div>
      <div className="mb-3">
        <Controller
          name="tagslist"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Dropdown
                options={tags ? tags : []}
                placeholder={t("tags")}
                enableSearchBox={true}
                controlledVal={value?value:[]}
                loading={handleTagsLoading}
                handleFilter={handleTagInput}
                handleChange={onChange}
                multiple={true}
                getoptlabel={function (val?: any): string {
                  return val.pt_name;
                }}
              />
            );
          }}
        />
      </div>
      {/* <div className="mb-3">
      <Controller
        name="created_by"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Dropdown
              options={createdbyUsers}
              placeholder={t("created_by")}
              enableSearchBox={true}
              controlledVal={value}
              loading={createdbyLoading}
              handleFilter={handleTaskCreatedByList}
              handleChange={onChange}
              multiple={true}
              getoptlabel={function (val: any): string {
                return val.name;
              }}
            />
          );
        }}
      />
    </div> */}
      <Sy_CustomFooter
        handleSubmit={handleSubmit}
        handleformSubmit={handleformSubmit}
      />
    </>
  );
};

export default FilterPopup;
