import React, { useState } from "react";
import StyleSheet from "./style.module.scss";
import Button from "./button";
import { AppBar, CssBaseline, Toolbar, Tabs, Typography } from "@mui/material";

type Props = {};
interface NavButtonProps {
  buttons: any;
  onclick:any;
  activeSection:any
}

const SyNavButton: React.FC<NavButtonProps> = ({ buttons,onclick,activeSection }) => {



  return (
    <>
      <AppBar
        position="relative"
        className={`${StyleSheet.customAppBar} shadow-none `}
      >
        <CssBaseline />
        <Toolbar disableGutters>
          <div className="d-flex customAppBar text-nowrap ">
            {buttons.details.map((button: any, i: number) => (
              <Button key={button.id} {...button} index={i} onclick={onclick} activeSection={activeSection} />
            ))}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default SyNavButton;
