import { useEffect, useState } from "react";
import StyleSheet from "./style.module.scss";
import { useContext } from "react";
import { AuthContext } from "../../../../../context";
import commentService from "../../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import SyconfirmPopup from "../../../../../../../shared/components/sy-confirmPopup";
import SyIcons from "../../../../../../../shared/components/sy-icons";
import SyLoadingIcon from "../../../../../../../shared/components/sy-loading-icon";
import { useTranslation } from "react-i18next";
import { SyToast } from "../../../../../../../shared/utils/sy-toast";
import SyFileDnD from "../../../../../../../shared/components/sy-file-dnd";
import { SyFileReference, syOpenFile } from "../../../../../../../shared/utils";
import {
  FILE_ICON_NAME,
  getfiletype,
} from "../../../../../../../shared/utils/sy-file-utils";
import { MAIN_API_URL } from "../../../../../../../shared/constants";

type Props = {};
interface Data {
  taskid: any;
  itemId: any;
  editTaskId: any;
  uploadStatus: any;
}

const DatataskManage = ({ taskid, itemId, editTaskId, uploadStatus }: Data) => {
  const [fileTable, setfileTable] = useState([]);
  const [displayType, setDisplayType] = useState("list");
  const [isOpen, setIsOpen] = useState(false);
  const [openconfirmPopup, setopenconfirmPopup] = useState(false);

  const [deleteConfirmData, setDeleteConfirmData] = useState<any>({
    data: "",
    index: "",
  });
  const authToken = useContext(AuthContext);

  const [submitLoading, setSubmitLoading] = useState<any>([]);
  const { t } = useTranslation();

  // const openPopUp = () => {
  //   setIsOpen(true);
  // };

  // const closePopUp = () => {
  //   setIsOpen(false);
  // };

  const api = `${MAIN_API_URL}public/modules/mopus/main/fileUpload/${itemId}/${
    editTaskId ? editTaskId : taskid
  }`;

  const deleteConfirmPopup = (val: any) => {
    setDeleteConfirmData(val);
    setopenconfirmPopup(true);
  };

  const handleDisplayChange = (val: string) => {
    setDisplayType(val);
  };

  const fileDetails = (data: any) => {
    setfileTable(data.data.docs);
  };

  const { mutate: fileDocMutate } = useMutation({
    mutationFn: commentService.getFileDoc,
    onSuccess(data, variables, context) {
      setfileTable(data.data.docs);
      fileDetails(data);
    },
  });

  const { mutate: deletFile } = useMutation({
    mutationFn: commentService.deleteFile,
    onSuccess(data, variables, context) {
      const message = data.data.message;
      SyToast(t(message), "bottom-right", "short", "success");
      const indexval = [...submitLoading];
      indexval[variables.index] = false;
      setSubmitLoading(indexval);
      FileDoc();
    },
    onError(error: any, variables, context) {
      const message = error.message;
      SyToast(t(message), "bottom-right", "short", "error");
    },
  });

  const FileDoc = () => {
    const payload = {
      filter: { fileType: null },
      paging: { pageIndex: 0, pageSize: 12 },
      search: null,
    };
    const taskId = editTaskId ? editTaskId : taskid;
    fileDocMutate({ itemId, taskId, payload });
  };

  const deletFiles = () => {
    const submitLoadingVal = [...submitLoading];
    submitLoadingVal[deleteConfirmData.index] = true;
    setSubmitLoading(submitLoadingVal);
    deletFile(deleteConfirmData);
  };

  const { mutate: fileMutate } = useMutation({
    mutationFn: commentService.getFileDoc,
    onSuccess(data, variables, context) {
      setfileTable(data.data.docs);
      fileDetails(data);
    },
  });

  const payload = {
    filter: { fileType: null },
    paging: { pageIndex: 0, pageSize: 12 },
    search: null,
  };
  useEffect(() => {
    if (editTaskId) {
      const taskId = editTaskId ? editTaskId : taskid;
      fileMutate({ itemId, taskId, payload });
    }
  }, []);

  const iconName = (data: any) => FILE_ICON_NAME[getfiletype(data)];
  const dataTable = () => {
    return (
      <>
        {fileTable.length === 0 ? (
          <div className="mt-5">
            <h4 className="d-flex  justify-content-center opacity-25">
              {" "}
              {t("no_data_found")}
            </h4>
          </div>
        ) : (
          <>
            {displayType === "list" && (
              <div className="mt-2 mx-1">
                <table className="table">
                  <tbody>
                    {fileTable?.length > 0 &&
                      fileTable.map((data: any, index: number) => {
                        const handleFileIconClick = () => {
                          syOpenFile({
                            fileName: data.md_name,
                            authToken: authToken.authToken,
                            path: "mopus_docs",
                          });
                        };
                        return (
                          <tr key={data.md_id}>
                            <td className=" col-2">
                              {getfiletype(data.md_name) === "image" ? (
                                //   <div className={`position-relative ${StyleSheet.image}`}>
                                //     <video
                                //       src={SyFileReference(data.md_name)}
                                //       onClick={handleFileIconClick}
                                //       poster="https://cdn-icons-png.flaticon.com/128/1179/1179069.png"
                                //       className={`${StyleSheet.image}`}
                                //     ></video>
                                //     <div
                                //       className={`${StyleSheet.overlayIconStyle}`}
                                //     >
                                //       <SyIcons
                                //         className={StyleSheet.smallvideoIcon}
                                //         name={"play_circle_filled"}
                                //         iconStyle="outlined"
                                //         iconType="icons"
                                //         onClick={handleFileIconClick}
                                //       />
                                //     </div>
                                //   </div>
                                // ) : (
                                <img
                                  onClick={handleFileIconClick}
                                  className={`${StyleSheet.image}`}
                                  src={SyFileReference(data.md_name)}
                                  alt={data.md_name}
                                ></img>
                              ) : (
                                <SyIcons
                                  className={`${StyleSheet.image} ${StyleSheet.font_size_sm} d-flex justify-content-center`}
                                  iconStyle="outlined"
                                  iconType="icons"
                                  name={iconName(data.md_name)}
                                  onClick={handleFileIconClick}
                                />
                              )}
                            </td>

                            <td className={`fw-bolder col-3 `}>
                              <p className={`${StyleSheet.overflows}`}>
                                {data.md_title ? data.md_title : data.md_name}
                              </p>
                            </td>

                            <td className="opacity-25 col-5">
                              {data.created_at}
                            </td>

                            <td className="col-2">
                              {submitLoading[index] ? (
                                <SyLoadingIcon color={"loading "} />
                              ) : (
                                <SyIcons
                                  name={"delete"}
                                  toolTipTitle={t("delete")}
                                  value={{ data: data.md_id, index: index }}
                                  onClick={deleteConfirmPopup}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <SyconfirmPopup
                  onConfirmClick={deletFiles}
                  open={openconfirmPopup}
                  close={setopenconfirmPopup}
                />
              </div>
            )}

            {displayType === "grid" && (
              <div className=" d-flex row g-0 pb-3 col-12">
                {fileTable.map((data: any, index: number) => {
                  const handleFileIconClick = () => {
                    syOpenFile({
                      fileName: data.md_name,
                      authToken: authToken.authToken,
                      path: "mopus_docs",
                    });
                  };
                  const fileType = getfiletype(data.md_name);
                  return (
                    <div className="col-lg-2 col-md-3 px-1 col-sm-4 mt-2 col-6">
                      <div className="card border- 2 p-0 " key={data.md_id}>
                        <div>
                          {fileType === "image" ? (
                            //   <div
                            //     className={`${StyleSheet.videoContainer}`}
                            //   >
                            //     <video
                            //       src={SyFileReference(data.md_name)}
                            //       // poster="https://cdn-icons-png.flaticon.com/128/1179/1179069.png"
                            //       onClick={handleFileIconClick}
                            //       className={`${StyleSheet.images}`}
                            //     />
                            //     <div className={`${StyleSheet.overlayIconStyle}`}>
                            //       <SyIcons
                            //         className={StyleSheet.videoIcon}
                            //         name={"play_circle_filled"}
                            //         iconStyle="outlined"
                            //         iconType="icons"
                            //         onClick={handleFileIconClick}
                            //       />
                            //     </div>
                            //   </div>
                            // ) : (
                            <img
                              onClick={handleFileIconClick}
                              className={`${StyleSheet.images}`}
                              src={SyFileReference(data.md_name)}
                              alt={data.md_name}
                            ></img>
                          ) : (
                            <SyIcons
                              className={`${StyleSheet.images} ${StyleSheet.icon_size} d-flex justify-content-center`}
                              iconStyle="outlined"
                              iconType="icons"
                              name={iconName(data.md_name)}
                              onClick={handleFileIconClick}
                            />
                          )}

                          {submitLoading[index] ? (
                            <SyLoadingIcon
                              color={"loading position-absolute top-0 end-0 "}
                            />
                          ) : (
                            <SyIcons
                              className={`position-absolute top-0 end-0 opacity-50 ${StyleSheet.closeSpan}`}
                              value={{ data: data.md_id, index: index }}
                              onClick={deleteConfirmPopup}
                              name={"close"}
                              toolTipTitle={t("delete")}
                            />
                          )}
                        </div>

                        <hr className={`${StyleSheet.line} mt-0 mb-0 p-0`}></hr>
                        <p className={`fw-bolder px-2 mb-2  ellipsis w-100 `}>
                          {data.md_title ? data.md_title : data.md_name}
                        </p>
                        <small className="opacity-50 mx-2">
                          {data.created_at}
                        </small>
                      </div>
                    </div>
                  );
                })}

                <SyconfirmPopup
                  onConfirmClick={deletFiles}
                  open={openconfirmPopup}
                  close={setopenconfirmPopup}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const {
    mutate: deleteUploads,
    isLoading: deleteLoading,
    isError,
  } = useMutation({
    mutationFn: commentService.deleteFile,
    onSuccess(data, variables, context) {},
    onError(error: any, variables, context) {
      const message = error.message;
      SyToast(t(message), "bottom-right", "short", "error");
    },
  });

  return (
    <>
      <div className={`${StyleSheet.card} card border-0 p-3 `}>
        <div className="mt-1 d-flex row ">
          <div className="col">
            <p className={`${StyleSheet.Overview}`}>{t("file_overview")}</p>
          </div>
          {editTaskId && (
            <div className="col d-flex flex-row-reverse me-3">
              <SyIcons
                name={"view_list"}
                value={"list"}
                iconStyle="outlined"
                iconType="symbols"
                toolTipTitle={t("list_view")}
                className={` rowcol ms-1 pointer ${
                  displayType === "list" ? StyleSheet.activeSpan : ""
                }`}
                onClick={handleDisplayChange}
              />
              <SyIcons
                name={"grid_view"}
                value={"grid"}
                iconType="symbols"
                toolTipTitle={t("grid_view")}
                className={` rowcol  pointer ${
                  displayType === "grid" ? StyleSheet.activeSpan : ""
                }`}
                onClick={handleDisplayChange}
              />
            </div>
          )}
        </div>

        <div className="col ">
          {editTaskId && dataTable()}
          <SyFileDnD
            url={api}
            uploadStatus={uploadStatus}
            validationSize={50}
            deleteUploads={deleteUploads}
            autoSave={true}
          />
        </div>
      </div>
    </>
  );
};

export default DatataskManage;
