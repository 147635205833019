import { Draggable } from "react-beautiful-dnd";
import Stylesheet from "./style.module.scss";
import { useContext, useState } from "react";
import { AuthContext } from "../../../projects/simpler/context";
import SyChip from "../sy-chip";
import SyTaskdetailsPopup from "../../../projects/simpler/modules/simpler-module/pop-up/sy-taskdetails-popup";
import Popup from "../sy-model";
import {
  getNavDetails,
  updateMyDetailOnToggle,
} from "../../../projects/simpler/feature/simpler-slice";
import { useSelector } from "react-redux";
import Avathar from "../avathar/avathar";
import SyTooltip from "../sy-tooltip";

type SortableItemProps = {
  subItem: any;
  isAccordionOpen: boolean;
  status: boolean;
  index: number;
  onClickNavBtn: any;
  handleAccordionItemClick: (itemId: string) => void;
};

const SortableItem = ({
  subItem,
  isAccordionOpen,
  status,
  handleAccordionItemClick,
  index,
  onClickNavBtn,
}: SortableItemProps) => {
  const authToken = useContext(AuthContext);
  const navDetails = useSelector(getNavDetails);
  const [openTaskDetalPopup, setOpenTaskDetalPopup] = useState(false);
  const [dirtyValues, setDirtyValues] = useState<boolean>(false);
  const [taskIdData, setTaskIdData] = useState<any | null>(null);
  const [subTitleName, setSubTitleName] = useState("");
  const my_tasks = useSelector(updateMyDetailOnToggle);

  const taskNameClick = (data: any) => {
    setSubTitleName(data.task_name);
    setTaskIdData({
      projectId: data.task_project_id,
      taskId: data.task_id,
    });
    setOpenTaskDetalPopup(true);
  };
  const handlePopupClose = () => {
    if (dirtyValues) {
      onClickNavBtn(navDetails.path[navDetails.path.length - 1], {
        from: "popup",
        mine: my_tasks,
      });
    }
  };
  const updateDash = (data: boolean) => {
    return setDirtyValues(data);
  };
  const getTaskNameOnSucess = (title: string) => {
    setSubTitleName(title);
  };
  return (
    <Draggable
      key={subItem.task_id}
      draggableId={subItem.task_id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className={`rounded mb-2 ${isAccordionOpen ? " show" : ""}`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          data-dragging={snapshot.isDragging}
        >
          {status && !isAccordionOpen ? (
            <div className=" ms-2 me-2">
              <div
                className={`${Stylesheet.profiles} rounded p-1 `}
                onClick={() => handleAccordionItemClick(subItem.task_id)}
              >
                {/* <img
                src="https://img.freepik.com/free-photo/close-up-smiley-family-therapist_23-2149220329.jpg?w=900&t=st=1697772664~exp=1697773264~hmac=daf4fc70f1f4c9977335543778b68f9f840d00f6e7750607ebe0d83538f90e75"
                className={`${Stylesheet.img_fluid} rounded-top`}
                alt="img-Responsive"
              /> */}
                <div className={Stylesheet.title}>
                  <SyTooltip title={subItem.task_name}>
                    <span
                      className={`${Stylesheet.title} p-1 ms-1 mb-0 main-font`}
                    >
                      <a onClick={() => taskNameClick(subItem)}>
                        {subItem.task_name}
                      </a>
                    </span>
                  </SyTooltip>
                </div>
                <div className="d-flex flex-wrap">
                  {subItem?.tags?.map((tag: any, index: number) => {
                    return (
                      <div
                        className={`${Stylesheet.chipWidth} ms-2 mt-1 text-dark`}
                        key={index}
                      >
                        <SyChip
                          label={tag.pt_name}
                          color={tag.pt_color}
                          size="sm"
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  className={`${Stylesheet.avatar} ms-1 mb-1 mt-1 d-flex justify-content-start`}
                >
                  <Avathar
                    type="group"
                    avatarSet={subItem?.assignees}
                    max={6}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div
            className={`accordion-collapse collapse  ${
              isAccordionOpen ? "show" : ""
            }`}
            aria-labelledby={`heading - ${subItem.task_id}`}
          >
            <div
              className={`${Stylesheet.viewBar} ${Stylesheet.title} rounded p-1 ms-2 me-2`}
              onClick={() => handleAccordionItemClick(subItem.task_id)}
            >
              <SyTooltip title={subItem.task_name}>
                <span
                  className={`${Stylesheet.title} mt-1 ms-2 mb-3 rounded main-font`}
                  id={subItem.task_id}
                >
                  <a onClick={() => taskNameClick(subItem)}>
                    {subItem.task_name}
                  </a>
                </span>
              </SyTooltip>
            </div>
          </div>
          {openTaskDetalPopup && (
            <Popup
              open={openTaskDetalPopup}
              close={setOpenTaskDetalPopup}
              onClose={handlePopupClose}
              scroll="paper"
              showHeader={true}
              title={subTitleName}
              showCloseIcon={true}
              model_type={"alert"}
              maxWidth={"lg"}
              children={
                <SyTaskdetailsPopup
                  getTaskNameOnSucess={getTaskNameOnSucess}
                  oncloseclick={handlePopupClose}
                  pro_id={taskIdData.projectId}
                  task_id={taskIdData?.taskId}
                  updateDashboard={updateDash}
                />
              }
            />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default SortableItem;
