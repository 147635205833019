import { useEffect, useState } from "react";
import StyleSheet from "./style.module.scss";
import { InputForm } from "../../../../../../../shared/components/forms";
import { Controller, useForm } from "react-hook-form";
import commentService from "../../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "../../../schema-validation/schema-validation";
import * as yup from "yup";
import Dropdown from "../../../../../../../shared/components/forms/dropdown";
import DatePicker from "../../../../../../../shared/components/forms/date-picker/date-picker";
import SyTextEditor from "../../../../../../../shared/components/forms/sy-rich-text-edit/sy-text-editor";
import Sybutton from "../../../../../../../shared/components/sy-button/sy-button";
import dayjs from "dayjs";
import SyLoadingIcon from "../../../../../../../shared/components/sy-loading-icon";
import { useTranslation } from "react-i18next";
import Errormessage from "../../../../../../../shared/components/forms/errormessage/errormessage";
import { SyToast } from "../../../../../../../shared/utils/sy-toast";
import SyAsynSelect from "../../../../../../../shared/components/sy-asyn-select";
import { useSelector } from "react-redux";
import { getModuleDetails } from "../../../../../feature/common-slice";
import Popup from "../../../../../../../shared/components/sy-model";
import SyTaskdetailsPopup from "../../../pop-up/sy-taskdetails-popup";
import { transpileModule } from "typescript";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SyIcons } from "../../../../../../../shared/components";
import { loadingIconButton } from "../../../components/create-process-footer/create-process-footer";
import { getDateLocaleString } from "../../../../../../../shared/utils/dayjs";
import {
  asyncWildDatapayload,
  getAsyncWildData,
} from "../../../../../../../shared/utils";

type formValues = {
  parent_id: any;
  text: string;
  edittext: string;
  start_date: string;
  end_date: string;
  status: any;
  Priority: any;
  Effort: any;
  Label: any;
  Tags: any;
  Overall_task: any;
  email: any;
  showComments: any;
  docUpload: any;
  members: any;
};
type editvalues = {
  task_name: any;
  task_description: any;
  task_start_date: any;
  task_end_date: any;
  task_status: any;
  access_member: any;
  access_members: any;
};

const BasictaskManage = ({
  setFormSubmitted,
  getId,
  loadingSaveButton,
  saveNextButtonClick,
  saveButtonClick,
  itemId,
  status,
  priority,
  effort,
  editTaskId,
  parentId,
  subParent,
}: any) => {
  const { t } = useTranslation();
  const [SaveId, setSaveId] = useState("");
  const [saveButtonValue, setSaveButtonValue] = useState("");
  const [editDetails, setEditDetails] = useState<any>(null);
  const currentDate = Date();
  const lableDetails = useSelector(getModuleDetails);
  const defaultStatus = status.find((data: any, index: any) => index == "0");
  const defaultValues = {
    text: "",
    edittext: "",
    start_date: editDetails ? editDetails.task_start_date : currentDate,
    email: "",
    status: defaultStatus,
    Priority: "",
    effort: "",
  };
  const navigate = useNavigate();

  const [memberValue, setMemberValue] = useState(false);
  const tabChange = (id: string = "") => {
    const backMitarbeiter = document.getElementById(id);
    if (backMitarbeiter) {
      backMitarbeiter.click();
    }
  };
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
    control,
    watch,
    formState: { isValid, errors },
  } = useForm<formValues>({
    mode: "all",
    defaultValues: defaultValues,
    resolver: yupResolver<yup.AnyObjectSchema>(validationSchema),
  });

  const onSubmit = (Formvalue: formValues) => {};

  const { mutate, isLoading } = useMutation({
    mutationFn: commentService.SavetaskManage,
    onSuccess(data, variables, context) {
      const message = data.data.message;
      SyToast(t(message), "bottom-right", "short", "success");
      getTaskid(data);

      if (variables.next) {
        setFormSubmitted(true);

        setTimeout(() => {
          tabChange("section2");
        });
      } else {
        navigate(`/`);
      }
    },
    onError(error: any, variables, context) {
      const errorMessage = error.message;
      SyToast(t(errorMessage), "bottom-right", "short", "error");
    },
  });
  const getPayload = (data?: any) => {
    const formValue = getValues();

    return {
      fields: {
        custom: { test: null, file: null },
        default: {
          task_depends_on: null,
          task_description: formValue.edittext,
          task_end_date: formValue.end_date
            ? getDateLocaleString(formValue.end_date, "dateFormate")
            : null,

          task_mail: formValue.email,
          task_name: formValue.text,

          task_parent_id:
            parentId || editTaskId
              ? parentId
              : null || formValue.Overall_task
              ? formValue.Overall_task.task_id
              : null,
          task_priority: formValue.Priority
            ? formValue.Priority.opt_value
            : null,
          task_project_id: itemId,
          task_start_date: getDateLocaleString(
            formValue.start_date,
            "dateFormate"
          ),
          task_status: formValue.status ? formValue.status.ts_value : "",
          task_weight: formValue.Effort ? formValue.Effort.opt_value : null,
          access_members: formValue.members ? formValue.members : [],
          enable_file:
            formValue.members?.length > 0 ? formValue.docUpload : false,
          enable_comments:
            formValue.members?.length > 0 ? formValue.showComments : false,
          tam_access_type: formValue.members?.length > 0 ? 2 : 1,
        },
      },
      labels: formValue.Label
        ? formValue.Label.map((data: any) => data.l_id)
        : [],
      tags: formValue.Tags
        ? formValue.Tags.map((data: any) => data.pt_id)
        : null,
    };
  };
  const getTaskid = (data: any) => {
    const taskId = editTaskId ? editTaskId : data.data.id;
    setSaveId(data.data.id);
    getId(taskId);
    getSaveMutation({ itemId, taskId });
  };

  const { mutate: getSaveMutation } = useMutation({
    mutationFn: commentService.employeeSavetaskManage,
  });

  const save = (next: boolean) => {
    trigger();
    if (isValid) {
      const payload = getPayload();
      const edittaskManage = editTaskId ? editTaskId : SaveId;
      const getSaveId = edittaskManage ? edittaskManage : "";
      next ? setSaveButtonValue("") : setSaveButtonValue("save");

      mutate({ getSaveId, payload, next });
    } else {
      console.log("no data ");
    }
  };

  const { mutate: taskMutate, isLoading: taskLoading } = useMutation({
    mutationFn: commentService.OverAllTask,
    onSuccess(data, variables, context) {
      taskOptions(data);
    },
  });
  const [task, setTask] = useState();

  const taskOptions = (data: any) => {
    const optionsArray: any = [];
    data.data.forEach((data: any) => {
      optionsArray.push(data);
    });
    setTask(optionsArray);
  };

  const { mutate: labelMutate, isLoading: lableLoading } = useMutation({
    mutationFn: commentService.TaskLabel,
    onSuccess(data, variables, context) {
      labelOptions(data.data ? data : []);
    },
  });

  const [label, setlabel] = useState();

  const labelOptions = (data: any) => {
    const optionsArray: any = [];
    data?.data?.forEach((data: any) => {
      optionsArray.push(data);
    });
    setlabel(optionsArray);
  };

  const { mutate: tagsMutate, isLoading: tagLoading } = useMutation({
    mutationFn: commentService.getTags,
    onSuccess(data, variables, context) {
      tagsOptions(data.data ? data : null);
    },
  });

  const [tags, settags] = useState();

  const tagsOptions = (data: any) => {
    const optionsArray: any = [];
    data?.data?.forEach((data: any) => {
      optionsArray.push(data);
    });
    settags(optionsArray);
  };

  const TagHandleInput = (val: any) => {
    tagsMutate({ itemId, val });
  };

  const handleTagFocus = () => tagsMutate({ itemId, val: "" });
  const LableHandleInput = (val: any) => {
    const payload = { data: val };
    labelMutate(payload);
  };
  const handleLabelFocus = () => labelMutate({});
  const TaskHandleInput = (val: any) => {
    const payload = {
      only_parents: true,
      search: val,
    };

    taskMutate({ itemId, payload });
  };
  const handleTaskFocus = () => TaskHandleInput("");

  useEffect(() => {
    if (saveNextButtonClick) {
      document.getElementById("saveNextButton")?.click();
    }
  }, [saveNextButtonClick]);

  useEffect(() => {
    if (saveButtonClick) {
      document.getElementById("saveButton")?.click();
    }
  }, [saveButtonClick]);

  useEffect(() => {
    loadingSaveButton(isLoading);
  }, [isLoading]);

  const handleStatus = (data: any) => data.ts_name;
  const handlePriority = (data: any) => data.opt_name;
  const handleEffort = (data: any) => data.opt_name;
  const handleTask = (data: any) => data.task_name;
  const handleTags = (data: any) => data.pt_name;
  const handleLable = (data: any) => data.l_name;

  const {
    data,
    mutate: getTaskManageEdit,
    isLoading: editLoading,
  } = useMutation({
    mutationFn: commentService.getTaskManageEdit,
    onSuccess(data, variables, context) {
      setEditDetails(data.data);
      setFormValues(data.data);
    },
  });

  useEffect(() => {
    if (editTaskId) {
      getTaskManageEdit({ editTaskId, itemId });
      labelMutate(data);
      tagsMutate({ itemId });
    }
  }, []);

  const setFormValues = (data: any) => {
    // const members = data.access_members.map((data:any)=>{
    //   return data
    // })

    const defaultOption = status.find(
      (option: any) => option.ts_value === data.task_status
    );
    const defaultPriority = priority.find(
      (option: any) => option.opt_value === data.task_priority
    );

    const defaultEffort = effort.find(
      (option: any) => option.opt_value === data.task_weight
    );

    setValue("text", data.task_name);
    setValue("edittext", data.task_description);
    setValue("start_date", data.task_start_date);
    setValue("end_date", data.task_end_date);
    setValue("email", data.task_mail);
    setValue("status", defaultOption);
    setValue("Priority", defaultPriority);
    setValue("Effort", defaultEffort);
    setValue("Tags", data.tags ? data.tags.map((data: any) => data) : null);
    setValue(
      "Label",
      data?.labels?.map((data: any) => data)
    );
    setFormSubmitted(true);
    setValue("members", data.access_members);
    setValue(
      "showComments",
      data.access_members.length > 0
        ? data.enable_comments === "0"
          ? false
          : true
        : false
    );
    setValue(
      "docUpload",
      data.access_members.length > 0
        ? data.enable_file === "0"
          ? false
          : true
        : false
    );
  };


  const cancel = () => {
    navigate("/");
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value?.members?.length > 0) {
        setMemberValue(true);
      } else {
        setMemberValue(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      {editLoading ? (
        <div className={`${StyleSheet.load}`}>
          <SyLoadingIcon
            size="lg "
            color={`${StyleSheet.overlay} loading ms-5`}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card border-0 p-4 pe-2 ">
            <div
              className={`${StyleSheet.taskname} col-xl-4 col-lg-4 col-md-4 col-12 `}
              id={"pills-home"}
            >
              <InputForm
                placeholder={t("task_name")}
                type={"text"}
                {...register("text")}
                error={errors.text && errors.text.message}
              />
            </div>

            <div className={`col-xl-6 col-lg-6 col-md-6 col-12  mt-2`}>
              <Controller
                name={"edittext"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <SyTextEditor
                      value={value}
                      onChange={onChange}
                      register={register("edittext")}
                      name={"edittext"}
                      placeholder={t("task_description")}
                    />
                  );
                }}
              />
            </div>

            <div className="row d-flex mt-2 ">
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 pt-2 me-1">
                <Controller
                  name={"start_date"}
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => {
                    const handleChange = (val: any) => {
                      onChange(val);
                      trigger("end_date");
                    };
                    return (
                      <DatePicker
                        {...onBlur}
                        Placeholder={t("start_date")}
                        selectedDate={handleChange}
                        date={value}
                        errorMessage={errors?.start_date?.message}
                      />
                    );
                  }}
                />

                {/* {errors.start_date && (
                  <Errormessage message={errors.start_date.message} />
                )} */}
              </div>

              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 pt-2 me-1">
                <Controller
                  name={"end_date"}
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => {
                    return (
                      <DatePicker
                        {...onBlur}
                        Placeholder={t("end_date")}
                        selectedDate={onChange}
                        date={value ? value : null}
                        errorMessage={errors?.end_date?.message}
                      />
                    );
                  }}
                />

                {/* {errors.end_date && (
                  <Errormessage message={errors.end_date.message} />
                )} */}
              </div>

              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 pt-2 me-1">
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Dropdown
                        options={status}
                        placeholder={t("task_status")}
                        enableSearchBox={false}
                        loading={false}
                        handleFilter={() => void null}
                        handleChange={onChange}
                        multiple={false}
                        value={value}
                        getoptlabel={handleStatus}
                        clearIcon={true}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 pt-2 me-1">
                <Controller
                  name="Priority"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Dropdown
                        options={priority}
                        placeholder={t("priority")}
                        enableSearchBox={false}
                        loading={false}
                        handleFilter={() => void null}
                        handleChange={onChange}
                        multiple={false}
                        getoptlabel={handlePriority}
                        value={value}
                      />
                    );
                  }}
                />
              </div>

              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 pt-2 me-1">
                <Controller
                  name="Effort"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Dropdown
                        options={effort}
                        placeholder={t("task_weight")}
                        enableSearchBox={false}
                        loading={false}
                        handleFilter={() => void null}
                        handleChange={onChange}
                        multiple={false}
                        value={value}
                        getoptlabel={handleEffort}
                      />
                    );
                  }}
                />
              </div>

              <div className="mt-2">
                <div className="d-flex flex-wrap">
                  <div
                    className={`col-xl-5 col-lg-5 col-md-5 col-12 mt-3 me-4`}
                  >
                    <InputForm
                      type={"text"}
                      placeholder={t("email_for_status_update")}
                      {...register("email")}
                      error={
                        errors.email && "please_enter_a_correct_email_address"
                      }
                    />
                    <p className={`note-text-style text-black-50 pt-1`}>
                      {t("notes")} :{" "}
                      {t(
                        "please_for_different_mail_id_use_comma_to_separate_and_empty_space_is_not_allowed"
                      )}
                    </p>
                  </div>

                  <div
                    className={`col-xl-5 col-lg-5 col-md-5 col-12 mt-3 ${
                      editTaskId || parentId ? "d-none" : ""
                    }`}
                  >
                    <Controller
                      name="Overall_task"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Dropdown
                            options={task}
                            placeholder={t("parent_task")}
                            enableSearchBox={true}
                            loading={taskLoading}
                            handleFilter={TaskHandleInput}
                            handleChange={onChange}
                            multiple={false}
                            getoptlabel={handleTask}
                            onFocus={handleTaskFocus}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  <div
                    className={`col-xl-5 col-lg-5 col-md-5 col-12 mt-3 me-4`}
                  >
                    <Controller
                      name="Tags"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Dropdown
                            options={tags}
                            placeholder={t("tags")}
                            enableSearchBox={true}
                            loading={tagLoading}
                            handleFilter={TagHandleInput}
                            onFocus={handleTagFocus}
                            handleChange={onChange}
                            multiple={true}
                            getoptlabel={handleTags}
                            value={value}
                          />
                        );
                      }}
                    />
                  </div>
                  {lableDetails.label_mgmt_mod && (
                    <div className={`col-xl-5 col-lg-5 col-md-5 col-12 mt-3`}>
                      <Controller
                        name="Label"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Dropdown
                              options={label}
                              placeholder={t("label")}
                              enableSearchBox={true}
                              loading={lableLoading}
                              handleFilter={LableHandleInput}
                              handleChange={onChange}
                              onFocus={handleLabelFocus}
                              multiple={true}
                              value={value}
                              getoptlabel={handleLable}
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className="mt-3 col-xl-5 col-lg-5 col-md-5 col-12">
                  <SyAsynSelect
                    name={"members"}
                    placeholder={t("members")}
                    control={control}
                    optionValue={null}
                    multiple={true}
                    defaultVal={false}
                    SERVICE_API_FN={commentService.getEmployeeTags}
                    onSuccessAsyncSearch={getAsyncWildData}
                    payload={asyncWildDatapayload.user}
                  />
                </div>

                {memberValue && (
                  <div className="col-xl-5 col-lg-5 col-md-5 col-12 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="ms-2 ">
                        <InputForm
                          type={"checkbox"}
                          placeholder={t("allow_comments")}
                          {...register("showComments")}
                        />
                      </div>
                      <div className="">
                        <InputForm
                          type={"checkbox"}
                          placeholder={t("allow_doc_upload")}
                          {...register("docUpload")}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={` p-3 pb-0 pe-4 d-flex justify-content-end `}>
              <Sybutton
                type={"button"}
                className={"Secondarybutton me-2 d-none d-sm-block"}
                size={"sm"}
                label={t("cancel")}
                onBtnClick={cancel}
              />
              <Sybutton
                type={"button"}
                className={
                  "iconButton me-2 d-flex align-items-center d-block d-sm-none"
                }
                size={"sm"}
                onBtnClick={cancel}
                label={<SyIcons name="keyboard_backspace" />}
              />
              <Sybutton
                type={"submit"}
                className={"primarybutton d-none d-sm-block"}
                size={"sm"}
                value={"save"}
                id={
                  saveButtonClick
                    ? "saveButton"
                    : saveNextButtonClick
                    ? "saveNextButton"
                    : "save"
                }
                label={t("save")}
                Loadings={saveButtonValue == "save" ? isLoading : false}
                disabled={isLoading ? true : false}
                onBtnClick={() => {
                  saveButtonClick
                    ? save(false)
                    : saveNextButtonClick
                    ? save(true)
                    : save(false);
                }}
              />
              <Sybutton
                type={"button"}
                className={
                  "iconButton d-flex align-items-center d-block d-sm-none"
                }
                size={"sm"}
                value={"save"}
                id={
                  saveButtonClick
                    ? "saveButton"
                    : saveNextButtonClick
                    ? "saveNextButton"
                    : "save"
                }
                onBtnClick={() => {
                  saveButtonClick
                    ? save(false)
                    : saveNextButtonClick
                    ? save(true)
                    : save(false);
                }}
                label={
                  saveButtonValue === "save" && isLoading ? (
                    loadingIconButton
                  ) : (
                    <SyIcons name="save" />
                  )
                }
                disabled={!!isLoading}
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default BasictaskManage;
export const Previous_page = () => window.history.back();
