import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styleSheet from "./style.module.scss";
import AddLimitedAccess from "./add-limited-access";
import Popup from "../../../../../../shared/components/sy-model";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../service/comment-service";
import { SyIcons, SyLoadingIcon } from "../../../../../../shared/components";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import { SyToast } from "../../../../../../shared/utils/sy-toast";

interface Props {
  taskId: number | null;
}

const LimitedAccess = (props: Props) => {
  const { taskId } = props;
  const { t } = useTranslation();
  const [limitedAcessPopup, setLimitedAcessPopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deletableId, setDeletableId] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState<any>([]);
  const [limitedAccessList, setLimitedAccessList] = useState<any>([]);

  const { mutate: getLimitedAccess, isLoading: limitedLoading } = useMutation({
    mutationFn: commentService.getLimitedAccess,
    onSuccess(data) {
      setLimitedAccessList(data.data.limited_role);
    },
  });
  const getAccessMembers = () => {
    getLimitedAccess(taskId);
  };

  useEffect(() => {
    getAccessMembers();
  }, []);

  const handlePopupButtonClick = () => {
    setLimitedAcessPopup(true);
  };

  const handleOpenPopup = (val: boolean) => {
    setLimitedAcessPopup(val);
  };

  const { mutate: deleteLimitedAccess, isLoading } = useMutation({
    mutationFn: commentService.deleteLimitedAccess,
    onSuccess(data, variables) {
      getAccessMembers();
      const message = data.data.message;
      SyToast(t(message), "bottom-right", "short", "success");
      const indexval = [...submitLoading];
      indexval[variables.index] = false;
      setSubmitLoading(indexval);
    },
    onError(error: any) {
      const errorMessage = error.message;
      SyToast(t(errorMessage), "bottom-right", "short", "error");
    },
  });
  const deleteClick = () => {
    deleteLimitedAccess(deletableId);
    const submitLoadingVal = [...submitLoading];
    submitLoadingVal[deletableId.index] = true;
    setSubmitLoading(submitLoadingVal);
  };

  const TbodyData = ({ data, index }: any) => {
    const deletePopup = (data: any) => {
      setConfirmPopup(true);
      setDeletableId({
        proId: taskId,
        id: data.id,
        type_id: data.type_id,
        index,
      });
    };

    return (
      <tr>
        <td scope="row" className={"opacity-75 col-10"}>
          {data.name}
        </td>
        <td scope="row">
          {submitLoading[index] && isLoading ? (
            <SyLoadingIcon />
          ) : (
            <SyIcons
              name={"delete_outline"}
              className={"opacity-75"}
              onClick={deletePopup}
              value={data}
            />
          )}
        </td>
      </tr>
    );
  };

  const Tbody = (list: any) => {
    return (
      <tbody>
        {limitedLoading ? (
          <td className="d-flex justify-content-center ps-5 text-black-50">
            {t("loading")}...
          </td>
        ) : list.list?.length !== 0 ? (
          list.list?.map((data: any, index: any) => {
            return <TbodyData data={data} index={index} />;
          })
        ) : (
          <td className="d-flex justify-content-center  ps-5 text-black-50">
            {t("no_data_found")}
          </td>
        )}
      </tbody>
    );
  };

  return (
    <div className={`${styleSheet.innertab_style}  rounded p-3`}>
      <SyconfirmPopup
        open={confirmPopup}
        close={setConfirmPopup}
        onConfirmClick={deleteClick}
      />
      <div className="d-flex flex-wrap justify-content-between">
        <p className={`${styleSheet.title}`}>{t("limited_access")}</p>
      </div>
      <div
        className={`d-flex justify-content-between ${styleSheet.element_padding}`}
      >
        <button
          className={`${styleSheet.buttonPrimary} btn`}
          type="button"
          onClick={handlePopupButtonClick}
        >
          {t("add")}
        </button>
        {limitedAcessPopup && (
          <Popup
            model_type={"responsive"}
            showHeader={limitedAcessPopup}
            open={limitedAcessPopup}
            close={setLimitedAcessPopup}
            children={
              <AddLimitedAccess
                limitedAcessPopup={handleOpenPopup}
                taskId={taskId}
                limitedAccessList={limitedAccessList}
                getAccessMembers={getAccessMembers}
              />
            }
            title={t("add")}
          />
        )}
      </div>
      <div className={` ${styleSheet.elementm_padding} sy_horizontal_scroll`}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="col-10">
                {t("name")}
              </th>

              <th scope="col" className="col-2">
                {t("action")}
              </th>
            </tr>
          </thead>

          <Tbody list={limitedAccessList} />
        </table>
      </div>
    </div>
  );
};

export default LimitedAccess;
