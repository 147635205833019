import http from "../../../../../axios";
import { getData } from "../../../../../shared/utils";

class getservice {
  async getCommentList(data: any) {
    const CHANGE_API = await getData("node");
    const apiUrl = CHANGE_API === "1" ? "simpler_api_url" : "main_url";
    let url =
      CHANGE_API === "1"
        ? `simpler/latestcomments`
        : `public/modules/mopus/main/latestcomments`;
    return http(apiUrl).post(
      `${url}/${data.data?.masterId}/${data.data?.id}`,
      data.getCommentPayload
    );
  }
  getLoadmore(payload: any) {
    return http().post(
      `public/modules/mopus/main/getreply/${payload.itemId}/${payload.taskId}`,
      payload.payload
    );
  }
  saveReplyComment(data: any) {
    return http().post(
      `public/modules/mopus/main/savecomment`,
      data.savePayload
    );
  }
  deleteComment(data: any) {
    return http().delete(`public/modules/mopus/main/comment/${data}`);
  }

  getProcessLabel(data: any) {
    return http().post("public/modules/label_mgmt/main/all", data);
  }

  getprofilelist(data: any) {
    return http().get(
      `public/modules/mopus/main/task_assignees/${data.itemId}/${data.taskId}`
    );
  }

  //--globalusers--//

  getGlobalUsers(data: any) {
    return http().get(
      `public/modules/mopus/main/globalUserName/${data.data.masterId}/${data.data.id}`
    );
  }


  getAccessByProject(data: any) {
    return http().get(
      `public/modules/mopus/main/accessByProject/${data}`
    );
  }
  getListPage(payload: any) {
    return http().post("/public/modules/mopus/main/list", payload);
  }

  getArchivedList(payload: any) {
    return http().get(`/public/modules/mopus/main/archivetask/${payload.data}`);
  }

  getUnarchiveList(payload: any) {
    return http().get(
      `/public/modules/mopus/main/unarchivetask/${payload.data}`
    );
  }

  deleteListItem(itemId: any) {
    return http().delete(`public/modules/mopus/main/delete/${itemId}`);
  }

  getProgressDetails(data: any) {
    return http().get(
      `/public/modules/mopus/main/get/${data.itemId}/${data.taskId}`
    );
  }

  getProgressnullDetails(data: any) {
    return http().get(`/public/modules/mopus/main/prerequisite/${data}/null`);
  }

  async getGlobalTasklist(data: any) {
    const CHANGE_API = await getData("node");
    const apiUrl = CHANGE_API === "1" ? "simpler_api_url" : "main_url";
    let url =
      CHANGE_API === "1"
        ? `simpler/tasks/`
        : `public/modules/mopus/main/alltask/`;

    return http(apiUrl).post(
      `${url}${data?.data?.masterId}/${data?.data?.id}`,
      data.taskpayload
    );
  }

  // ---nodeApi---//

  getAllTaskList(data: any) {
    return http("sy_url").post(
      `/public/modules/mopus/main/allTask/${data?.data?.masterId}/${data?.data?.id}`,
      data.taskpayload
    );
  }
  // ------------//

  getHierarchyHistory(data: any) {
    const payload: any = { ...data };
    delete payload.mine;
    return http().post(`public/modules/mopus/main/hierarical_history`, payload);
  }

  getTasklist(data: any) {
    return http().post(
      `public/modules/mopus/main/tasklist/${data.proId}/${data.taskId}`,
      data.taskpayload
    );
  }

  saveNewTask(data: any) {
    return http().post(
      `public/modules/mopus/main/save_project/${data.taskid}`,
      data.payload
    );
  }

  getTaskAssignees(taskId: any) {
    return http().get(`public/modules/mopus/main/pro_assignees/${taskId}`);
  }

  getTaskStatus(taskId: any) {
    return http().post(
      `public/modules/mopus/main/list_pro_task_status/${taskId}`
    );
  }

  // deleteStatus(data: any) {
  //   return http().delete(`public/modules/mopus/main/del_status/${data.id}`);
  // }

  deleteStatus(data: any) {
    return http("simpler_api_url").delete(`simpler/delete/status/${data.pro_id}/${data.id}`);
  }
  
  updateStatus(data: any) {
    return http("simpler_api_url").post(`simpler/upsert/status/${data.pro_id}/${data.id}`,data.payload);
  }

  reorderStatus(data: any) {
    return http().post(
      `public/modules/mopus/main/order_status/${data.taskId}`,
      data.payload
    );
  }

  getTaskRoles(taskId: any) {
    return http().get(`public/modules/mopus/main/get_pro_roles/${taskId}`);
  }

  deleteRole(data: any) {
    return http().delete(`public/modules/mopus/main/del_pro_role/${data.id}`);
  }

  getProcessTags(taskId: any) {
    return http().post(`public/modules/mopus/main/list_pro_tags/${taskId}`);
  }

  // deleteTag(data: any) {
  //   return http().delete(`public/modules/mopus/main/del_tags/${data.id}`);
  // }
  tagDelete(data: any) {
    return http("simpler_api_url").delete(`simpler/delete/tag/${data.pro_id}/${data.id}`);
  }
  updateTag(data: any) {
    return http("simpler_api_url").post(`simpler/upsert/tag/${data.pro_id}/${data.id}`,data.payload);
  }


  reorderTags(data: any) {
    return http().post(
      `public/modules/mopus/main/order_tags/${data.taskId}`,
      data.payload
    );
  }

  //--task_manage--//

  getRoles(data: any) {
    return http().get(`public/modules/mopus/main/get_pro_roles/${data}`);
  }

  getStatus(data: any) {
    return http().get(`public/modules/mopus/main/prerequisite/${data}/null`);
  }

  SavetaskManage(data: any) {
    return http().post(
      `public/modules/mopus/main/savetask${
        data.getSaveId ? `/${data.getSaveId}` : ""
      }`,
      data.payload
    );
  }

  //task details popup
  getTaskComments(data: any) {
    return http().get(
      `public/modules/mopus/main/comment/${data.proId}/${data.taskId}`
    );
  }

  employeeSavetaskManage(data: any) {
    return http().get(
      `public/modules/mopus/main/task_assignees/${data.itemId}/${data.taskid}`
    );
  }

  dataSavetaskManage(data: any) {
    return http().post(
      `public/modules/mopus/main/docs/${data.itemId}/${data.taskid}`,
      data.payload
    );
  }

  OverAllTask(data: any) {
    return http().post(
      `public/modules/mopus/main/get_tasks/${data.itemId}`,
      data.payload
    );
  }
  getTaskTags(data: any) {
    return http().post(
      `public/modules/mopus/main/alltags/${data.data.masterId}/${data.data.id}`,
      data.tagsPayload
    );
  }

  getTags(data: any) {
    return http().get(
      `public/modules/mopus/main/get_tags/${data.itemId}/${
        data.val ? data.val : ""
      }`
    );
  }

  TaskLabel(data: any) {
    return http().post("/public/modules/label_mgmt/main/all", data);
  }

  //--taskManageEdit

  getTaskManageEdit(data: any) {
    return http().get(
      `public/modules/mopus/main/task/${data.itemId}/${data.editTaskId}`
    );
  }

  //---taskManageDelete

  deleteTask(data: any) {
    return http().delete(`public/modules/mopus/main/deletetask/${data.data}`);
  }

  //--employee--//

  getAddEmployee(getAddPayload: any) {
    return http().post("/public/support/wildcard/", getAddPayload);
  }

  getEmployeeTags(data: any) {
    return http().post(
      `public/support/wildcard/${data.inputvalue}`,
      data.payload
    );
  }

  getTaskMembers(data: any) {
    return http().get(
      `public/modules/mopus/main/assigned_pro/${data.proId}/${data.inputvalue}`
    );
  }
  getPortfolioUsers(data: any) {
    return http().get(
      `public/modules/mopus/main/get_portfolioUsers/${data?.data?.masterId}/${
        data?.data?.id
      }/${data?.value ? data.value : ""}`
    );
  }

  // getRefineDetails(){
  //   return http().get("public/modules/mopus/main/getRefineDetails");
  // }

  saveEmployeeTags(data: any) {
    return http().post(
      `public/modules/mopus/main/save_multiTask_assignees/${data.itemId}/${data.TaskId}/`,
      data.payload
    );
  }
  getSaveEmployeeTagsObj(data: any) {
    return http().get(
      `public/modules/mopus/main/task_assignees/${data.itemId}/${data.taskId}/`
    );
  }
  DeleteEmployeeTags(data: any) {
    return http().delete(
      `public/modules/mopus/main/del_assignees/${data.taskId}/${data.id}/${data.typeId}`
    );
  }
  getDeleteEmployeeIdTags(data: any) {
    return http().get(
      `public/modules/mopus/main/task_assignees/${data.saveTaskId}`
    );
  }

  //--fileupload--//
  uploadFile(data: any) {
    return http().post("public/filemanage/upload", data);
  }
  //====
  uploadFiles(data: any) {
    return http().post("public/filemanage/upload", data.formData);
  }
  //=====
  getFileSave(data: any) {
    return http().post(
      `public/modules/mopus/main/savedoc/${data.itemId}/${data.taskId}`,
      data.payload
    );
  }

  //=====
  getUploadSave(data: any) {
    return http().post(
      `public/modules/mopus/main/fileUpload/${data.itemId}/${data.taskId}`,
      data.formData
    );
  }

  getFileDoc(data: any) {
    return http().post(
      `public/modules/mopus/main/docs/${data.itemId}/${data.taskId}`,
      data.payload
    );
  }

  deleteFile(data: any) {
    return http().delete(`public/modules/mopus/main/deldoc/${data.data}`);
  }

  //--Assignee list--//
  getAssigneeTags(data: any) {
    return http().post(
      `public/support/wildcard/${data.val ? data.val : ""}`,
      data.payload
    );
  }

  deleteTaskAssignee(data: any) {
    return http().delete(
      `public/modules/mopus/main/del_pro_assignee/${data.id}`
    );
  }

  deleteTaskType(data: any) {
    return http().delete(
      `public/modules/mopus/main/delete_roletype/${data.taskid}/${data.userid}/${data.typeid}`
    );
  }

  saveTaskAssignee(data: any) {
    return http().post(
      // `public/modules/mopus/main/save_pro_assignees/${data.id}/`,
      `public/modules/mopus/main/save_multi_assignees/${data.id}/`,
      data.payload
    );
  }
  saveLimitedAccess(data: any) {
    return http().post(
      `public/modules/mopus/main/limitedAccessSave/${data.id}`,
      data.payload
    );
  }
  getLimitedAccess(data: any) {
    return http().get(`public/modules/mopus/main/limitedAccess/${data}`);
  }
  deleteLimitedAccess(data: any) {
    return http().delete(
      `public/modules/mopus/main/delLimitedAssignee/${data.proId}/${data.id}/${data.type_id}`
    );
  }

  //--Status list--//
  saveTaskStatus(data: any) {
    return http().post(
      `public/modules/mopus/main/save_task_status/${data.taskid}/${data.statusid}`,
      data.payload
    );
  }

  //--Roles list--//
  saveTaskRole(data: any) {
    return http().post(
      `public/modules/mopus/main/save_pro_role/${data.roleid}`,
      data.payload
    );
  }

  //--Task list--//
  // getTaskListUsers(data: any) {
  //   return http().get(
  //     `public/modules/mopus/main/search_users/${
  //       data.data ? data.data.id : data.proId
  //     }/${data.val ? data.val : ""}`
  //   );
  // }

  getTaskListUsers(data: any) {
    return http().get(
      `public/modules/mopus/main/search_globaluser/${
        data.data ? data.data.masterId : data.proId
      }/${data.data ? data.data.id : ""}/${data.val ? data.val : ""}`
    );
  }

  getAssignedProUsers(data: any) {
    return http().get(
      `public/modules/mopus/main/assigned_pro/${data.data}/${
        data.val ? data.val : ""
      }`
    );
  }

  TaskStatus(data: any) {
    return http().get(
      `public/modules/mopus/main/task_status/${
        data.data ? data.data.id : data.proId
      }`
    );
  }

  //--tags list--//
  saveTaskTag(data: any) {
    return http().post(
      `/public/modules/mopus/main/save_pro_tags/${data.taskid}/${data.tagid}`,
      data.payload
    );
  }
  //--edit process--//
  editProcess(id: any) {
    return http().get(`public/modules/mopus/main/get/${id}`);
  }

  //--For Graph--//
  getProgressGraphData(data: any) {
    return http().post(
      `public/modules/mopus/main/graph/${data.data.masterId}/${data.data.id}`,
      data.graphPayload
    );
  }
  //---
  savePortfolio(data: any) {
    return http().post(
      `public/modules/mopus/main/${
        data.masterId == "2" ? "programsave" : "portfoliosave"
      }/${data.editId}`,
      data.payload
    );
  }

  getHierarchyList(data: any) {
    return http().post(`public/modules/mopus/main/hierarical`, data);
  }

  //---Doc-List---//

  async getDocList(data: any) {
    const CHANGE_API = await getData("node");
    const apiUrl = CHANGE_API === "1" ? "simpler_api_url" : "main_url";
    let url =
      CHANGE_API === "1"
        ? `simpler/documents`
        : `public/modules/mopus/main/doclist`;
    return http(apiUrl).post(
      `${url}/${data.data.masterId}/${data.data.id}`,
      data.docPayload
    );
  }

  getPortfolio(data: any) {
    return http().get(
      `public/modules/mopus/main/${
        data.masterId == "2" ? "program" : "portfolio"
      }/${data.id}`
    );
  }

  portfolioDelete(data: any) {
    return http().delete(
      `public/modules/mopus/main/${
        data.masterId == "2"
          ? "programs"
          : data.masterId == "1"
          ? "portfolios"
          : "project"
      }/${
        data.masterId == "1" || data.masterId == "2" ? data.id : data.path_id
      }`
    );
  }

  getPortfolioList(data: any) {
    return http().get(
      `public/modules/mopus/main/portfolioList/${data ? data : ""}`
    );
  }
  getProgramList(data: any) {
    return http().get(`public/modules/mopus/main/programList/${data}`);
  }

  //--status save--//

  statusSave(data: any) {
    return http().post(
      `public/modules/mopus/main/status_save/${data.task_id}/${data.task_value}`,
      data.payload
    );
  }
  //--checkbox--//

  savecheckBox(data: any) {
    return http().post(
      `public/modules/mopus/main/update_task/${data.taskId}`,
      data.payload
    );
  }
  updateEmail(data: any) {
    return http().post(
      `public/modules/mopus/main/updateEmail/${data.taskId}`,
      data.payload
    );
  }
}

export default new getservice();
