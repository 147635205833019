
import config from "../../environments/env-config";

export const MAIN_API_URL= config?.api_url;
export const ASSET_API_URL= config?.asset_api_url;
export const SY_API_URL= config?.sys_api_url;
export const SIMPLER_API_URL= config?.simpler_api_url;




