import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";

export default function SyTextEditor(props: any) {
  const { placeholder, name, defaultValue, ...rest } = props;
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],

      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = ["bold", "italic", "underline", "list", "bullet"];

  const [quillContent, setQuillContent] = useState(props.value || "");

  const handleQuillContentChange = (content: any) => {
    setQuillContent(content);
    props.onChange(content);
  };

  return (
    <div className="form-group  ">
      <ReactQuill
        defaultValue={defaultValue}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        value={quillContent}
        
        name={name}
        onChange={handleQuillContentChange}
        {...rest}
      />
    </div>
  );
}
