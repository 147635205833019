import axios from "axios";
import { MAIN_API_URL, ASSET_API_URL, SY_API_URL,SIMPLER_API_URL } from "../shared/constants";
import { timezone } from "../shared/utils/time-zone";

import config from "../environments/env-config";
import { SyToast } from "../shared/utils/sy-toast";
import { t } from "i18next";
import { syWindowNavigate } from "../shared/utils";
import { getCookie } from "../shared/utils/cookie";

interface API_URL_TYPES {
  main_url: string
  sy_url: string
  asset_url: string
  simpler_api_url: string
}

const API_URLS: API_URL_TYPES = {
  main_url: MAIN_API_URL,
  sy_url: SY_API_URL,
  simpler_api_url:SIMPLER_API_URL,
  asset_url: ASSET_API_URL
}



const http = (url: string = 'main_url') => {
  const http = axios.create({ baseURL: API_URLS[url as keyof API_URL_TYPES] })


  http.interceptors.request.use(
    (config) => {
      console.log(config)
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      interceptor(error);

      return Promise.reject(error);
    }
  );

  const authToken = getCookie(config.auth_token);
  if (authToken) setAuthToken(http, authToken);
return http
};


export const setAuthToken = (http: any, token: string) => {
  http.defaults.headers["Authorization"] = "Bearer " + token;
  http.defaults.headers["Timezone"] = timezone();
};

const interceptor = (error: any) => {
  if (error.response.status == "400") {
    if (error.response.data.message == "invalid_id") {
      SyToast(t(error.response.data.message), "bottom-right", "short", "error");
    }
    if (error.response.data.message == "m_opus_mod_not_activated") {
      SyToast(t(error.response.data.message), "bottom-right", "short", "error");
      syWindowNavigate(config.app_url);
    }
  }
};

export default http;
