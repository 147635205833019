import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { FOOTER_CONFIG } from "./model-config";
import Sybutton from "../sy-button/sy-button";
import StyleSheet from "./style.module.scss";
import SyIcons from "../sy-screen/sy-screen";

type Props = {
  open: boolean;
  close?: any;
  onClose?: () => void;
  Padding?: boolean;
  model_type: "alert" | "responsive" | "scroll";
  showHeader?: boolean;
  footerType?: string;
  children?: React.ReactNode;
  title?: any;
  showCloseIcon?: boolean;
  fullScreen?: boolean;
  scroll?: "body" | "paper";
  style?: object;
  primaryBtnName?: string;
  secondaryBtnName?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  onClickOverlay?: boolean;
};

const Popup = (props: Props) => {
  const {
    open = false,
    close = false,
    onClose = () => void 0,
    model_type = "alert",
    footerType = "none",
    showHeader = true,
    fullScreen = false,
    Padding = false,
    title = "popup",
    children = "",
    showCloseIcon = true,
    scroll = "paper",
    style = {
      width: "100%",
    },
    maxWidth = "sm",
    onClickOverlay = true,
  } = props;

  const mod_types: Record<Props["model_type"], string> = {
    alert: "alert-dialog-title",
    responsive: "responsive-dialog-title",
    scroll: "scroll-dialog-title",
  };

  const handleClose = () => {
    close(false);
    onClose();
  };
  const onClick = () => {
    close(false);
    onClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      scroll={scroll}
      maxWidth={maxWidth ? maxWidth : false}
      onClose={onClickOverlay ? handleClose : void null}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": { ...style },
        },
      }}
    >
      <div className="d-flex justify-content-between align-items-center border">
        {showHeader ? (
          <div className={`${StyleSheet.title} ms-2`}>
            <DialogTitle
              sx={{
                maxHeight: "50px",
                lineHeight: 0,
                padding: 0,
              }}
            >
            <p className="title-font">{title}</p>  
            </DialogTitle>
          </div>
        ) : null}
        {showCloseIcon ? (
          <div className="me-2">
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[900],
              }}
            >
              <SyIcons name="close" />
            </IconButton>
          </div>
        ) : null}
      </div>
      <DialogContent
        className={`${Padding ? StyleSheet.popup : ""}`}
        aria-labelledby={mod_types[model_type]}
      >
        <DialogContentText component={"span"} id={mod_types[model_type]}>
          {children}
        </DialogContentText>
      </DialogContent>
      {FOOTER_CONFIG[footerType].showFooter && (
        <DialogActions>
          {FOOTER_CONFIG[footerType].cta.map((item) => (
            <div className="border">
              <Sybutton
                type={"button"}
                value={item.isSubmit}
                className={item.btn_color}
                size={""}
                label={item.text}
                onBtnClick={onClick}
              />
            </div>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Popup;
