
export const storeData = async (key:string, value:string) => {
  try {
     localStorage.setItem(key, value);
  } catch (e) {
    // saving error
  }
};

export const storeDataJson = async (key:string, value:string) => {
  try {
    const jsonValue = JSON.stringify(value);
     localStorage.setItem(key, jsonValue);
  } catch (e) {
    // saving error
  }
};

export const getData = async (key:string) => {
  try {
    return await localStorage.getItem(key);
  } catch (e) {
    // error reading value
  }
};

export const getJsonData = async (key:string) => {
    try {
      const jsonValue  = await localStorage.getItem(key);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      // error reading value
    }
  };

export const clearAll = async () => {
  try {
     localStorage.clear();
  } catch (e) {
    // clear error
  }
};
