import Stylesheet from "./style.module.scss";

type ProcessTableHeaderProps = {
  header: { key: string; label: string };
  handleSort: (key: string) => void;
  sorting: { active: string; direction: "asc" | "desc" | ""};
};

const ProcessTableHeader = ({
  header,
  handleSort,
  sorting,
}: ProcessTableHeaderProps) => {
  return (
    <th className={`${Stylesheet.theader}`} scope="col">
      {header.label}
      {sorting.active === header.key &&
        sorting.direction === "asc" && (
          <span
            className={`${Stylesheet.small_icon} material-icons-round pointer asc`}
            onClick={() => handleSort(header.key)}
          >
            south
          </span>
        )}
      {sorting.active === header.key &&
        sorting.direction === "desc" && (
          <span
            className={`material-icons-round ${Stylesheet.small_icon} pointer desc`}
            onClick={() => handleSort(header.key)}
          >
            north
          </span>
        )}
      {sorting.active !== header.key && (
        <span
          className={`material-icons-round ${Stylesheet.small_icon} pointer desc`}
          onClick={() => handleSort(header.key)}
        >
          north
        </span>
      )}
    </th>
  );
};



export default ProcessTableHeader;
