import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import styleSheet from "./style.module.scss";
import commentService from "../../service/comment-service";
import Syinnerhtml from "../../../../../../shared/components/sy-innerhtml";
import dayjs from "dayjs";
import SyChip from "../../../../../../shared/components/sy-chip";
import { CommentCard } from "../../components/comments/comment-card";
import SyTextEditor from "../../../../../../shared/components/forms/sy-rich-text-edit";
import { Controller, useForm } from "react-hook-form";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import TaskListScreen from "../../components/task-list";
import {
  SyIcons,
  SyLoadingIcon,
  SyTooltip,
} from "../../../../../../shared/components";
import { AuthContext } from "../../../../context";
import Popup from "../../../../../../shared/components/sy-model";
import AddEmployeePopup from "../simper-task-manage-popup/add-employee-popup";
import FileUploadPopup from "../simper-task-manage-popup/file-upload-popup";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import { displayOverlayLoading } from "../../../../feature/common-slice";
import { useDispatch } from "react-redux";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { useTranslation } from "react-i18next";
import Sy_Search from "../../../../../../shared/components/sy-search/sy-search";
import Sy_CustomFooter from "../../../../../../shared/components/sy-model/sy-customfooter";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import getCommentData from "../../service/comment-service";
import {
  FILE_ICON_NAME,
  getfiletype,
  syOpenFile,
} from "../../../../../../shared/utils/sy-file-utils";
import SyFileDnD from "../../../../../../shared/components/sy-file-dnd";
import { getDateLocaleString } from "../../../../../../shared/utils/dayjs";
import { InputForm } from "../../../../../../shared/components/forms";
import SyAsynSelect from "../../../../../../shared/components/sy-asyn-select";
import Avathar from "../../../../../../shared/components/avathar/avathar";
import Doclist from "./doclist";
import { TagSchema } from "../../schema-validation/schema-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Errormessage from "../../../../../../shared/components/forms/errormessage";
import { useDebouncedCallback } from "use-debounce";
import {
  getAsyncWildData,
  asyncWildDatapayload,
  setQueryParams,
} from "../../../../../../shared/utils";
import { getDashBoardParamPayload } from "../../../../../../shared/utils/helper";
import {
  getArchivedValue,
  getNavDetails,
  getTaskListData,
  getViewControlDetail,
  updateMyDetailOnToggle,
} from "../../../../feature/simpler-slice";
import { useSelector } from "react-redux";

type Props = {
  pro_id: any;
  task_id: any;
  mode?: "subTaskDetails";
  oncloseclick: () => void;
  updateDashboard?: (data: boolean) => void;
  popupclose?: any;
  getTaskNameOnSucess?: (name: string) => void;
  taskValue?: any;
};

const SyTaskdetailsPopup = (props: Props) => {
  const {
    oncloseclick,
    mode,
    pro_id,
    updateDashboard,
    taskValue,
    task_id,
    getTaskNameOnSucess,
  } = props;

  const viewControlDetail = useSelector(getViewControlDetail);

  const my_task = useSelector(updateMyDetailOnToggle);

  const navDetails = useSelector(getNavDetails);

  const isArchive = useSelector(getArchivedValue);

  const [isPopupOpen, setisPopupOpen] = useState(false);
  const [commentsResponse, setCommentsResponse] = useState<any>();
  const [enableEdit, setEnableEdit] = useState(false);
  const [employeeEdit, setEmployeeEdit] = useState([]);
  const [submitLoading, setSubmitLoading] = useState<any>([]);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deletableid, setdeletableid] = useState<any | null>(null);
  const [commentsData, setCommentsData] = useState<any>();
  const [updateDash, setUpdateDash] = useState<boolean>(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [assigneelist, setassigneelist] = useState<Array<object>>([]);
  const [memberValue, setMemberValue] = useState(false);
  const [statusDoubleClick, setStatusDoubleClick] = useState<
    "dropDownView" | "chipView"
  >("chipView");
  const [status, setStatus] = useState<Array<object>>([]);
  const [statusValue, setStatusValue] = useState<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    register,
    watch,
    formState: { isValid, errors },
  } = useForm({});

  const {
    control: emailControl,
    watch: emailWatch,
    setValue: emailSetValue,
    formState: { errors: emailErrors },
  } = useForm({
    mode: "all",
    resolver: yupResolver<yup.AnyObjectSchema>(TagSchema),
  });

  const getTaskName = (name: string) => {
    if (getTaskNameOnSucess) {
      getTaskNameOnSucess(name);
    } else {
      void null;
    }
  };

  const {
    data: taskData,
    mutate,
    isLoading,
  } = useMutation({
    mutationFn: commentService.getTaskManageEdit,
    onSuccess(data, variables, context) {
      setValues(data.data);
      getTaskName(data?.data?.task_name);
    },
  });

  const { mutate: getCommentsMutate, isLoading: getCommentsLoading } =
    useMutation({
      mutationFn: commentService.getTaskComments,
      onSuccess(data, variables, context) {
        setCommentsResponse(data?.data ? data?.data : []);
      },
    });

  const {
    data: assigneeResponse,
    mutate: getAssigneeMutate,
    isLoading: empLoading,
  } = useMutation({
    mutationFn: commentService.getprofilelist,
    onSuccess(data, variables) {
      setassigneelist(data.data);
      if (variables.variables) {
        const indexval = [...submitLoading];
        indexval[variables.variables.index] = false;
        setSubmitLoading(indexval);
      }
    },
  });

  const documentpayload = { search: "", filter: { fileType: null } };

  const {
    data: documentResponse,
    mutate: getDocsMutate,
    isLoading: docLoading,
  } = useMutation({
    mutationFn: commentService.getFileDoc,
    onSuccess(data) {},
  });

  //saveReplyComment
  const { mutate: saveCommentMutate, isLoading: saveCommentsLoading } =
    useMutation({
      mutationFn: commentService.saveReplyComment,
      onSuccess() {
        getCommentsMutate({ proId, taskId });
        setNullVal();
        setUpdateDash(true);
        setOpenEditPopup(false);
      },
    });

  const { data: roles, mutate: getRoles } = useMutation({
    mutationFn: commentService.getRoles,
  });

  const { mutate: DeletEmployee } = useMutation({
    mutationFn: commentService.DeleteEmployeeTags,
    onSuccess(data, variables) {
      const taskId = editTaskId ? editTaskId : taskid;
      getAssigneeMutate({ variables, itemId, taskId });
      const index = deletableid.deletableindex;
      assigneelist.splice(index, 1);
      setUpdateDash(true);
    },
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: commentService.deleteComment,
    onSuccess(data, variables) {
      const updateDelete = commentsResponse?.filter((val: any) => {
        return val.mco_id !== variables;
      });
      setCommentsResponse(updateDelete);
      // getCommentsMutate({ proId, taskId });
      SyToast(t("deleted_successfully"), "bottom-right", "short", "success");
    },
  });

  const { mutate: getStatus, isLoading: statusLoading } = useMutation({
    mutationFn: commentService.getStatus,
    onSuccess(data, variables, context) {
      setStatus(data.data.status_options);
    },
  });

  const proId = pro_id,
    taskId = task_id,
    taskid = task_id,
    editTaskId = task_id,
    itemId = pro_id;
  useEffect(() => {
    // let payload = documentpayload;
    getStatus(itemId);
    mutate({ itemId, editTaskId });
    getCommentsMutate({ proId, taskId });
    getAssigneeMutate({ itemId, taskId });

    // getDocsMutate({ itemId, taskId, payload });

    const selectedTask = {
      project: pro_id,
      task: task_id,
    };

    setQueryParams(
      getDashBoardParamPayload(
        navDetails.path,
        my_task,
        viewControlDetail,
        isArchive,
        selectedTask
      )
    );
    getRoles(pro_id);
  }, []);

  useEffect(() => {
    dispatch(displayOverlayLoading(isLoading ? (mode ? 1 : 2) : 0));
  }, [isLoading]);

  useEffect(() => {
    if (updateDashboard) {
      updateDashboard(updateDash);
    }
  }, [updateDash]);

  const getDate = (date: any) => {
    let formatedDate = dayjs(date).format("DD.MM.YYYY");
    return formatedDate;
  };

  const setNullVal = () => setValue("mco_text", "");

  const onSubmit = () => {
    let formvalues = getValues();
    formvalues.mco_text = formvalues?.mco_text?.replace(
      /<p[^>]*>(&nbsp;|\s+|<br\s*\/?>)*<\/p>/g,
      ""
    );

    const savePayload = {
      mco_pro_id: pro_id,
      mco_task_id: task_id,
      mco_text: formvalues.mco_text,
    };
    formvalues.mco_text && isValid
      ? saveCommentMutate({ savePayload })
      : SyToast(t("please_enter_some_comment"), "top-right", "medium", "error");
  };

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) =>

  //   )
  //   return () => subscription.unsubscribe()
  // }, [watch])

  const onSaveComment = () => {
    let formvalues = getValues();
    const savePayload = {
      mco_id: commentsData.mco_id,
      mco_text: formvalues.comment_text,
    };
    saveCommentMutate({ savePayload });
  };

  const handleAddAssigneeClick = () => {
    if (!disable) {
      setisPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setisPopupOpen(false);
    setEnableEdit(false);
  };

  const handleFormSubmit = (formValues: any) => {};

  const handleDeleteEmployee = (data: any, index: any) => {
    const deletedata = { deletabledata: data, deletableindex: index };
    setdeletableid(deletedata);
    setConfirmPopup(true);
  };

  const handleConfirmClick = () => {
    const submitLoadingVal = [...submitLoading];
    submitLoadingVal[deletableid.deletableindex] = true;
    setSubmitLoading(submitLoadingVal);

    const id = deletableid.deletabledata.id;
    const taskId = editTaskId ? editTaskId : taskid;
    const typeId = deletableid.deletabledata.type_id;
    const index = deletableid.deletableindex;
    DeletEmployee({ taskId, id, typeId, index });
  };

  const handleEditEmployee = (data: any) => {
    setEnableEdit(true);
    setisPopupOpen(true);
    setEmployeeEdit(data);
    setUpdateDash(true);
    document.getElementById("EmployeePopup")?.click();
  };

  const handleformSubmit = (val: any) => {
    if (val?.fileType) {
      let payload = { ...documentpayload };
      payload.filter.fileType = val.fileType;
    }
  };

  const handlestatusfilter = () => {};

  const file_types = [
    "document_file",
    "image_file",
    "excel_file",
    "ppt_file",
    "audio_file",
    "video_file",
  ];

  const onCancel = () => {
    setOpenEditPopup(false);
  };

  const editContent = () => {
    return (
      <div onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name={"comment_text"}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <SyTextEditor
                  value={value}
                  onChange={onChange}
                  name={"mco_text"}
                />
              );
            }}
          />
        </div>
        <div className="d-flex justify-content-end mt-4 pt-1">
          <>
            <Sybutton
              className={`Secondarybutton me-3`}
              label={t("cancel")}
              type={"reset"}
              onBtnClick={onCancel}
              size={"sm"}
            />
            <Sybutton
              type={"submit"}
              className={"primarybutton"}
              size={"sm"}
              label={t("send")}
              onBtnClick={onSaveComment}
              disabled={saveCommentsLoading}
              Loadings={saveCommentsLoading}
            />
          </>
        </div>
      </div>
    );
  };
  const filter_content = () => {
    return (
      <>
        <div className="mb-3">
          <Controller
            name="fileType"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Dropdown
                  options={file_types}
                  placeholder={t("file_type")}
                  enableSearchBox={false}
                  loading={false}
                  handleFilter={handlestatusfilter}
                  handleChange={onChange}
                  multiple={false}
                  getoptlabel={(val: any) => val}
                />
              );
            }}
          />
        </div>
        <Sy_CustomFooter
          handleSubmit={handleSubmit}
          handleformSubmit={handleformSubmit}
        />
      </>
    );
  };

  const updateList = () => getAssigneeMutate({ itemId, taskId });

  const isTableUpdated = () => setUpdateDash(true);

  const handleEmployeeAltered = () => setUpdateDash(true);

  const { mutate: checkBoxMutation, isLoading: checkBoxLoadinf } = useMutation({
    mutationFn: commentService.savecheckBox,
    onSuccess(data, variables) {},
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type == "change" && name != "mco_text") {
        const payload = {
          members: value.members ? value.members : [],
          enable_file: value?.members.length > 0 ? value.docUpload : false,
          enable_comments:
            value?.members.length > 0 ? value.showComments : false,
          access_type: value?.members.length > 0 ? 2 : 1,
        };

        checkBoxMutation({ taskId, payload });
      }
      if (value?.members?.length > 0) {
        setMemberValue(true);
      } else {
        setMemberValue(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const { mutate: updateEmail, isLoading: emailLoading } = useMutation({
    mutationFn: commentService.updateEmail,
    onSuccess(data, variables) {},
  });

  const debouncedHandleInputChange = useDebouncedCallback((payload: any) => {
    updateEmail({ taskId, payload });
  }, 800);

  useEffect(() => {
    const subscription = emailWatch((value, { name, type }) => {
      setTimeout(() => {
        if (!emailErrors.hasOwnProperty("pt_notify_emails")) {
          const payload = {
            task_mail: value.pt_notify_emails,
          };

          debouncedHandleInputChange(payload);
        }
      });
    });

    return () => subscription.unsubscribe();
  }, [!emailErrors.hasOwnProperty("pt_notify_emails")]);

  const statusval = status?.find((val: any) => {
    return val.ts_value == taskData?.data.ts_value;
  });

  const setValues = (data: any) => {
    const file = data?.enable_file === "1" ? true : false;
    const comment = data?.enable_comments === "1" ? true : false;
    setValue("members", data?.access_members ? data.access_members : []);
    setValue("showComments", comment);
    setValue("docUpload", file);
    emailSetValue("pt_notify_emails", data?.task_mail);
  };
  const getCommentDetails = (data: any) => {
    onClickMenuButton({ data });
    setCommentsData(data.data);
  };
  const onClickMenuButton = (data: any) => {
    data?.data.val?.label === "delete" &&
      deleteMutate(data?.data?.data?.mco_id);
    data?.data.val?.label === "edit" && setOpenEditPopup(true);
    setValue("comment_text", data?.data?.data.mco_text);
  };




  const disable =
  taskData?.data.main_access.portfolio_access ||
  taskData?.data.main_access.program_acess||
  taskData?.data.main_access.project_access||
  taskData?.data.mopus_admin
      ? false
      : true;




  const statusChange = () => {
    if (!taskData?.data.has_children && !disable) {
      setStatusDoubleClick("dropDownView");
    }
  };

  const handleStatus = (data: any) => data?.ts_name;
  const searchvalue = {};

  const getStatusValue = (data: any) => {
    const statusval = status.find((val: any) => {
      return val.ts_name == data.ts_name;
    });
    setStatusValue(statusval);
    statusSave({
      task_id: task_id,
      task_value: data?.ts_value,
      payload: searchvalue,
      status: statusval,
    });
    setUpdateDash(true);
  };

  const { data: updateStatus, mutate: statusSave } = useMutation({
    mutationFn: getCommentData.statusSave,
    onSuccess() {
      setStatusDoubleClick("chipView");
    },
  });
  const closeStatusDropDown = () => setStatusDoubleClick("chipView");

  const ddstyle = { width: "130px", height: "100%" };

  return (
    <div
      className={`text-dark ${styleSheet.dialog_content} `}
      onClick={closeStatusDropDown}
    >
      <SyconfirmPopup
        open={confirmPopup}
        close={setConfirmPopup}
        onConfirmClick={handleConfirmClick}
      />

      {/* <div
        className={`d-flex justify-content-start  ${styleSheet.title_style} `}
      >
        <p className={`title-font ${styleSheet.title}`}>
          {taskData?.data.task_name}
        </p> */}
      {/* <SyIcons
          iconStyle="round"
          iconType="icons"
          name={"close"}
          className={"pointer"}
          onClick={oncloseclick}
        /> */}
      {/* </div> */}
      <div className={`row pb-1 ${styleSheet.top_div_style}`}>
        <div className={` col-md-6 col-xm-12  ${styleSheet.v_line_right}`}>
          <div className="text-black-50  d-flex     align-items-baseline">
            <p className="small-font">
              {t("id")} - {taskData?.data.task_id}
            </p>
            <SyTooltip
              title={
                !disable && !taskData?.data?.has_children
                  ? t("double_click_to_change")
                  : ""
              }
            >
              <span
                className={`ps-2 ${
                  !taskData?.data.has_children ? `pointer` : null
                }`}
                onDoubleClick={statusChange}
                onClick={(event) => event.stopPropagation()}
              >
                {statusDoubleClick === "chipView" ? (
                  <SyChip
                    label={
                      updateStatus
                        ? updateStatus.data.task_status?.ts_name
                        : taskData?.data.ts_name
                    }
                    color={
                      updateStatus
                        ? updateStatus.data.task_status?.ts_color
                        : taskData?.data.ts_color
                    }
                    size="sm"
                  />
                ) : (
                  <Dropdown
                    options={status}
                    placeholder={t("sta...")}
                    enableSearchBox={false}
                    loading={false}
                    handleFilter={() => void null}
                    handleChange={getStatusValue}
                    multiple={false}
                    controlledVal={statusValue ? statusValue : statusval}
                    style={ddstyle}
                    getoptlabel={handleStatus}
                    clearIcon={true}
                    onBlur={closeStatusDropDown}
                  />
                )}
              </span>
            </SyTooltip>
          </div>
          <div
            className={`d-flex xxx sy_horizontal_scroll ${styleSheet.tag_div_style}`}
          >
            <span className="d-flex">
              {taskData?.data.tags
                ? taskData.data.tags.map((data: any, index: any) => (
                    <span className="pe-1" key={index}>
                      <SyChip
                        label={data.pt_name}
                        color={data.pt_color}
                        size="sm"
                      />
                    </span>
                  ))
                : null}
            </span>
          </div>
          <div className="row g-0 lh-sm">
            <div className={`col-6 `}>
              <div className="text-black-50">
                <p className="sub-font">{t("task_start_date")}</p>
              </div>
              <div>
                <p className="sub-font">
                  {getDate(taskData?.data.task_start_date)}
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="text-black-50">
                <p className="sub-font">{t("created_by")}</p>
              </div>
              <div>
                <p className="sub-font">
                  {taskData?.data?.created_user_data?.name}
                </p>
              </div>
            </div>
          </div>
          {!isLoading && (
            <div className={`mt-2`}>
              <Controller
                name="pt_notify_emails"
                control={emailControl}
                render={({ field }) => {
                  return (
                    <div onChange={field.onChange}>
                      <InputForm
                        {...field.onChange}
                        type={"text"}
                        onChange={field.onChange}
                        value={field.value}
                        placeholder={t("email_for_status_update")}
                        disable={disable}
                      />
                      <div className={`${styleSheet.emailNote}`}>
                        <p
                          className={`small-font text-black-50  ${styleSheet.note}`}
                        >
                          {t("notes")} :{" "}
                          {t(
                            "please_for_different_mail_id_use_comma_to_separate_and_empty_space_is_not_allowed"
                          )}
                        </p>
                      </div>
                      {emailErrors.pt_notify_emails && (
                        <p className="d-flex justify-content-end note-text-style small-font text-black-50  mt-1">
                          <Errormessage
                            message={"please_enter_a_correct_email_address"}
                          />
                        </p>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          )}

          {!isLoading && (
            <div className="mt-3">
              <SyAsynSelect
                name={"members"}
                disabled={disable}
                placeholder={t("members")}
                control={control}
                optionValue={null}
                multiple={true}
                defaultVal={false}
                limitItems={2}
                onSuccessAsyncSearch={getAsyncWildData}
                payload={asyncWildDatapayload.user}
                SERVICE_API_FN={commentService.getEmployeeTags}
              />
            </div>
          )}
          {memberValue && (
            <div className=" col-md-8 col-12 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="ms-2">
                  <InputForm
                    {...register("showComments")}
                    type={"checkbox"}
                    placeholder={t("allow_comments")}
                    disable={disable}
                  />
                </div>
                <div className="ms-3 sub-font">
                  <InputForm
                    {...register("docUpload")}
                    type={"checkbox"}
                    placeholder={t("allow_doc_upload")}
                    disable={disable}
                    
                  />
                </div>
              </div>
            </div>
          )}
          <div className={` pt-2 `}>
            <div className="text-black-50 d-flex justify-content-between mb-2">
              <p className="sub-font">{t("task_employee")}</p>
              <SyIcons
                name="add"
                iconStyle="outlined"
                toolTipTitle={`${disable ? "" : "add"}`}
                iconType="icons"
                className={`${
                  disable
                    ? "text-black-50 pointer mr-5 opacity-50"
                    : "text-black-50 pointer mr-5"
                }`}
                onClick={handleAddAssigneeClick}
              />
              {isPopupOpen && (
                <Popup
                  model_type={"responsive"}
                  open={isPopupOpen}
                  close={setisPopupOpen}
                  showHeader={isPopupOpen}
                  onClose={handleClosePopup}
                  title={enableEdit ? t("edit") : t("add")}
                >
                  {isPopupOpen ? (
                    <AddEmployeePopup
                      onClose={handleClosePopup}
                      onSubmit={handleFormSubmit}
                      taskid={taskId}
                      setEmployeeObj={setassigneelist}
                      employeeEdit={employeeEdit}
                      enableEdit={enableEdit}
                      role={roles?.data}
                      itemId={proId}
                      updateList={updateList}
                      editEmployee={assigneelist}
                      editTaskId={editTaskId}
                      employeeObj={assigneelist}
                      employeeAltered={handleEmployeeAltered}
                    />
                  ) : null}
                </Popup>
              )}
              {/* </div> */}
            </div>

            <div
              className={`sy_horizontal_scroll ${styleSheet.list_div_style}`}
            >
              {assigneelist.length === 0 && !empLoading ? (
                <div className="d-flex justify-content-center">
                  <p className="sub-font text-black-50">{t("no_data_found")}</p>
                </div>
              ) : (
                assigneelist.map((data: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={`d-flex justify-content-between pb-2 ${styleSheet.list_style}`}
                    >
                      <div className="col-4">
                        {" "}
                        <Avathar profile={data} showName={true} />
                      </div>
                      <div className="col-4">
                        {data.roles.map((data: any, index: any) => {
                          return (
                            <span key={index} className="ms-1 mb-1">
                              <SyChip
                                label={data.tr_name}
                                color={data.tr_color}
                                size="sm"
                              />
                            </span>
                          );
                        })}
                      </div>

                      <div
                        className={`${
                          disable ? "d-none" : ""
                        } col-2 d-flex justify-content-end `}
                      >
                        <SyIcons
                          name={"create"}
                          className={`material-icons-outlined pointer text-black-50 pb-0 ${styleSheet.icon_style}`}
                          onClick={() => handleEditEmployee(data)}
                          value={null}
                        />
                        {submitLoading[index] ? (
                          <div className="px-1">
                            <SyLoadingIcon color={`loading mb-1`} size={"xm"} />
                          </div>
                        ) : (
                          <>
                            <SyIcons
                              name={"close"}
                              className={`material-icons-round pointer text-black-50 pb-0 ${styleSheet.icon_style}`}
                              onClick={() => handleDeleteEmployee(data, index)}
                              value={null}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              )}
              {empLoading ? (
                <div className="d-flex justify-content-center">
                  {t("loading")}...
                </div>
              ) : (
                void 0
              )}
            </div>
          </div>
          {/* {(taskData?.data.access_member &&
            taskData?.data.enable_comments === "1") ||
          taskData?.data.mopus_admin === true ||
          !taskData?.data.access_member ? (
            <Doclist
              task_id={task_id}
              itemId={pro_id}
              filter_content={filter_content()}
              taskData={taskData}
              updateDashboard={updateDashboard}
            />
          ) : (
            void null
          )} */}
          {!disable ||
          taskData?.data.limited_Access ||
          !taskData?.data.access_member ||
          (taskData?.data.access_member &&
            taskData?.data.enable_comments === "1") ? (
            <Doclist
              task_id={task_id}
              itemId={pro_id}
              filter_content={filter_content()}
              taskData={taskData}
              updateDashboard={updateDashboard}
              disable={disable}
            />
          ) : (
            void null
          )}
        </div>
        <div className={` col-md-6 col-xm-12  `}>
          {taskData?.data.task_description ? (
            <>
              <div className="text-black-50">
                <p className="sub-font">{t("task_description")}</p>
              </div>
              <div>
                <Syinnerhtml
                  comments={taskData?.data.task_description}
                  className={"p-0 lh-sm"}
                />
              </div>
            </>
          ) : null}

          {getCommentsLoading ? (
            <div className="d-flex justify-content-center">
              <SyLoadingIcon />
            </div>
          ) : (
            <div
              className={`${styleSheet.comment_div_style} sy_vertical_scroll mt-2`}
            >
              {commentsResponse?.map((data: any, index: any) => {
                return (
                  <span key={index}>
                    <CommentCard data={data} getComment={getCommentDetails} taskData={taskData?.data}/>
                  </span>
                );
              })}
            </div>
          )}
          {!disable ||
          taskData?.data.limited_Access ||
          (taskData?.data.access_member &&
            taskData?.data.enable_comments === "1") ? (
            <div onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Controller
                  name={"mco_text"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <SyTextEditor
                        value={value}
                        onChange={onChange}
                        name={"mco_text"}
                      />
                    );
                  }}
                />
              </div>
              <div className="d-flex justify-content-end pt-1">
                <Sybutton
                  type={"submit"}
                  className={"primarybutton"}
                  size={"sm"}
                  label={t("send")}
                  onBtnClick={onSubmit}
                  disabled={saveCommentsLoading}
                  Loadings={saveCommentsLoading}
                />
              </div>
            </div>
          ) : (
            <div className="mt-2">
              <Doclist
                task_id={task_id}
                itemId={pro_id}
                filter_content={filter_content()}
                taskData={taskData}
                updateDashboard={updateDashboard}
                disable={disable}
              />
            </div>
          )}
        </div>
      </div>

      {openEditPopup && (
        <Popup
          open={openEditPopup}
          close={setOpenEditPopup}
          showHeader={true}
          title={"edit_comment"}
          children={editContent()}
          model_type={"responsive"}
        />
      )}

      <div
        className={` ${mode ? "d-none" : `${styleSheet.tasklist_div_style}`} `}
      >
        <div>
          <TaskListScreen
            disable={disable}
            task_id={task_id}
            mode="subTaskList"
            pro_id={pro_id}
            tableupdated={isTableUpdated}
            childern={taskData?.data.has_children}
          />
        </div>
      </div>
    </div>
  );
};

export default SyTaskdetailsPopup;
