export const button: any = {
  details: [
    {
      navBar: true,
      id: "section1",
      label: "general",
    },
    {
      navBar: true,
      id: "section2",
      label: "pro_employee",
    },
    {
      navBar: true,
      id: "section3",
      label: "files",
    },
  ],
};
