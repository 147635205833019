


export const syWindowReload = () => {
    window.location.reload();
  }   

export const syWindowNavigate = (data:any) => {
    window.location.href = data
  }  

export const syGetPathName=()=>{
 return window.location.pathname
}
