import React from "react";
import Stylesheet from "./style.module.scss";

type Props = {
  comments: any;
  className?: any;
};

const makeLinksClickable = (comments: any) => {
  const linkRegex =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

  if (linkRegex.test(comments)) {
    return comments.replace(/<p>(.*?)<\/p>/g, (match: any, group: any) =>
      group.replace(
        linkRegex,
        (url: any) => `<a href="${url}" target="_blank">${url}</a>`
      )
    );
  } else {
    return comments;
  }
};

const Syinnerhtml = ({ comments, className }: Props) => {
  const formattedComments = makeLinksClickable(comments);
  return (
    <div className={`${className ? className : "ms-1"}`}>
      <small
        className={`${Stylesheet.command_font} text-break sub-font`}
        dangerouslySetInnerHTML={{ __html: formattedComments }}
      ></small>
    </div>
  );
};

export default Syinnerhtml;
