export const initialState = {
    isAppReady: false,
    authToken: null,
}

export function authReducer(state:any, action:any) {
    console.log(state,action)
    switch (action.type) {
        case 'SIGN_IN':
            return { ...state, authToken: action.jwt_token };
        case 'SIGN_OUT':
            return { ...state, authToken: null };
        case 'IS_READY':
            return { ...state, ...action.data };
        default:
            return state
    }
}
