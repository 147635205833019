import styleSheet from "./style.module.scss";
import { primaryColor } from "../../constants/theme";
import { getTextColor } from "../../utils/theming";

interface ColoredLabelprops {
  label: string | undefined | null;
  color?: string | undefined | null;
  actionIcon?: boolean;
  type?:'square'|'round'
  size?: "sm" | "md"|'lg';
  onClick?: () => void;
}

const SyChip = (props: ColoredLabelprops) => {
  const { label, color, actionIcon, onClick, size = "md",type='square' } = props,
    COLOR = color ? color : primaryColor,
    chipStyle = {
      backgroundColor: `${COLOR}`,
      color: `${getTextColor(COLOR)}`,
    };

  return (
    <>
      {label?<div className={`${styleSheet.badge} ${styleSheet[size]} ${styleSheet[type]} `} style={chipStyle}>
        <p
          className={`align-items-center ellipsis ${
            styleSheet.font_size
          } m-0`}
        >
          {label}
        </p>
        {actionIcon ? (
          <span
            className={`material-icons-round d-flex align-items-center  pointer select-none ${styleSheet.closeIcon}`}
            onClick={onClick}
          >
            cancel
          </span>
        ) : null}
      </div>: void null}
    </>
  );
};

export default SyChip;
