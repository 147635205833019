import { createSlice } from "@reduxjs/toolkit";

interface RootState{
    docList:any;
    docListLoading:boolean;
}

const initialState:RootState = {
  docList: "",
  docListLoading: false,
};

export const docSlice = createSlice({
  name: "doc",
  initialState,
  reducers: {
    setdocList: (state, { payload }) => {
      state.docList = payload;
    state.docListLoading=false
    },
    docListLoading: (state, { payload }) => {
        state.docList = [];
        state.docListLoading=true;
    },
    resetDocSlice:()=>initialState
  },
});

export const { setdocList,docListLoading,resetDocSlice } = docSlice.actions;

export const getdocList=(state:any)=>state.docReducer.docList;

export const getdocLoading=(state:any)=>state.docReducer.docListLoading;

export default docSlice.reducer;
