export const buttons : any ={
details: [
    {
      navBar: true,
      id: "general",
      label: "general",
     
    },
    {
      navBar: true,
      id: "employee",
      label: "employee",

    },
    {
      navBar: true,
      id: "limitedAccess",
      label: "limited_access",

 
    },
    
    {
      navBar: true,
      id: "status",
      label: "create_status"

   
    },
    {
      navBar: true,
      id: "roles&tags",
      label: "roles_tags",

   
    },
  ]
};