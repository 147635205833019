import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: '',
  company_details:'',
  module_details:'',
  token:''
};

export const userDetailsSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    setTokenReducer:(state, { payload })=>{
        state.token = payload;
    },
    setUserDetailReducer: (state, { payload }) => {
      state.userDetails = payload;
    },
    setCompanyDetailReducer: (state, { payload }) => {
      state.company_details = payload;
    },
    setModuleDetailReducer:(state,{payload})=>{
      state.module_details =payload;
    }
  },
});

export const { setUserDetailReducer,setCompanyDetailReducer,setTokenReducer,setModuleDetailReducer } = userDetailsSlice.actions;

export const getuserDetailReducer = (state: any) => state.userDetailReducer.userDetails
export const getJwtToken = (state: any) => state.userDetailReducer.token
export const getCompanyDetails = (state: any) => state.userDetailReducer.company_details
export const getModuleDetails = (state: any) => state.userDetailReducer.module_details


export default userDetailsSlice.reducer;
