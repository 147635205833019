// import React, { useEffect, useState } from "react";
import KanbanBoard from "../../../../../../shared/components/kanban";
import kanbanService from "../../../../../../shared/components/kanban/service/kanban-service";
import { useMutation } from "@tanstack/react-query";
// import { useParams } from "react-router-dom";
import {useSelector} from "react-redux"
import { getkanbanList,getKanbanLoading,setKanbanList } from "../../../../feature/simpler-slice/kanban-slice";
import { getNavDetails, updateMyDetailOnToggle } from "../../../../feature/simpler-slice";
 
type Props = {
  updateGraph:any;
  onClickBtn:any;
  updateKanban:any;
};

const MyKanbanScreen = ({updateGraph,onClickBtn,updateKanban}: Props) => {
  // const { itemId ,taskId } = useParams();
  // const dispatch = useDispatch()
  // const [dataList, setDataList] = useState<any[]>([]);
  const navDetails = useSelector(getNavDetails);
  const my_tasks = useSelector(updateMyDetailOnToggle)
  const kanbanLoading = useSelector(getKanbanLoading)
  const data = navDetails.path[navDetails.path.length - 1];
  
  // const kanbanPayload = {
    //   search: "",
    //   filter: { assignees: [], tags: [], created_by: [] },
    // };
    
    const graphPayload = {
      mine: my_tasks,
    };
    const kanbanPayload = {
      search: "",
      filter: { assignees: [], tags: [], created_by: [] },
      mine: my_tasks,
    };
  const KanbanList = useSelector(getkanbanList);


  const { mutate: putKanbanStatus } = useMutation({
    mutationFn: kanbanService.putKanbanTask,
    onSuccess(){
      updateGraph({data,graphPayload})
      updateKanban({data,kanbanPayload})
    }
  });


  // useEffect(() => {
  //   setDataList(myKanbandata?.data?.data || []);
  // }, [myKanbandata]);

  // useEffect(() => {
  //   const taskParameter = taskId || ''; 
  //   getMyKanbanDetails({ payload, itemId, taskId: taskParameter });
  // }, []); 

  const saveStatus = (data: any) => {
    const { taskId, payload } = data;
    putKanbanStatus({ taskId, payload });
  };

  return (
    <>
      <KanbanBoard
        kanbanList={KanbanList}
        saveStatus={saveStatus}
        type="mykanban"
        Loading={kanbanLoading}
        onClickBtn={onClickBtn}
        statusData={KanbanList}
      />
    </>
  );
};

export default MyKanbanScreen;
