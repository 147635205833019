import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DURATION = {
  short: 2000,
  medium: 4000,
  long: 6000,
};

export const POSITION = {
  "bottom-center": toast.POSITION.BOTTOM_CENTER,
  "bottom-left": toast.POSITION.BOTTOM_LEFT,
  "bottom-right": toast.POSITION.BOTTOM_RIGHT,
  "top-center": toast.POSITION.TOP_CENTER,
  "top-left": toast.POSITION.TOP_LEFT,
  "top-right": toast.POSITION.TOP_RIGHT,
};

export const SyToast = (
  message: string,
  position:
    | "bottom-center"
    | "bottom-left"
    | "bottom-right"
    | "top-center"
    | "top-left"
    | "top-right" = "top-right",
  duration: "short" | "medium" | "long" = "medium",
  type: "info" | "success" | "warning" | "error" | "default" = "success"
) => {
  const toast_position = POSITION[position];
  const toast_duration = DURATION[duration];
  message ? toast(message, {
    position: toast_position,
    autoClose: toast_duration,
    type: type,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }) : void null;
};
