import { createSlice } from "@reduxjs/toolkit";

interface RootState {
  taskList: any;
  taskListLoading: boolean;
  onArchiveClick : object;
  onSelectedTask:any
}
const initialState: RootState = {
  taskList: "",
  taskListLoading: false,
  onArchiveClick: {},
  onSelectedTask:{}

};

export const taskListSlice = createSlice({
  name: "taskList",
  initialState,
  reducers: {
    setTaskList: (state, { payload }) => {
      state.taskList = payload;
      state.taskListLoading = false;
    },
    settaskListLoading: (state, { payload }) => {
      state.taskList = [];
      state.taskListLoading = true;
    },
    setArchiveClick: (state, { payload }) => {
      state.onArchiveClick = payload;
    },
    updateArchiveClick: (state, { payload }) => {
      state.onArchiveClick= payload;
    },
    setSelectedTask: (state, { payload }) => {
      state.onSelectedTask = payload;
    },
    updateSelectedTask: (state, { payload }) => {
      state.onSelectedTask = payload;
    },

    resetTaskListSlice:()=>initialState,
    
  },
});

export const { setTaskList, settaskListLoading ,setSelectedTask,updateSelectedTask,resetTaskListSlice,setArchiveClick,updateArchiveClick} = taskListSlice.actions;

export const getTaskListData = (state: any) => state.taskListReducer.taskList;

export const getArchivedValue = (state: any) => state.taskListReducer.onArchiveClick;

export const getsetSelectedTask = (state: any) => state.taskListReducer.onSelectedTask;


export const getTaskListLoading = (state: any) =>
  state.taskListReducer.taskListLoading;

export default taskListSlice.reducer;
