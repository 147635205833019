import React from "react";
import Stylesheet from "./style.module.scss"

type Props = {};
interface spinner{
    color?:string;
    size?:string,
    

}

const SyLoadingIcon = ({color=Stylesheet.color, size = "sm"}:spinner)=> {
  return (
    <div
      className={`spinner-border  ${color} spinner-border-${
        size === "xm" ? "sm" : size
      } ${size === "xm" ? Stylesheet.font_xm : ""} `}
      role="status"
    ></div>
  );
};

export default SyLoadingIcon;
