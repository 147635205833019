import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styleSheet from "./style.module.scss";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../service/comment-service";
import { useState, useEffect } from "react";
import Chip from "../../../../../../shared/components/sy-chip";
import Popup from "../../../../../../shared/components/sy-model";
import AddStatus from "./add-status";
import SyconfirmPopup from "../../../../../../shared/components/sy-confirmPopup";
import SyLoadingIcon from "../../../../../../shared/components/sy-loading-icon";
import { useTranslation } from "react-i18next";
import { SyIcons } from "../../../../../../shared/components";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { dragIcon } from "./helper";
import UpdateStatus from "../update-status";

interface statusProps {
  taskId: number | null;
  statusId: number;
  switchTab: (id: string) => void;
  updatetable: (val: boolean) => void;
  statusList: Array<object>;
  statusTableLoading: boolean;
}

const   StatusManage = (props: statusProps) => {
  const { taskId, updatetable, statusList, statusId, statusTableLoading } =
    props;
  const { t } = useTranslation();
  const title = t("status_overview");

  const [isLoading, setisLoading] = useState<boolean>(false);
  const [openPOPUP, setOpenPOPUP] = useState(false);
  const [editStatus, setEditStatus] = useState();
  const [enableEdit, setEnableEdit] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deletableid, setdeletableid] = useState<any>(null);
  const [droppedindex, setdroppedindex] = useState<number|null>(null);
  const [submitLoading, setSubmitLoading] = useState<any>([]);
  const [statusUpdatePopup, setStatusUpdatePopup] = useState(false);

  useEffect(() => {
    updatetable(true);
  }, []);

  // const { mutate: deleteStatusMutate, isLoading: deleteLoading } = useMutation({
  //   mutationFn: commentService.deleteStatus,
  //   onSuccess(data,variables) {
  //     const message = data.data.message;
  //     SyToast(t(message), "bottom-right", "short", "success");
  //     updatetable(true);
  //     const indexval = [...submitLoading];
  //     indexval[variables.index] = false;      
  //     setSubmitLoading(indexval);
  //   },
  // });
  const { mutate: deleteStatus, isLoading: deleteLoading } = useMutation({
    mutationFn: commentService.deleteStatus,
    onSuccess(data,variables) {
      const message = data.data.message;
      SyToast(t(message), "bottom-right", "short", "success");
      updatetable(true);
      const indexval = [...submitLoading];
      indexval[variables.index] = false;      
      setSubmitLoading(indexval);
    },
    onError(error:any) {

      if(error?.response?.data?.message ==='status was selected for some tasks'){
          setStatusUpdatePopup(true)
      }
      else{
       const errorMessage = error?.response?.data?.message;
       SyToast(t(errorMessage), "top-right", "short", "error");
 
      }
      
    },
  });




  const { mutate: reorderStatusMutate } = useMutation({
    mutationFn: commentService.reorderStatus,
    onSuccess() {
      setisLoading(false);
    },
  });

  const handleDragEnd = (result: any) => {
    result.destination? setdroppedindex(result.destination.index):void 0;
    if (!result.destination) return;
    const [reorderedItem] = statusList.splice(result.source.index, 1);
    statusList.splice(result.destination.index, 0, reorderedItem);
    const payload = {
      previous_index: result.source.index,
      new_index: result.destination.index,
      ts_id: result.draggableId,
    };
    payload.previous_index !== payload.new_index
      ? setisLoading(true)
      : void null;
    payload.previous_index !== payload.new_index
      ? reorderStatusMutate({ taskId, payload })
      : void null;
  };

  const handleDelIconClick = (data: any) => {
    setConfirmPopup(true);
    setdeletableid(data);
  };

  const handleDelete = () => {
    deleteStatus(deletableid);
    const submitLoadingVal = [...submitLoading];
    submitLoadingVal[deletableid.index] = true;
    setSubmitLoading(submitLoadingVal);
  };

  const handlePopupButtonClick = () => setOpenPOPUP(true);

  const handleEditClick = (data: any) => {
    setEnableEdit(true);
    setEditStatus(data);
    return document.getElementById("statusPopup")?.click();
  };

  const handlePopupClose = () => {
    setOpenPOPUP(false);
    setEnableEdit(false);
  };

  const handleRerender = () => updatetable(true);

  const emptyStatus = (
    <tr>
      <td className="col-1"></td>
      <td className="col-3"></td>
      <td className="col-4 ps-5 text-black-50">{t("no_data_found")}</td>
      <td className="col-3"></td>
      <td className="col-1"></td>
    </tr>
  );

  const loadingStatus = (
    <tr>
      <td className="col-1"></td>
      <td className="col-3"></td>
      <td className="col-4 ps-5 text-black-50">{t("loading")}...</td>
      <td className="col-3"></td>
      <td className="col-1"></td>
    </tr>
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <SyconfirmPopup
        open={confirmPopup}
        close={setConfirmPopup}
        onConfirmClick={handleDelete}
      />
      <div className={` ${styleSheet.innertab_style} p-3 rounded`}>
        <div className="d-flex flex-wrap justify-content-between">
          <p className={`${styleSheet.title}`}>{title}</p>
        </div>
        <div
          className={`d-flex justify-content-start ${styleSheet.element_padding}`}
        >
          <button
            className={`${styleSheet.button_primary} btn`}
            type="button"
            id="statusPopup"
            onClick={handlePopupButtonClick}
          >
            {t("add")}
          </button>
          {openPOPUP && (
            <Popup
              model_type={"responsive"}
              showHeader={true}
              children={
                <AddStatus
                  taskId={taskId}
                  onCloseClick={handlePopupClose}
                  statusToEdit={editStatus}
                  enableEdit={enableEdit}
                  rerenderTable={handleRerender}
                  statusId={statusId}
                  statusOptions={statusList}
                />
              }
              title={enableEdit ? t("edit") : t("add")}
              open={openPOPUP}
              close={setOpenPOPUP}
            />
          )}
            {statusUpdatePopup && (
              <Popup
                model_type={"scroll"}
                maxWidth={'xs'}
                showHeader={true}
                children={   
                  <UpdateStatus
                  list={statusList}
                  id={deletableid}
                  type={'status'}
                  close={setStatusUpdatePopup}
                  updateList={ updatetable}
                  discription={'status was selected for some tasks'}
                  
                  />
                }
                title={'update_status'}
                open={statusUpdatePopup}
                close={setStatusUpdatePopup}
              />
            )}
        </div>
        <div className={` ${styleSheet.element_padding} sy_horizontal_scroll`}>
          <table className="table ">
            <thead>
              <tr>
                <th scope="col" className="col-1"></th>
                <th scope="col" className="col-3">
                  {t("status")}
                </th>
                <th scope="col" className="col-4">
                  {t("description")}
                </th>
                <th scope="col" className="col-3">
                  {t("label")}
                </th>
                <th scope="col" className="col-1">
                  {t("action")}
                </th>
              </tr>
            </thead>
            <Droppable droppableId={"status"}>
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {statusTableLoading
                    ? loadingStatus
                    : statusList.length !== 0
                    ? statusList.map((data: any, index: number) => {
                        return (
                          <Draggable
                            key={data.ts_id}
                            draggableId={data.ts_id}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                className={`${styleSheet.t_row}`}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <td
                                  className="col-1"
                                  {...provided.dragHandleProps}
                                >
                                  {droppedindex === index &&
                                  isLoading ? (
                                    <SyLoadingIcon />
                                  ) : (
                                    dragIcon({ ...provided })
                                  )}
                                </td>
                                <td className="col-4">{data.ts_name}</td>
                                <td className="col-5">{data.ts_description}</td>
                                <td className="col-2">
                                  <Chip
                                    label={data.ts_name}
                                    color={data.ts_color}
                                  />
                                </td>
                                <td className="col-1">
                                  <div
                                    className={`d-flex select-none ${styleSheet.button_space}`}
                                  >
                                    {submitLoading[index] &&
                                    deleteLoading ? (
                                      <SyLoadingIcon />
                                    ) : (
                                      (data.ts_value !== "1" && data.ts_value !== "2" && data.ts_value !== "3" && data.ts_value !== "4") ? (
                                        <SyIcons
                                          name="delete_outline"
                                          iconType="icons"
                                          value={{ id: data.ts_id, index ,pro_id:taskId}}
                                          onClick={handleDelIconClick}
                                        />
                                      ):void null
                                    )}
                                    <span
                                      className="material-icons-outlined pointer"
                                      onClick={() => handleEditClick(data)}
                                    >
                                      create
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        );
                      })
                    : emptyStatus}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </div>
      </div>
      {/* </div> */}
    </DragDropContext>
  );
};

export default StatusManage;
