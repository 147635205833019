import React from "react";
import { syOpenFile } from "../../utils";
import { fileFormat } from "../../constants";
import StyleSheet from "./style.module.scss";
import SyIcons from "../sy-icons";
import { FILE_ICON_NAME, getfiletype } from "../../utils/sy-file-utils";
import SyLoadingIcon from "../sy-loading-icon";

type Props = {};

const SyListPreview = ({
  file,
  index,
  deleteConfirmPopup,

}: any) => {

  const handleFileIconClick = () => syOpenFile({ localUrl: file.url });
  const iconName = (data: any) => FILE_ICON_NAME[getfiletype(data)];

  return file.status != "deleted" ? (
    <div key={index} className={`${StyleSheet.filerow}`}>
      <div className="col-2  col-md-2 col-sm-2 pt-4 ms-3 ps-2 col-xm-1">
        {fileFormat.imageFormats.includes(file.extension) ? (
          <img
            key={index}
            src={`${file.url}`}
            alt={`${file.fileName}`}
            className={`${StyleSheet.image} `}
            onClick={handleFileIconClick}
          />
        ) : (
          <SyIcons
            className={`${StyleSheet.image} ${StyleSheet.icon_size} d-flex justify-content-center`}
            iconStyle="outlined"
            iconType="icons"
            name={iconName(file.fileName)}
            onClick={handleFileIconClick}
          />
        )}
      </div>
      <div
        className={`  col-lg-9 col-5  ms-1  mt-3  col-md-9 col-sm-6 col-xm-9  ms-0`}
      >
        <p className={` ${StyleSheet.fileName} mt-3 mb-0`}>{file.fileName}</p>

        <div className={`progress ${StyleSheet.progressbar} `}>
          <div
            key={index}
            className={`progress-bar  ${
              file.status == "pending"
                ? StyleSheet.uploading
                : file.status == "success"
                ? StyleSheet.success
                : StyleSheet.failed
            }`}
            role="progressbar"
            aria-valuenow={70}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: `${file.loading}%` }}
          ></div>
        </div>
      </div>

      <div
        className={`${StyleSheet.cancelbtn} col-1  col-md-1  col-sm-1 col-xm-1 mt-5 ps-3 `}
      >
        {file.delete[index]? (
          <SyLoadingIcon color={"loading"} />
        ) : (
          <SyIcons
            name={"cancel"}
            iconType="icons"
            iconStyle=""
            value={{ data: file.md_id, index: index, file: file }}
            onClick={deleteConfirmPopup}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SyListPreview;
