import React from "react";
import Popup from "../sy-model/popup_modal";
import Sybutton from "../sy-button/sy-button";
import { useTranslation } from "react-i18next";

interface confirmPopupProps {
  title?: string;
  onConfirmClick?: () => void;
  open: any;
  subHeading?: string;
  primaryBtnname?: string;
  secondaryBtnname?: string;
  style?: any;
  close: ((shouldClose: boolean) => void) | undefined;
}

const SyconfirmPopup = ({
  title = "confirmation",
  open,
  onConfirmClick,
  subHeading,
  primaryBtnname = "yes",
  secondaryBtnname = "cancel",
  style,
  close,
}: confirmPopupProps) => {
const {t} = useTranslation();
  const onClose = () => {
    close?.(false);
  };

  const footer = () => {
    return (
      <>
        <div>
          <p className="mb-5 main-font">
            {subHeading
              ? subHeading
              : t("are_you_sure_to_proceed_with_this_action")}
          </p>
        </div>
        <div className="d-flex justify-content-end">
          <Sybutton
            type={"button"}
            className={"Secondarybutton"}
            size={"sm"}
            label={t(secondaryBtnname)}
            onBtnClick={onClose}
          />
          <Sybutton
            type={"button"}
            className={"primarybutton ms-2"}
            size={"sm"}
            label={t(primaryBtnname)}
            onBtnClick={() => {
              onConfirmClick?.();
              onClose();
            }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {open && (
        <Popup
          model_type={"responsive"}
          showHeader={true}
          children={footer()}
          title={`${t(title)}`}
          open={open}
          close={close}
          style={{ width: "450px" }}
        />
      )}
    </>
  );
};

export default SyconfirmPopup;
