import Stylesheet from "./style.module.scss";
import Comments from "../../components/comments";
import { useSelector } from "react-redux";
import { getCommentLoading } from "../../../../feature/simpler-slice";
import { SyLoadingIcon } from "../../../../../../shared/components";
import { useTranslation } from "react-i18next";

type Props = {
  onClickNavBtn?: any;
  getIdDetails: any;
  onClickPath?:any;
};

const CommentScreen = ({ onClickNavBtn, getIdDetails,onClickPath }: Props) => {
  const commentLoading = useSelector(getCommentLoading);
  const { t } = useTranslation();
  return (
    <div className={`${Stylesheet.container} rounded me-2 p-1 ms-2 mt-2`}>
      <div className="d-flex align-items-center justify-content-between p-1">
        <div className="text-truncate ">
          <h5 className="main-font semi-b ms-2">{t('communication')}</h5>
        </div>
        {/* <div className="d-flex">
          <SyIcons name={"search"} className={"me-1"}/>
          <SyIcons name={"filter_list"} />
        </div> */}
      </div>
     
      <Comments onClickNavButton={onClickNavBtn} idVals={getIdDetails} onClickCommentsPath={onClickPath} />
    </div>
  );
};

export default CommentScreen;
