import { setCookie } from "../../../../../shared/utils";
import { InputForm } from "../../../../../shared/components/forms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import Service from "../service/service";
import config from "../../../../../environments/env-config";

const validationSchema = yup.object({
  email: yup.string().required("enter a valid Email or Username"),
  password: yup.string().required("enter a valid Password"),
})

type formValues = {
  email: string;
  password: string;
};

const LoginScreen = (props: any) => {

  const { mutate } = useMutation({
    mutationFn: Service.signIn,
    onSuccess(data, variables, context) {
      login(data);
    },
  });

  // const { dispatch }: any = useContext(AuthContext);

  const login = (data: any) => {

    const jwt_token = data?.data.jwt_token;
    if (jwt_token) {
      setCookie(config.auth_token, jwt_token, 365, config.auth_domain)
      window.location.href = 'http://localhost:3000';

    }
  };
  const formValues = {
    email: "",
    password: "",
  };

  const methods = useForm<formValues>({
    defaultValues: formValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema),
  });

  const submitFunc = (submitVal: any) => {
    const email = submitVal.email;
    const pwd = submitVal.password;
    return { email: email, password: pwd };
  };
  const { handleSubmit, register, } = methods;

  const onSubmit = (value: any) => {
    const { email, password } = submitFunc(value)
    const payload = { username: `${email}`, password: `${password}` };
    mutate(payload);
  };

  const onError = (err: any) => {
    console.log(err);
  };
  // console.log(process.env.REACT_APP_ENV)
  // if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'local') {
  //   window.location.href = `${config.auth_url}/?al=2`;
  //   return null
  // }
  
  return (
    <div className="container-fluid ">
      <center>
        <h1 className="fw-lighter ">Loginpage</h1>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-7 m-3">
            <InputForm
              placeholder={"Email"}
              type={"email"}
              {...register("email")}
            />
          </div>
          <div className="col-7 m-3">
            <InputForm
              placeholder={"Password"}
              type={"password"}
              {...register("password")}
            />
          </div>
        </form>

        <button
          className="btn btn-outline-secondary"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Login
        </button>
      </center>
    </div>
  );
};

export default LoginScreen;
