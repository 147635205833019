import React, { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import Stylesheet from "./style.module.scss";
import { pageSizeOptions } from "../../constants";
import { useTranslation } from "react-i18next";

type Props = {
  total: number;
  handlePaginateClick: (data: { pageIndex: any; rowPerPage: any }) => void;
};

const Sy_Pagination = ({ total, handlePaginateClick }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(pageSizeOptions[0]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    handlePaginateClick({ pageIndex: newPage, rowPerPage:rowsPerPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const a =event.target.value;
    setRowsPerPage(a);
 
    setPage(0);
    handlePaginateClick({ pageIndex: 0, rowPerPage: event.target.value });
  };

  const labelDisplayedRowsfn = ({ from, to, count }: any) => {
    return `${from}–${to === -1 ? count : to} ${t("of")} ${count}`;
  };
  
  return (  
    <div className={`${Stylesheet.pagination} d-flex `}>
      {total > 100 ? (
        <div className="d-flex justify-content-center">
          <TablePagination
            component="div"
            count={total}
            page={page}
            labelRowsPerPage="Anzahl der Einträge"
            onPageChange={handleChangePage}
            labelDisplayedRows={labelDisplayedRowsfn}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={pageSizeOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Sy_Pagination;
