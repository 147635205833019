import { configureStore } from "@reduxjs/toolkit";
import {navSlice,analyticSlice,viewControlSlice, docSlice,commentSlice,kanbanSlice,taskListSlice} from "./simpler-slice";
import {overlayLoaderSlice} from "./common-slice";
import {userDetailsSlice} from "./common-slice";



export const store = configureStore({
  reducer: {
    analyticReducer: analyticSlice,
    overLayLoading: overlayLoaderSlice,
    navReducer:navSlice,
    viewControlReducer:viewControlSlice,
    userDetailReducer:userDetailsSlice,
    docReducer:docSlice,
    commentReducer:commentSlice,
    kanbanReducer:kanbanSlice,
    taskListReducer:taskListSlice
  },
});

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch
