import StyleSheet from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import commentService from "../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import SyLoadingIcon from "../../../../../../shared/components/sy-loading-icon";
import { useTranslation } from "react-i18next";
import SyIcons from "../../../../../../shared/components/sy-icons";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import SyNavButton from "../../../../../../shared/components/sy-tab";
import BasictaskManage from "./basic-task-manage";
import Employeetaskmanage from "./employee-task-manage";
import DatataskManage from "./data-task-manage";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import { loadingIconButton } from "../../components/create-process-footer/create-process-footer";
import { button } from "./config";
import { headerName } from "./helper";
import { getsetSelectedTask } from "../../../../feature/simpler-slice/task-list-slice";
import { useSelector } from "react-redux";

type Props = {};

const TaskManage = (props: Props) => {
  const { t } = useTranslation();
  const [taskId, setTaskId] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState("section1");
  const [activeSection, setActiveSection] = useState<string | null>("section1");
  const [roles, setRoles] = useState<Array<object>>([]);
  const [status, setStatus] = useState<Array<object>>([]);
  const [priority, setPriority] = useState<Array<object>>([]);
  const [effort, setEffort] = useState<Array<object>>([]);
  const [saveButtonValue, setSaveButtonValue] = useState("");
  const [saveNextButtonValue, setSaveNextButtonValue] = useState("");
  const [saveButtonClick, setSaveButtonClick] = useState(false);
  const [saveNextButtonClick, setSaveNextButtonClick] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [editEmployee, seteditEmployee] = useState([]);
  const [fileStatus, setFileStatus] = useState<any>([]);
  const navRef = useRef<HTMLDivElement>(null);
  const basic = useRef<HTMLDivElement | null>(null);
  const employee = useRef<HTMLDivElement | null>(null);
  const data = useRef<HTMLDivElement | null>(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { itemId, taskId: parentId, parentId: subParent } = useParams();
  const navigate = useNavigate();
  const { editTaskId } = useParams();
  const subTask = useSelector(getsetSelectedTask);

  const getId = (val: any) => {
    setTaskId(val);
  };

  const OnScroll = (val: any) => {
    if (val == "section1" && basic.current) {
      basic.current.scrollIntoView();
    }
    if (val == "section2" && employee.current) {
      employee.current.scrollIntoView();
      TabeChange(val);
    }

    if (val == "section3" && data.current) {
      data.current.scrollIntoView();
      TabeChange(val);
    }
  };

  const { mutate: getEmployeeTab } = useMutation({
    mutationFn: commentService.employeeSavetaskManage,
    onSuccess(data, variables, context) {
      seteditEmployee(data.data);
    },
  });

  const { mutate: getDataTab } = useMutation({
    mutationFn: commentService.dataSavetaskManage,
  });
  const TabeChange = (val: any) => {
    if (val == "2") {
      const taskid = editTaskId ? editTaskId : taskId;
      getEmployeeTab({ itemId, taskid });
    }
    if (val == "3") {
      const payload = {
        filter: { fileType: null },
        paging: { pageIndex: 0, pageSize: 12 },
        search: null,
      };
      const taskid = editTaskId ? editTaskId : taskId;
      getDataTab({ itemId, taskid, payload });
    }
  };

  //--save_saveNext_handleClick

  const handleSaveNextButtonClick = (data: boolean) => {
    setSaveNextButtonClick(data);
    saveLoading!
      ? setSaveNextButtonValue("")
      : setSaveNextButtonValue("savenext");
    setTimeout(() => {
      setSaveNextButtonClick(false);
    }, 1000);
  };

  const handleSaveButtonClick = (data: boolean) => {
    if (!fileStatus ) {
      SyToast(t("upload_pending"), "bottom-right", "short", "warning");
      setButtonDisable(true);
    } else {
      setSaveButtonClick(data);
      saveLoading! ? setSaveButtonValue("") : setSaveButtonValue("save");
      setTimeout(() => {
        setSaveButtonClick(false);
      }, 1000);
    }
  };

  //--getbuttonloading

  const loadingSaveButton = (value: any) => {
    setSaveLoading(value);
    if (value == false) {
      setSaveButtonValue("");
      setSaveNextButtonValue("");
    }
  };

  //--scroll_active_inactive

  const handleScroll = () => {
    const curPos = window.scrollY;
    const sections = document.querySelectorAll("section");

    sections.forEach((section) => {
      const top = section.offsetTop - (navRef.current?.offsetHeight || 0) - 55;
      const bottom = top + section.offsetHeight;

      if (curPos >= top && curPos <= bottom) {
        setActiveSection(section.id);
      }
    });
  };

  //--roles_stat

  const { mutate: getRoles } = useMutation({
    mutationFn: commentService.getRoles,
    onSuccess(data, variables, context) {
      setRoles(data.data);
    },
  });

  const { mutate: getStatus, isLoading: statusLoading } = useMutation({
    mutationFn: commentService.getStatus,
    onSuccess(data, variables, context) {
      setStatus(data.data.status_options);
      setPriority(data.data.priority_options);
      setEffort(data.data.weight_options);
    },
  });

  const uploadStatus = (value: any) => {

      setFileStatus(value);

  };

  const cancel = () => {
    SyToast(t("upload_pending"), "top-right", "short", "warning");
    setButtonDisable(true);
  };
  const close = () => {
      navigate("/");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    getRoles(itemId);
    getStatus(itemId);
    const backMitarbeiter = document.getElementById("section1");
    if (backMitarbeiter) {
      backMitarbeiter.click();
    }
  }, []);
  return (
    <>
      <div className={`${StyleSheet.subtask}  pb-3 `}>
        <div
          className={`${StyleSheet.headertask}  d-flex align-items-center mb-0  pt-2 pb-0 ps-4`}
        >
          <SyIcons
            name={"chevron_left"}
            className={"material-icons mb-2 me-0"}
            onClick={close}
          />
          <h3 className="ps-1">
            {t(headerName({ parentId, editTaskId, subTask }))}
          </h3>
        </div>

        <div
          className={`${StyleSheet.sections} d-flex justify-content-between  row p-0 ps-3   `}
          ref={navRef}
        >
          <div
            className={`${StyleSheet.navButton} p-0 col-xl-3 col-lg-4 col-md-4 d-flex sy_horizontal_scroll`}
          >
            <SyNavButton
              buttons={button}
              onclick={OnScroll}
              activeSection={activeSection}
            />
          </div>

          <div
            className={`col-xl-5 col-lg-6  col-md-7 col-12  p-3 pe-3 d-flex justify-content-end me-2`}
          >
            <div>
              <Sybutton
                type={"button"}
                className={"Secondarybutton me-2 d-none d-sm-block"}
                size={"sm"}
                label={t("cancel")}
                onBtnClick={!fileStatus ? cancel : close}
                disabled={
                  !fileStatus && buttonDisable ? true : false
                }
              />
              <Sybutton
                type={"button"}
                className={
                  "iconButton me-2 d-flex align-items-center d-block d-sm-none"
                }
                size={"sm"}
                onBtnClick={!fileStatus ? cancel : close}
                label={<SyIcons name="keyboard_backspace" />}
              />
            </div>
            <div>
              <Sybutton
                type={"submit"}
                className={"primarybutton d-none d-sm-block"}
                size={"sm"}
                label={t("save")}
                value={"save"}
                onBtnClick={() => {
                  handleSaveButtonClick(true);
                }}
                Loadings={saveButtonValue === "save" ? saveLoading : false}
                disabled={
                  saveLoading
                    ? true
                    : buttonDisable && !fileStatus
                    ? true
                    : false
                }
              />
              <Sybutton
                type={"button"}
                className={
                  "iconButton me-2 d-flex align-items-center d-block d-sm-none"
                }
                size={"sm"}
                value={"save"}
                onBtnClick={() => {
                  handleSaveButtonClick(true);
                }}
                label={
                  saveButtonValue === "save" && saveLoading ? (
                    loadingIconButton
                  ) : (
                    <SyIcons name="save" />
                  )
                }
                disabled={
                  saveLoading
                    ? true
                    : buttonDisable && !fileStatus
                    ? true
                    : false
                }
              />
            </div>
            <div>
              <Sybutton
                type={"submit"}
                className={`primarybutton ms-2 ${
                  activeSection === "section1" ? "d-none d-sm-block" : "d-none"
                } `}
                value={"save_and_next"}
                size={"sm"}
                label={t("save_and_next")}
                onBtnClick={() => {
                  handleSaveNextButtonClick(true);
                }}
                Loadings={
                  saveNextButtonValue === "savenext" ? saveLoading : false
                }
                disabled={saveLoading ? true : false}
              />
              <Sybutton
                type={"button"}
                className={`iconButton me-2 d-flex align-items-center  ${
                  activeSection === "section1" ? "d-block d-sm-none" : "d-none"
                }  d-block d-sm-none`}
                size={"sm"}
                value={"save_and_next"}
                onBtnClick={() => {
                  handleSaveNextButtonClick(true);
                }}
                label={
                  <>
                    {saveNextButtonValue === "savenext" && saveLoading ? (
                      loadingIconButton
                    ) : (
                      <SyIcons name="save" />
                    )}
                    <SyIcons name="keyboard_double_arrow_right" />
                  </>
                }
                disabled={saveLoading ? true : false}
              />
            </div>
          </div>
        </div>

        <div className={` ${StyleSheet.form} `}>
          <section
            ref={basic}
            className={`${StyleSheet.BasictaskManage}  `}
            id="section1"
            aria-labelledby="pills-home-tab"
          >
            {statusLoading ? (
              <div className={`${StyleSheet.load}`}>
                <SyLoadingIcon
                  size="lg "
                  color={`${StyleSheet.overlay} loading ms-5`}
                />
              </div>
            ) : (
              <BasictaskManage
                setFormSubmitted={setFormSubmitted}
                getId={getId}
                itemId={itemId}
                loadingSaveButton={loadingSaveButton}
                saveNextButtonClick={saveNextButtonClick}
                saveButtonClick={saveButtonClick}
                status={status}
                priority={priority}
                effort={effort}
                parentId={parentId}
                editTaskId={editTaskId}
                subParent={subParent}
              />
            )}
          </section>

          {taskId || editTaskId ? (
            <>
              <section
                ref={employee}
                id="section2"
                className={` mt-3 `}
                aria-labelledby="pills-profile-tab"
              >
                <Employeetaskmanage
                  itemId={itemId}
                  roles={roles}
                  taskid={taskId}
                  editEmployee={editEmployee}
                  editTaskId={editTaskId}
                />
              </section>
            </>
          ) : null}

          {taskId || editTaskId ? (
            <div>
              <section
                ref={data}
                id="section3"
                className={`${StyleSheet.Employeetaskmanage}  mt-3 `}
                aria-labelledby="pills-contact-tab"
              >
                <DatataskManage
                  taskid={taskId}
                  editTaskId={editTaskId}
                  itemId={itemId}
                  uploadStatus={uploadStatus}
                />
              </section>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TaskManage;
export const Previous_page = () => window.history.back();


