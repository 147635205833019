import { useState } from "react";
import Chip from "../../sy-chip";
import styleSheet from "./style.module.scss";

interface colorPickerProps {
  defaultColor: string;
  displayText: string | null;
  onColorChange?: (val: any) => void;
}

const SyColorpicker = (props: colorPickerProps) => {
  const { defaultColor, displayText, onColorChange } = props;

  const [chipColor, setChipColor] = useState<string>(defaultColor);

  const onchange = (e: any) => {
    setChipColor(e.target.value);
    onColorChange && onColorChange(e);
  };
  return (
    <span className={`${styleSheet.colorpicker_outer}`}>
      <input
        type="color"
        id="syColorPicker"
        className={`${styleSheet.color_picker} pointer`}
        defaultValue={defaultColor}
        onChange={onchange}
      />
      <div
        className={`${styleSheet.color_picker_text} d-flex align-items-center`}
      >
        <label htmlFor="syColorPicker">
          {displayText ? (
            <Chip label={displayText} color={chipColor} />
          ) : (
            void null
          )}
        </label>
      </div>
    </span>
  );
};

export default SyColorpicker;
