export const dragIcon = (provided: any) => (
  <span
    className={`material-icons-round select-none`}
    {...provided.dragHandleProps}
  >
    drag_indicator
  </span>
);


export const masterStatus = [
  { id:"1",name:"offen"},
  { id:"2",name:"in Bearbeitung"},
  { id:"3",name:"erledigt"},
  { id:"4",name:"abgeschlossen"},
]