import { useEffect, useState } from "react";
import { SyToggleButton } from "../../../../../../shared/components/forms";
import Stylesheet from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { SyIcons } from "../../../../../../shared/components";
import { useSelector, useDispatch } from "react-redux";
import {
  updateMyDetail,
  getNavDetails,
  getNavPath,
  getViewControlDetail,
  updateViewType
} from "../../../../feature/simpler-slice";
import { useParams } from "react-router-dom";

type Props = {
  handileViewClick: any;
  onClickNavButton?: any;
  data:any
};

const ViewControl = ({ handileViewClick, onClickNavButton,data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const viewControlDetail = useSelector(getViewControlDetail);
  const [selectedView, setSelectedView] = useState(data.view||'list');
  const navDetails = useSelector(getNavDetails);
  const checkId = navDetails.path[navDetails.path.length - 1]
  const { popup } = useParams();

  
  const toggleClick = (data: any) => {
    dispatch(updateMyDetail(data.target.checked));
    onClickNavButton(navDetails.path[navDetails.path.length - 1], { from: 'viewControl', mine: data.target.checked });
  };

  const iconClick = (val: any) => {
    dispatch(updateViewType(val));
    handileViewClick(val);
    setSelectedView(val);
  };


  useEffect(() => {
    if (popup) {
      iconClick('list')
    }
  }, [popup]);

  return (
    <div className={`${Stylesheet.container} p-2 mt-2`}>
      <div
        className={`${Stylesheet.viewcontrolcard} d-flex justify-content-between align-items-center rounded p-2`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <SyIcons
            name={"view_list"}
            value={"list"}
            iconType="icons"
            toolTipTitle={t("all_task")}
            className={`me-1 pointer p-1 ${selectedView === "list" ? Stylesheet.primaryBackground :  ""
              }`}
            onClick={iconClick}
          />
          <SyIcons
            name={"grid_view"}
            value={"grid"}
            iconType="icons"
            iconStyle="outlined"
            toolTipTitle={t("kanban_task_status_board")}
            className={`pointer ms-1 me-1 p-1 ${selectedView === "grid" ? Stylesheet.primaryBackground : ""
              }`}
            onClick={iconClick}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <SyToggleButton toggleClick={toggleClick} value={viewControlDetail.mine}/>
          </div>
          <div className="me-3 ellipsis">
            <p className={`mb-1 main-font`}>{t("my_task")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewControl;
