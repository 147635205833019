import Stylesheet from "./style.module.scss";
import { MAIN_API_URL  } from "../../constants";
import { useSelector } from "react-redux";
import { getJwtToken } from "../../../projects/simpler/feature/common-slice";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Dummyavatar from "../sy-dummyavatar";
interface Props {
  profile?: any;
  showName?: Boolean;
  type?: "single" | "group";
  max?: number;
  avatarSet?: any;
  typeId?: string | number;
  id?: string | number;
  size?: "sm" | "md" | "lg";
}

const Avathar = ({
  profile,
  showName = false,
  type = "single",
  max,
  avatarSet,
  typeId = "type_id",
  id = "id",
  size = "md",
}: Props) => {
  const token = useSelector(getJwtToken);

  return (
    <>
      <div className="d-flex align-items-center ellipsis">
        {type === "single" && (
          <div className={`${Stylesheet[size]}`}>
            <img
              src={`${MAIN_API_URL}public/asset/avatar/${profile[typeId]}/${profile[id]}/${token}`}
              className={`${Stylesheet.singleImage} user-select-none d-flex align-items-center`}
            />
          </div>
        )}

        {showName && (
          <p className={`ellipsis ms-2 mb-0 mt-0 sub-font`}>{profile?.name}</p>
        )}
      </div>

      {type === "group" && (
        <div>
          <AvatarGroup max={max}>
            {avatarSet.map((avatar: any, index: number) => (
              <Avatar
                src={`${MAIN_API_URL}public/asset/avatar/${avatar.type_id}/${avatar.id}/${token}`}
                className={`${Stylesheet.image} user-select-none d-flex align-items-center`}
                key={index}
              />
            ))}
          </AvatarGroup>
        </div>
      )}
    </>
  );
};
export default Avathar;
