import React from "react";
import SyChip from "../sy-chip";

interface props {
  searchValue: string;
  onChange: (data: any) => void;
  chipSize?: "sm" | "md" | "lg";
  filterValue?: any;
  filterDelete?: any;
}

const SyPersist = (props: props) => {
  const {
    searchValue,
    onChange,
    chipSize = "md",
    filterValue,
    filterDelete,
  } = props;

  const onRemoveSearch = () => {
    const data = { action: "remove", type: "search", search: "" };
    onChange(data);
  };

  const onRemoveFilter = (data: any) => {
    filterDelete(data);
  };

  return (
    <>
      {searchValue && (
        <div className="pe-2 pb-2">
          <SyChip
            label={searchValue}
            size={chipSize}
            onClick={onRemoveSearch}
            actionIcon={true}
          />
        </div>
      )}
      {filterValue &&
        filterValue?.map((data: any) => (
          <div className="pe-2 pb-2">
            <SyChip
              label={data.name}
              size={chipSize}
              onClick={() => onRemoveFilter(data)}
              actionIcon={true}
            />
          </div>
        ))}
    </>
  );
};

export default SyPersist;
