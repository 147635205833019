import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Stylesheet from "./style.module.scss";
import { useTranslation } from "react-i18next";
import SyIcons from "../sy-icons";

type Props = {
  onFilterClick?: any;
  showFilterIcon?: boolean;
  subtasklist?: boolean;
  fetchList?: (
    searchValue?: string,
    showInactive?: boolean,
    sort?: any
  ) => void;
  searchEventEmit: any;
  searchValue: string;
  disabled?:any
};

type FormData = {
  searchText: string;
};

const Sy_Search = ({
  searchEventEmit,
  onFilterClick,
  showFilterIcon,
  subtasklist,
  searchValue,
  disabled
}: Props) => {
  const { register, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      searchText: searchValue,
    },
  });
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState(searchValue);
  const [newSearchTimeout, setNewSearchTimeout] =
    useState<NodeJS.Timeout | null>(null);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSearchText = event.target.value;

    setSearchText(newSearchText);
    setValue("searchText", newSearchText);

    if (newSearchTimeout) {
      clearTimeout(newSearchTimeout);
    }

    const searchTimeout = setTimeout(() => {
      handleSubmit(submitForm)();
    }, 800);

    setNewSearchTimeout(searchTimeout);
  };

  const submitForm: SubmitHandler<FormData> = (data) => {
    const { searchText } = data;
    searchEventEmit(searchText);
  };

  useEffect(() => {
    setValue("searchText", searchValue);
    setSearchText(searchValue);
  }, [searchValue]);

  return (
    <div className={`${Stylesheet.input_group} rounded d-flex `}>
      {showFilterIcon && (
        <SyIcons
          className=" pointer select-none "
          onClick={onFilterClick}
          iconStyle="round"
          name="filter_list"
          toolTipTitle={t("filter")}
        />
      )}

      <input
        className="box-input ms-2 "
        placeholder={t("search")}
        type="search"
        {...register("searchText")}
        value={searchText}
        onChange={handleSearchInputChange}
        onSubmit={handleSubmit(submitForm)}
        disabled={disabled}
      />
      <SyIcons
        name="search"
        iconStyle="round"
        className={"pointer me-2"}
        toolTipTitle={t("search")}
      />
    </div>
  );
};

export default Sy_Search;
