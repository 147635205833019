import Stylesheet from "./style.module.scss";
import { useTranslation } from "react-i18next";



type TaskHeaderHeaderProps = {
  header: { key: string; label: string };
  handleSort: (key: string) => void;
  sorting: { active: string; direction: "asc" | "desc" | ""};
  list?:any
};

const TaskHeader = ({
  header,
  handleSort,
  sorting,
  list,
}: TaskHeaderHeaderProps) => {
  const { t } = useTranslation();
  return (
    <th className={`${Stylesheet.theader} sub-font fw-bold ${list?"pb-1":""}`} scope="col">
      {t(header.label)}
      {sorting.active === header.key &&
        sorting.direction === "asc" && (
          <span
            className={`${Stylesheet.small_icon} material-icons-round pointer asc`}
            onClick={() => handleSort(header.key)}
          >
            south
          </span>
        )}
      {sorting.active === header.key &&
        sorting.direction === "desc" && (
          <span
            className={`material-icons-round ${Stylesheet.small_icon} pointer desc`}
            onClick={() => handleSort(header.key)}
          >
            north
          </span>
        )}
      {sorting.active !== header.key && (
        <span
          className={`material-icons-round ${Stylesheet.small_icon} pointer desc`}
          onClick={() => handleSort(header.key)}
        >
          north
        </span>
      )}
    </th>
  );
};



export default TaskHeader;
