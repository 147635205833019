import React from "react";
import Stylesheet from "./style.module.scss";
import Sybutton from "../sy-button/sy-button";
import { useTranslation } from "react-i18next";

type Props = {
  handleSubmit: any;
  handleformSubmit: any;
  label?: string;
  loading?:boolean;
};

const Sy_CustomFooter = ({
  handleSubmit,
  handleformSubmit,
  label = "search",
  loading = false,
}: Props) => {
  const { t } = useTranslation();

  const onsubmit = (val: any) => {
    handleformSubmit(val);
  };

  const onClose = () => {
    handleformSubmit(null);
  };

  const onerror = (val: any) => {
    console.log(val);
  };

  return (
    <div className={`${Stylesheet.footer} d-flex align-items-end flex-column`}>
      <div className="mt-3">
        <Sybutton
          type={"button"}
          className={`Secondarybutton me-2`}
          size={""}
          label={t("close")}
          onBtnClick={onClose}
        />
        <Sybutton
          type={"button"}
          className={`primarybutton`}
          size={""}
          label={t(`${label}`)}
          Loadings={loading}
          onBtnClick={handleSubmit(onsubmit, onerror)}
        />
      </div>
    </div>
  );
};

export default Sy_CustomFooter;
