import React from "react";
import http from "../../../../axios";

type Props = {};
class kanbanService {
  getkanbanList(payload: object) {
    return http().post(
      "/public/modules/mopus/main/task_details_based_on_status/8/6",
      payload
    );
  }

  getKanbanTaskStatus(payload: object) {
    return http().post(
      "/public/modules/mopus/main/task_list_status",
      payload
    );
  }

  saveKanban(payload: object) {
    return http().post(
      "/public/modules/mopus/main/project_task_status_save",
      payload
    );
  }


  //my kanban 

  getMyKanbandetails(data:any) {
    return http().post(`/public/modules/mopus/main/kanban_task_list/${data.data.masterId}/${data.data.id}`,data.kanbanPayload)
    // return http().post(`/public/modules/mopus/main/task_details_based_on_status/1`,data.kanbanPayload)
  }

  putKanbanTask(payload:any) {
    return http().put(`/public/modules/mopus/main/update_task_on_status/${payload.taskId}`,payload.payload);
  }

}

export default new kanbanService();
