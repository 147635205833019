import dayjs from "dayjs";
import * as yup from "yup";
import {getDateLocaleString} from "../../../../../shared/utils/dayjs"

export const editPortFolio = yup.object().shape({
  name: yup.string().required("field_required").max(100, "max_100"),
  parent_name: yup.object().required("field_required"),
});
export const portfolio = yup.object().shape({
  name: yup.string().required("field_required").max(100, "max_100"),
});

export const validationSchema = yup.object().shape({
  text: yup.string().required("field_required"),

  email: yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(yup.string().email(`please_enter_a_correct_email_address`)),

  start_date: yup.date().nullable().required("field_required"),
  end_date: yup
    .date()
    .default(null)
    .nullable()
    .test("", "invalid_time_range", (value, context) => {
      const { start_date } = context.parent;
      if (start_date && value) {
        return getDateLocaleString(value,"date") >= getDateLocaleString(start_date,"date");
      }
      return true;
    }),
});

export const whiteSpaceTrim = yup.object({
  reply: yup.string().trim(),
});

export const employeevalidationSchema = yup.object({
  Employee: yup.array().min(1, "field_required"),
});

export const fileSchema = yup.object().shape({
  File: yup.mixed().required(""),
});

export const AssigneeSchema = yup.object({
  user: yup.array().min(1, "field_required"),
  type: yup.array().min(1, "field_required"),
  // type: yup.object({
  //     id: yup.string(),
  //   })
  //   .nullable()
  //   .required("field_required"),
});
export const limitedAccess = yup.object({
  user: yup.array().required( "field_required"),

});

export const RoleSchema = yup.object({
  tr_name: yup.string().required("field_required"),
  tr_color: yup.string(),
});

  export const StatusSchema = yup.object({
    ts_name: yup.string().required('field_required'),
    ts_description: yup.string().nullable(),
    ts_color: yup.string(),
    ts_master_type:yup.object().nullable().required("field_required")
  });
const tagAssigneeSchema = yup.object({
  user: yup
    .object({
      name: yup.string().required("field_required"),
    })
    .nullable()
    .required("field_required"),
  roles: yup.array().min(1, "field_required"),
});

export const TagSchema = yup.object({
  pt_name: yup.string().required("field_required"),
  pt_notify_emails: yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(yup.string().email(`please_enter_a_correct_email_address`)),
  assignees: yup.array().of(tagAssigneeSchema),
});
