export const MODEL_FOOTER_MODE = {
  SAVE: "save",
  SUBMIT: "submit",
  NONE: "none",
  ONLY_CANCEL: "onlycancel",
};

export const FOOTER_CONFIG = {
  [MODEL_FOOTER_MODE.SAVE]: {
    showFooter: true,
    cta: [
      {
        isSubmit:false,
        btn_color: "Secondarybutton",
        text: "cancel",
        onCancelFn: "onCancel",
      },
      {
        isSubmit:true,
        btn_color: "primarybuttton",
        text: "submit",
        onSubmitFn: "onSubmit",
      },
    ],
  },
  [MODEL_FOOTER_MODE.SUBMIT]: {
    showFooter: false,
    syConfirmPopupFooter: true,
    cta: [],
  },
  [MODEL_FOOTER_MODE.NONE]: {
    showFooter: true,
    cta: [],
  },
  [MODEL_FOOTER_MODE.ONLY_CANCEL]: {
    showFooter: true,
    cta: [
      {
        isSubmit:false,
        btn_color: "Secondarybutton",
        text: "cancel",
        onSubmitFn: "onCancel",
      },
    ],
  },
};
