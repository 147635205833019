import { useEffect, useState } from "react";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import { Controller, useForm } from "react-hook-form";
import { InputForm } from "../../../../../../shared/components/forms";
import SyTextEditor from "../../../../../../shared/components/forms/sy-rich-text-edit";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import Popup from "../../../../../../shared/components/sy-model";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../service/comment-service";
import {
  getNavDetails,
  updateMasterItems,
  updateMyDetailOnToggle,
} from "../../../../feature/simpler-slice";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  editPortFolio,
  portfolio,
} from "../../schema-validation/schema-validation";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import SyAsynSelect from "../../../../../../shared/components/sy-asyn-select";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import Errormessage from "../../../../../../shared/components/forms/errormessage";
import { asyncWildDatapayload, getAsyncWildData } from "../../../../../../shared/utils";

type Props = {
  openPopup?: any;
  closePopup?: any;
  data?: any;
  onPortfolioPopupClose?: (data: any) => void;
  title?: any;
  portfolioDetail?: any;
  mode?: any;
  onClickNavBtn?: any;
};

type formValues = {
  name: string;
  description: any;
  status: any;
  members: any;
  parent_name: any;
};

const PortfolioPopup = (props: any) => {
  const {
    openPopup,
    closePopup,
    data,
    onPortfolioPopupClose,
    title,
    portfolioDetail,
    mode,
    navDetails,
    onClickNavBtn,
  } = props;

  const { masterId, id } = data ? data : "";

  const newPfoId = navDetails?.path[0]?.id;

  const navDetail = useSelector(getNavDetails);

  const getIdDetails = navDetail.path[navDetail.path.length - 1];

  const my_tasks = useSelector(updateMyDetailOnToggle);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [validation, setValidation] = useState(false);

  const status = [
    {
      value: 1,
      label: t("active"),
    },
    {
      value: 2,
      label: t("inactive"),
    },
  ];

  const defaultOption = status.find((option: any) => option.label === "active");
  const poryfolioStatus = status.find(
    (option: any) => option.value == portfolioDetail?.pfo_status
  );
  const programStatus = status.find(
    (option: any) => option.value == portfolioDetail?.prm_status
  );
  const defaultValues = {
    parent_name: null,
    status: poryfolioStatus
      ? poryfolioStatus
      : programStatus
      ? programStatus
      : defaultOption,
    members: [],
  };

  const Form = () => {
    const {
      handleSubmit,
      control,
      register,
      setValue,
      setFocus,
      formState: { isValid, errors },
    } = useForm<formValues>({
      defaultValues: defaultValues,
      resolver: yupResolver<yup.AnyObjectSchema>(
        validation ? editPortFolio : portfolio
      ),
    });

    const { mutate: saveMutation, isLoading } = useMutation({
      mutationFn: commentService.savePortfolio,
      onSuccess(data, variables, context) {
        if (data?.data?.status === "success") {
          dispatch(
            updateMasterItems({
              data: data.data.data,
              mode,
              newPfoId,
              from: masterId == "1" ? "portfolio " : "project",
            })
          );
          onPortfolioPopupClose && onPortfolioPopupClose(data.data.data);
          closePopup(false);
          const message = data.data.message;
          SyToast(t(message), "bottom-right", "short", "success");
          // if (model == "add") {
          //   dispatch(updateMasterItems(data.data));
          // }
          onClickNavBtn(getIdDetails,{ from: 'popup', mine: my_tasks });
        }
      },
    });

    const save = (val: any) => {
      const prefix =
        masterId === 1
          ? "pfo"
          : masterId === 2
          ? "prm"
          : portfolioDetail?.pfo_id
          ? "pfo"
          : "prm";

      const payload = {
        [`${prefix}_name`]: val.name,
        [`${prefix}_description`]: val.description,
        [`${prefix}_status`]: val.status?.value,
        access_members: val.members ? val.members : [],
      };

      if ((id && !portfolioDetail) || val?.parent_name?.pfo_id)
        payload["prm_pfo_id"] = val?.parent_name?.pfo_id
          ? val.parent_name.pfo_id
          : id;

      const editId = portfolioDetail?.pfo_id
        ? portfolioDetail?.pfo_id
        : portfolioDetail?.prm_id
        ? portfolioDetail?.prm_id
        : "";

      saveMutation({ payload, masterId, editId });
    };
    const handleFormSubmit = (data: any) => {
      save(data);
    };

    const handleStatusLabel = (data: any) => data?.label;
    const handleStatusFilter = () => void 0;

    const setFormValues = (data: any, prefix: string) => {
      const defaultStatus = status.find(
        (option: any) => option.value === data[`${prefix}_status`]
      );

      setValue("name", data[`${prefix}_name`]);
      setValue("description", data[`${prefix}_description`]);
      setValue("status", defaultStatus);
      setValue("members", data.access_members);
      setValue("parent_name", data.portfolio);
    };

    useEffect(() => {
      setFocus("name");

      if (portfolioDetail?.pfo_id || portfolioDetail?.prm_id) {
        setFormValues(portfolioDetail, portfolioDetail?.pfo_id ? "pfo" : "prm");
      }
      // if (portfolioDetail?.prm_id) {
      //   setFormValues(portfolioDetail, "prm");
      // }
    }, []);

    const onCancel = () => {
      closePopup(false);
    };

    const ParentField = ({ control, name, placeholder, multiple }: any) => {
      const [parent, setparent] = useState<any>();

      const { mutate: portfolioList, isLoading: taskLoading } = useMutation({
        mutationFn: commentService.getPortfolioList,
        onSuccess(data, variables, context) {
          parentOptions(data);
        },
      });

      const parentOptions = (data: any) => {
        const optionsArray: any = [];
        data?.data?.forEach((data: any) => {
          optionsArray.push(data);
        });

        setparent(optionsArray);
      };

      const handleParentLabel = (data: any) => data?.pfo_name;
      const handleParentFilter = (val: any) => {
        portfolioList(val);
      };

      useEffect(() => {
        setValidation(true);
      }, []);

      return (
        <div>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  options={parent}
                  placeholder={placeholder}
                  loading={taskLoading}
                  enableSearchBox={true}
                  handleFilter={handleParentFilter}
                  handleChange={onChange}
                  multiple={multiple ? true : false}
                  controlledVal={value ? value : null}
                  getoptlabel={handleParentLabel}
                />
              );
            }}
          />
        </div>
      );
    };

    return (
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div>
          <InputForm
            placeholder={t("text")}
            {...register("name")}
            type={"text"}
            error={errors.name && errors.name.message}
          />
        </div>
        <div>
          <Controller
            name={"description"}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <SyTextEditor
                  value={value}
                  onChange={onChange}
                  register={register("description")}
                  placeholder={t("description")}
                />
              );
            }}
          />
        </div>
        <div className="row">
          <div className="col-sm-6 col-xm-12 mt-3 pe-0">
            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Dropdown
                    options={status}
                    placeholder={t("status")}
                    value={value}
                    handleFilter={handleStatusFilter}
                    handleChange={onChange}
                    getoptlabel={handleStatusLabel}
                    clearIcon={true}
                  />
                );
              }}
            />
          </div>

          <div className="mt-3">
            <SyAsynSelect
              name={"members"}
              placeholder={t("members")}
              control={control}
              optionValue={null}
              multiple={true}
              defaultVal={false}
              SERVICE_API_FN={commentService.getEmployeeTags}
              onSuccessAsyncSearch={getAsyncWildData}
              payload={asyncWildDatapayload.userOnly}
            />
          </div>

          {portfolioDetail &&
            data !== "1" &&
            masterId != navDetails.path[0].masterId && (
              <div className="mt-3">
                <ParentField
                  name={"parent_name"}
                  placeholder={t("portfolio")}
                  control={control}
                />
                {errors.parent_name && (
                  <p className="d-flex justify-content-end note-text-style mt-2">
                    <Errormessage message={"field_required"} />
                  </p>
                )}
              </div>
            )}
        </div>

        <div className="d-flex justify-content-end mt-5 mb-2">
          <Sybutton
            className={`Secondarybutton me-2`}
            label={t("close")}
            type={"reset"}
            onBtnClick={onCancel}
            size={"sm"}
          />
          <Sybutton
            className={`primarybutton  me-2`}
            label={t("save")}
            type={"submit"}
            size={"sm"}
            Loadings={isLoading}
            disabled={isLoading ? true : false}
          />
        </div>
      </form>
    );
  };

  return (
    <div>
      {openPopup && (
        <Popup
          open={openPopup}
          close={closePopup}
          model_type={"responsive"}
          style={{ width: "500px" }}
          title={title}
        >
          <Form />
        </Popup>
      )}
    </div>
  );
};

export default PortfolioPopup;
