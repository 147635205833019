export const getAccessMembers = (data: any) =>
  data.access_members.map((data: any) => {
    delete data.manual_id;
    delete data.region;
    delete data.community;
    delete data.sub;
    delete data.type;
    return data;
  });

export const getMembersdata = (saveData: any) =>
  saveData.access_members ? getAccessMembers(saveData) : [];

export const getAssignees = (data: any) =>
  data?.assignees.map((data: any) => {
    const assignees = {
      user: data.user,
      roles: data.roles.map((data: any) => data.tr_id),
    };
    return assignees;
  });

const getAssigneeRole = (selectedOpt: any, roles: any) => {
  const filterRoles = roles.filter((id: any) => {
    return selectedOpt.includes(id.tr_id);
  });
  return filterRoles;
};

export const getEditAssignees = (data: Array<object>, roles: any) => {
  return data.map((data: any) => {
    const assignees = {
      user: data.user,
      roles: getAssigneeRole(data.roles, roles),
    };
    return assignees;
  });
};

export const memberExist = (val: any,Memberslist:any) =>
Memberslist.map((data: any) => data.name).some((name:any) => {
  if (name === val) {
    return true;
  }
});

export const getRoleLabel = (options: any) => {
    return options.tr_name;
  };

export const dragIcon = (provided: any) => (
  <span
    className={`material-icons-round select-none`}
    {...provided.dragHandleProps}
  >
    drag_indicator
  </span>
);