
import React from 'react'
import { useTranslation } from "react-i18next";

type Props = {}

const Errormessage = ({message}:any) => {
  const { t } = useTranslation();
  return (

    <div>
    <small className="text-danger">{t(message)}</small>
    </div>
  )
}

export default Errormessage