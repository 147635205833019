import { useState, useCallback, useEffect } from "react";
import Stylesheet from "./style.module.scss";
import { DragDropContext } from "react-beautiful-dnd";
import UserComponent from "./user-component";
import SyLoadingIcon from "../sy-loading-icon";
import { useSelector, useDispatch } from "react-redux";
import { setKanbanList } from "../../../projects/simpler/feature/simpler-slice/kanban-slice";
import SyAccordion from "../sy-accordion";
import { useTranslation } from "react-i18next";
import Popup from "../sy-model";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../forms/dropdown";
import Sybutton from "../sy-button/sy-button";
import { getNavDetails } from "../../../projects/simpler/feature/simpler-slice";

type Props = {
  kanbanList: any;
  type: string;
  saveStatus: (data: any) => void;
  Loading?: boolean;
  onClickBtn?: any;
  statusData?: any;
};

const KanbanBoard = (props: Props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, control } = useForm();
  const navDetails = useSelector(getNavDetails);
  const { kanbanList, onClickBtn, statusData } = props;
  const [openItemId, setOpenItemId] = useState<string | null>(null);
  const [status, setStatus] = useState(true);
  const [masterStatus, setMasterStatus] = useState([]);
  const [openStatusPopup, setOpenStatusPopup] = useState(false);
  const [statusValue, setStatusValue] = useState<any>([]);
  const [movingItem, setMovingItem] = useState<any>([]);
  const { t } = useTranslation();

  const handleAccordionItemClick = useCallback(
    (itemId: string) => {
      if (openItemId === itemId) {
        setOpenItemId(null);
      } else {
        setOpenItemId(itemId);
        setStatus(true);
      }
    },
    [openItemId]
  );

  const onSubmit = (val: any) => {
    const taskId = movingItem?.task_id;
    const payload = {
      task_status: statusValue?.ts_value,
    };
    props.saveStatus({ taskId, payload });
    setOpenStatusPopup(false);
  };

  const onClose = (data: any) => {
    setOpenStatusPopup(false);
  };

  const getClickedStatus = (data: any) => {
    setStatusValue(data);
  };

  const checkMasterId = navDetails.path[navDetails.path.length - 1];

  const popupContent = () => {
    return (
      <>
        <div>
          <Controller
            name="change_status"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  options={masterStatus}
                  placeholder={t("status")}
                  enableSearchBox={false}
                  loading={false}
                  handleChange={getClickedStatus}
                  multiple={false}
                  value={null}
                  getoptlabel={function (val: any): string {
                    return val.ts_name;
                  }}
                />
              );
            }}
          />
        </div>
        <div className="d-flex mt-3 justify-content-end">
          <div>
            <Sybutton
              className={`Secondarybutton me-2`}
              label={t("cancel")}
              type={"button"}
              onBtnClick={onClose}
              size={"sm"}
            />
          </div>
          <div>
            <Sybutton
              className={`primarybutton  me-2`}
              label={t("save")}
              type={"submit"}
              size="sm"
              onBtnClick={onSubmit}
            />
          </div>
        </div>
      </>
    );
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    // const updatedDataList = [...kanbanList];
    const updatedDataList = JSON.parse(JSON.stringify(kanbanList));
    const destinationDroppableId = +result.destination.droppableId;
    const movedItem = updatedDataList?.data[
      result.source.droppableId
    ]?.data?.splice(result.source.index, 1);
    setMovingItem(movedItem[0]);
    const getTsId = updatedDataList?.data[destinationDroppableId];

    if (checkMasterId?.masterId != 3) {
      const checkOptions = statusData?.status[destinationDroppableId + 1];
      setMasterStatus(checkOptions[movedItem[0].proId]);

      // for (const key in checkOptions) {
      //   if (checkOptions.hasOwnProperty(key)) {
      //     const arrayValue = checkOptions[key];
      //     setMasterStatus(arrayValue);
      //   }
      // }
      //  {masterStatus?.length > 0 ? :dispatch(setKanbanList(updatedDataList))}

      if (checkOptions[movedItem[0].proId]) {
        if (checkOptions[movedItem[0].proId]?.length > 1) {
          setOpenStatusPopup(true);
          updatedDataList?.data[destinationDroppableId]?.data?.splice(
            result.destination.index,
            0,
            movedItem[0]
          );
          dispatch(setKanbanList(updatedDataList));
        } else {
          updatedDataList?.data[destinationDroppableId]?.data?.splice(
            result.destination.index,
            0,
            movedItem[0]
          );
          const taskId = movedItem[0]?.task_id;
          const payload = {
            task_status: checkOptions[movedItem[0].proId][0]?.ts_value,
          };
          props.saveStatus({ taskId, payload });
          dispatch(setKanbanList(updatedDataList));
        }
      } else {
        void null;
      }
    }

    // if (props.type === "global") {
    //   const globalpayload = {
    //     project_id: movedItem[0].task_project_id,
    //     task_id: result.draggableId,
    //     task_status: movedItem[0].task_status,
    //   };
    //   props.saveStatus(globalpayload);
    // } else {

    if (checkMasterId?.masterId == 3) {
      updatedDataList?.data[destinationDroppableId]?.data?.splice(
        result.destination.index,
        0,
        movedItem[0]
      );

      dispatch(setKanbanList(updatedDataList));
      const taskId = result.draggableId;
      const payload = {
        task_status: getTsId?.id,
      };
      props.saveStatus({ taskId, payload });
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <SyAccordion title={`${t("kanban_task_status_board")}`} id="kanbanList">
          <div
            className={`${Stylesheet.kanban_card} rounded-bottom  ${
              kanbanList?.data?.length !== 0 && `sy_horizontal_scroll`
            }`}
          >
            {props.Loading ? (
              <div className="container_fluid rounded-bottom d-flex justify-content-center bg-white mt-2 p-5">
                <SyLoadingIcon size="sm" />
              </div>
            ) : (
              <div className="d-flex">
                {kanbanList?.data?.length > 0 &&
                  kanbanList?.data?.map((item: any, index: any) => {
                    return (
                      <UserComponent
                        key={index}
                        item={item}
                        openItemId={openItemId}
                        handleAccordionItemClick={handleAccordionItemClick}
                        status={status}
                        onClickNavBtn={onClickBtn}
                      />
                    );
                  })}
              </div>
            )}
            {kanbanList?.data?.length === 0 && !props.Loading ? (
              <div className="container_fluid rounded-bottom d-flex justify-content-center bg-white mt-2 p-5">
                <span className=" text-center opacity-50">no_data_found</span>
              </div>
            ) : null}
          </div>
        </SyAccordion>
      </DragDropContext>
      {openStatusPopup && masterStatus?.length > 0 ? (
        <Popup
          open={openStatusPopup}
          close={setOpenStatusPopup}
          model_type={"scroll"}
          children={popupContent()}
          title={"status"}
          maxWidth="xs"
          onClickOverlay={false}
        />
      ) : (
        void null
      )}
    </>
  );
};
export default KanbanBoard;
