import Stylesheet from "./style.module.scss"
type Props = {
  toggleClick : (data:any)=>void;
  value:any
}

const SyToggleButton = ({toggleClick,value}: Props) => {

  const onChange=(()=>{

  })
  
  return (
    <div className={`${Stylesheet.togglebtn_color} form-check form-switch `}>
      <input
        className=" form-check-input pointer border"
        type="checkbox"
        role="switch"
        onClick={toggleClick}
        checked={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SyToggleButton;
