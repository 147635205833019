import React from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import SyIcons from "../sy-icons";
import StyleSheet from "./style.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  menuBtn: any;
  menuItems: any;
  onClick: any;
  vertical:any;
  horizontal:any;
};

const SyMenu = ({ menuBtn, menuItems, onClick,vertical,horizontal }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenu = (val: any = "") => {
    onClick(val);
    setAnchorEl(null);
  };

  const MenuList = (props: any) => {
    const { onClickMenuItem, item } = props;
    const onClickMenu = () => {
      onClickMenuItem(item);
    };
    return (
      <div className={`d-flex ${StyleSheet.menuItem}`}>
        <MenuItem onClick={onClickMenu}>
          {item?.icon && <SyIcons name={item?.icon} />}
          {t(item.label)}
        </MenuItem>
      </div>
    );
  };

  return (
    <>
      <div onClick={handleClick}>{menuBtn}</div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{
          vertical: vertical,
          horizontal: horizontal,
        }}
      >
        {menuItems.map((item: any, i: number) => (
          <MenuList key={i} onClickMenuItem={onClickMenu} item={item} />
        ))}
      </Menu>
    </>
  );
};

export default SyMenu;
