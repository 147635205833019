import { createSlice } from "@reduxjs/toolkit";

interface RootState {
  viewType: string;
  mine: boolean;
}

const initialState: RootState = {
  viewType: "grid",
  mine: false,
};

export const viewControlSlice = createSlice({
  name: "viewcontrol",
  initialState,
  reducers: {
    updateviewControlDetail: (state, { payload }) => {
      state.viewType = payload.viewType;
      state.mine = payload.mine;
    },
    updateMyDetail: (state, { payload }) => {
      state.mine = payload;
    },
    updateViewType: (state, { payload }) => {
      state.viewType = payload;
    },
  },
});

export const { updateMyDetail, updateViewType, updateviewControlDetail } = viewControlSlice.actions;

export const updateMyDetailOnToggle = (state: any) => state.viewControlReducer.mine;
export const getViewControlDetail = (state: any) => state.viewControlReducer;

export default viewControlSlice.reducer;
