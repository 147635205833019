import {createSlice} from "@reduxjs/toolkit";

interface RootState{
    kanbanList:any;
    kanbanLoading:boolean;
}
const initialState:RootState={
    kanbanList:"",
    kanbanLoading:false,
}

export const kanbanSlice=createSlice({
    name:'kanban',
    initialState,
    reducers:{
        setKanbanList:(state,{payload}) => {
            state.kanbanList = payload;
            state.kanbanLoading =false;
        },
        setKanbanLoading:(state,{payload}) => {
            state.kanbanList=[];
            state.kanbanLoading =true;
        },
        resetKanbanSlice:()=>initialState
    }
})

export const {setKanbanList,setKanbanLoading,resetKanbanSlice} = kanbanSlice.actions;

export const getkanbanList =(state:any) => state.kanbanReducer.kanbanList;

export const getKanbanLoading =(state:any) => state.kanbanReducer.kanbanLoading

export default kanbanSlice.reducer;