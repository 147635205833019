import React from "react";
import Stylesheet from "./style.module.scss"

type Props = {
  title: any;
  children:any
  id: string;
};

const SyAccordion = ({ title, children,id }: Props) => {
  return (
    <div className="accordion border border-0 " id={id}>
      <div className="accordion-item border border-0">
        <h2 className={`${Stylesheet.accordionScreenStyle} accordion-header`}>
          <button
            className={`${Stylesheet.accordion_button} accordion-button title-font`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${id}-collapseOne`}
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            {title}
          </button>
        </h2>
        <div
          id={`${id}-collapseOne`}
          className="accordion-collapse collapse show"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SyAccordion;
