import styleSheet from "./style.module.scss";
import commentService from "../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StatusSchema } from "../../schema-validation/schema-validation";
import * as yup from "yup";
import { InputForm } from "../../../../../../shared/components/forms";
import Chip from "../../../../../../shared/components/sy-chip";
import Sy_CustomFooter from "../../../../../../shared/components/sy-model/sy-customfooter";
import { useTranslation } from "react-i18next";
import { SyToast } from "../../../../../../shared/utils/sy-toast";
import Errormessage from "../../../../../../shared/components/forms/errormessage/errormessage";
import Dropdown from "../../../../../../shared/components/forms/dropdown";
import {masterStatus} from "./helper"

interface AddPopup {
  taskId: number | null;
  enableEdit: boolean;
  statusToEdit: any;
  statusId: number;
  onCloseClick: () => void;
  rerenderTable: () => void;
  statusOptions: any;
}

type formValues = {
  ts_name: any;
  ts_description: string;
  ts_color: string;
  ts_master_type: any;
};

const AddStatus = (props: AddPopup) => {
  const {
    onCloseClick,
    taskId,
    statusToEdit,
    enableEdit,
    rerenderTable,
    statusId,
    statusOptions,
  } = props;

  const { t } = useTranslation();
  const [chipColor, setChipColor] = useState<string>(
    enableEdit ? statusToEdit.ts_color : "#7f7a7a"
  );
  const [chipText, setChipText] = useState(
    enableEdit ? statusToEdit.ts_name : ""
  );

  const { mutate: saveStatusMutate, isLoading } = useMutation({
    mutationFn: commentService.saveTaskStatus,
    onSuccess(data) {
      const message = data.data.message;
      SyToast(t(message), "top-right", "short", "success");
      onCloseClick();
      rerenderTable();
      document.getElementById("closePopup")?.click();
    },
  });

  const editMasterStatus = masterStatus?.filter((val:any)=>{
    return val?.id === statusToEdit?.ts_master_type
  })[0]

  const formDefaultValues = {
    ts_name: enableEdit ? statusToEdit.ts_name : "",
    ts_master_type: enableEdit ? editMasterStatus :null,
    ts_description: enableEdit ? statusToEdit.ts_description : "",
    ts_color: enableEdit ? statusToEdit.ts_color : "",
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<formValues>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: formDefaultValues,
    resolver: yupResolver<yup.AnyObjectSchema>(StatusSchema),
  });

  const handleSaveClick = (saveData: any) => {
    if (saveData) {
      !saveData.ts_color && setValue("ts_color", `#7f7a7a`);
      saveData = {
        ...saveData,
        ts_master_type:saveData?.ts_master_type.id,
        ts_id: enableEdit ? statusToEdit.ts_id : null,
        ts_order: enableEdit ? statusToEdit.ts_order : "",
        ts_value: enableEdit ? statusToEdit.ts_value : "",
        ts_pro_id: taskId?.toString(),
        ts_status: statusId.toString(),
        ts_static: "0",
      };

      const payload = {
        status: saveData,
      };
      const taskid = taskId;
      const statusid = enableEdit ? statusToEdit.ts_id : "";

      saveStatusMutate({ taskid, statusid, payload });
    } else onCloseClick();
  };


  const getMasterStatus = (data: any) => {
    return data?.name;
  };

  const handleColorChange = (e: any) => setChipColor(e.target.value);
  const handleInputChange = (e: any) => setChipText(e.target.value);
  return (
    <div className={`py-2 `}>
      <div
        className={`d-flex justify-content-between me-5`}
        onChange={handleInputChange}
      >
        <div className="col me-3">
          <Controller
            name="ts_name"
            control={control}
            render={({ field, field: { onChange, value } }) => {
              return (
                <div onChange={onChange}>
                  <InputForm
                    {...field}
                    type={"text"}
                    placeholder={t("status")}
                    defaultValue={
                      enableEdit && statusToEdit
                        ? statusToEdit.ts_name
                        : undefined
                    }
                  />
                </div>
              );
            }}
          />
          <span className="d-flex justify-content-end ">
            <Errormessage message={errors.ts_name?.message} />
          </span>
        </div>
        <div className="col ms-3 h-100">
          <Controller
            name="ts_master_type"
            control={control}
            render={({ field, field: { onBlur,onChange, value } }) => {
              return (
                <Dropdown
                {...onBlur}
                  options={masterStatus}
                  placeholder={t("master_status")}
                  getoptlabel={getMasterStatus}
                  handleChange={onChange}
                  controlledVal={value}
                  errorMessage={errors.ts_master_type?.message}
                />
              );
            }}
          />
        </div>
      </div>
      <div className={`my-3 pt-2 me-5`}>
        <Controller
          name="ts_description"
          control={control}
          render={({ field, field: { onChange, value } }) => {
            return (
              <div onChange={onChange}>
                <InputForm
                  {...field}
                  type={"text"}
                  placeholder={t("description")}
                  defaultValue={
                    enableEdit && statusToEdit
                      ? statusToEdit.ts_description
                      : undefined
                  }
                />
              </div>
            );
          }}
        />
      </div>
      <div className=" my-3 me-5 ellipsis">
        <span
          className={`${styleSheet.colorpicker_outer}`}
          onChange={handleColorChange}
        >
          <Controller
            name="ts_color"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <input
                  type="color"
                  className={`${styleSheet.color_picker}`}
                  defaultValue={chipColor}
                  onChange={onChange}
                />
              );
            }}
          />
          <div className={`${styleSheet.color_picker_text}`}>
            {chipText ? <Chip label={chipText} color={chipColor} /> : void null}
          </div>
        </span>
      </div>
      <div
        className={`d-flex justify-content-end ${styleSheet.button_space}  select-none`}
      >
        <Sy_CustomFooter
          handleSubmit={handleSubmit}
          handleformSubmit={handleSaveClick}
          loading={isLoading}
          label="save"
        />
      </div>
    </div>
  );
};

export default AddStatus;
