import React, { useContext } from "react";
import { AuthContext } from "../../projects/simpler/context";
import { MAIN_API_URL,fileFormat } from "../constants";
import { SyIcons } from "../components";
import heic2any from "heic2any";
type Props = {};

export const SyFileReference = (data: any) => {

  const fileExtension = data.split(".").pop();
  const authToken = useContext(AuthContext);
  const base = `${MAIN_API_URL}/public/assets/images/filetypes/`;
  const photo = `${MAIN_API_URL}public/asset/file/mopus_docs/${authToken.authToken}/${data}`;
  const xlsx = `${base}xls.png`;
  const pdf = `${base}pdf.png`;
  const txt = `${base}txt.png`;
  const video = `${MAIN_API_URL}public/asset/file/mopus_docs/${authToken.authToken}/${data}`;
  const audio = `${base}audio.png`;
  // const audio = `https://app-api.sodisys.net/ci-api/public/assets/images/filetypes/audio.png`;
  if (fileFormat.imageFormats.includes(fileExtension)) {
    return photo;
  }
  if (fileFormat.videoFormats.includes(fileExtension)) {
    return video;
  }
  if (fileFormat.audioFormats.includes(fileExtension)) {
    return audio;
  }
  if (fileExtension.includes("xlsx")) {
    return xlsx;
  }
  if (fileExtension.includes("xls")) {
    return xlsx;
  }
  if (fileExtension.includes("pdf")) {
    return pdf;
  }
  if (fileExtension.includes("tsx")) {
    return txt;
  } else {
    return "";
  }
};

export const getfiletype = (data: any) => {
  const fileExtension = data.split(".").pop();
  
  if (
    fileFormat.imageFormats.includes(fileExtension)
  ) {
    return "image";
  }
  if (fileExtension.includes("pdf")) {
    return "pdf";
  }
  if (
    fileFormat.fileFormats.includes(fileExtension)
  ) {
    return "document";
  }
  if (
    fileFormat.audioFormats.includes(fileExtension)
  ) {
    return "audio";
  }
  if (
    fileFormat.videoFormats.includes(fileExtension)
  ) {
    return "video";
  } else {
    return "document";
  }
};

export const FILE_ICON_NAME = {
  image: "image",
  document: "description",
  pdf: "picture_as_pdf",
  audio: "audio_file",
  video: "video_file",
};

export const SyIconReference = (data: any) => {
  const fileExtension = data.split(".").pop();

  if (fileExtension.includes("json")) {
    return <SyIcons name="description" />;
  }
  if (fileExtension.includes("pdf")) {
    return <SyIcons name="picture_as_pdf" />;
  }
  if (fileExtension.includes("php")) {
    return <SyIcons name="php" />;
  }
  if (fileExtension.includes("xlsx" || "xls")) {
    return <SyIcons name="article" />;
  }
  if (fileExtension.includes("sql")) {
    return <SyIcons name="table_chart" />;
  }
  if (fileFormat.videoFormats.includes(fileExtension)) {
    return <SyIcons name="ondemand_video" iconStyle="round" />;
  }
  if (fileFormat.audioFormats.includes(fileExtension)) {
    return <SyIcons name="audio_file" iconStyle="round" />;
  }
  if (fileExtension.includes("oxps")) {
    return <SyIcons name="list_alt" iconStyle="outlined" />;
  }
  if (fileFormat.imageFormats.includes(fileExtension)) {
    return <SyIcons name="image" />;
  } else {
    return "";
  }
};





export const syOpenFile = ({fileName,authToken,path, localUrl}:any) => {
  const url = `${MAIN_API_URL}public/asset/file/${path}/${authToken}`;
  return localUrl?window.open(`${localUrl}`, "_blank"):window.open(`${url}/${fileName}`, "_blank");
}


export const syFileSizeValidation = ({size,validationSize}:any) => {
    if (size >= validationSize * 1024 * 1024) {
      return false; 
    }else{
      return true
    }
 
}


export const convertFileFormate=async (fileUrl:any)=> {
  const toFileType = "image/jpeg";
  // const name = fileName.substring(0, fileName.lastIndexOf('.'));
  let url:any;
  

  await fetch(fileUrl)
    .then((res) => res.blob())
    .then((blob) => heic2any({
      blob,
      toType: toFileType,
    }))
    .then((conversionResult) => {
      url = conversionResult;
    })
    .catch((e) => {
      console.log(e);
    });
  const data = {
    fileUrl: URL.createObjectURL(url)
  }
  return data.fileUrl;
}

